var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
// services
import { AddCarFromOtherWebsiteService } from '../services/add-car-from-other-website.service';
import { ValidationService } from '../../../../core/services/validation.service';
import { CarLocalItemsService } from '../../../../core/services/car-local-items.service';
import { MyToastrService } from '../../../../shared/toastr/my-toastr.service';
// utils
import { AbstractForm } from '../../../../shared/form/abstract-form';
import { addCarFromOtherSiteValidationMessages } from './validation-messages';
import { SupportedWebsitesToAddCarFromValidator } from '../../../../shared/validators/supported-websites-validator';
import { backendErrorMessagesHandler } from 'src/app/shared/utils/utils';
var AddPostLinkModalComponent = /** @class */ (function (_super) {
    __extends(AddPostLinkModalComponent, _super);
    function AddPostLinkModalComponent(addCarFromOtherWebsiteService, carLocalItemsService, customValidatorService, fb, toastr, dialogRef) {
        var _this = _super.call(this, addCarFromOtherSiteValidationMessages, fb) || this;
        _this.addCarFromOtherWebsiteService = addCarFromOtherWebsiteService;
        _this.carLocalItemsService = carLocalItemsService;
        _this.customValidatorService = customValidatorService;
        _this.fb = fb;
        _this.toastr = toastr;
        _this.dialogRef = dialogRef;
        _this.submitted = false;
        _this.controlsConfig = {
            url: [null, [Validators.required, SupportedWebsitesToAddCarFromValidator]],
            price: [null, [Validators.required, Validators.min(1), _this.customValidatorService.onlyNumbers]],
            currency: ['amd']
        };
        return _this;
    }
    AddPostLinkModalComponent.prototype.closeDialog = function () {
        this.dialogRef.close();
    };
    AddPostLinkModalComponent.prototype.makeRequest = function (data) {
        var _this = this;
        if (this.submitted) {
            return;
        }
        this.submitted = true;
        this.$subscription = this.addCarFromOtherWebsiteService.createRequest(data).subscribe(function () { return _this.closeDialog(); }, function (error) {
            if (error.status == 500) {
                _this.toastr.error();
            }
            else {
                backendErrorMessagesHandler(error).forEach(function (err) { return _this.toastr.error(err.field, err.error); });
            }
            _this.submitted = false;
        });
    };
    AddPostLinkModalComponent.prototype.ngOnDestroy = function () {
        if (this.$subscription) {
            this.$subscription.unsubscribe();
        }
        this.submitted = false;
    };
    return AddPostLinkModalComponent;
}(AbstractForm));
export { AddPostLinkModalComponent };
