/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./page-header.component";
var styles_PageHeaderComponent = [];
var RenderType_PageHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageHeaderComponent, data: {} });
export { RenderType_PageHeaderComponent as RenderType_PageHeaderComponent };
function View_PageHeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "a", [["class", "b-breadCumbs__page"], ["routerLinkActive", "false"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, [[2, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(3, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(8, 0, null, null, 0, "span", [["class", "fa fa-angle-right"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.queryParams; var currVal_3 = _v.parent.context.$implicit.routerLink; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = "false"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_5 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_v.parent.context.$implicit.h1)); _ck(_v, 6, 0, currVal_5); }); }
function View_PageHeaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "b-breadCumbs__page m-active"], ["routerLinkActive", "true"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[4, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(2, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 3, { links: 1 }), i0.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _v.parent.context.$implicit.queryParams; var currVal_3 = _v.parent.context.$implicit.routerLink; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = "true"; _ck(_v, 2, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_v.parent.context.$implicit.h1)); _ck(_v, 5, 0, currVal_5); }); }
function View_PageHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_3)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.last; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.last; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PageHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.multiple; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PageHeaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["class", "b-breadCumbs__page m-active"], ["routerLinkActive", "true"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, [[6, 4]], 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵdid(2, 1720320, null, 2, i1.RouterLinkActive, [i1.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 5, { links: 1 }), i0.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.data == null) ? null : _co.data.routerLink); _ck(_v, 1, 0, currVal_2); var currVal_3 = "true"; _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(((_co.data == null) ? null : _co.data.h1))); _ck(_v, 5, 0, currVal_4); }); }
export function View_PageHeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "b-pageHeader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 10, "div", [["class", "b-breadCumbs s-shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 9, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 3, "a", [["class", "b-breadCumbs__page"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(11, 0, null, null, 0, "span", [["class", "fa fa-angle-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_1)), i0.ɵdid(13, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageHeaderComponent_5)), i0.ɵdid(15, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/"; _ck(_v, 8, 0, currVal_3); var currVal_5 = (_co.data && _co.data.multiple); _ck(_v, 13, 0, currVal_5); var currVal_6 = (_co.data && !_co.data.multiple); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(((_co.data == null) ? null : _co.data.h1))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 8).target; var currVal_2 = i0.ɵnov(_v, 8).href; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_4 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("HEADER.HOME")); _ck(_v, 9, 0, currVal_4); }); }
export function View_PageHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-header", [], null, null, null, View_PageHeaderComponent_0, RenderType_PageHeaderComponent)), i0.ɵdid(1, 245760, null, 0, i4.PageHeaderComponent, [i1.ActivatedRoute, i1.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageHeaderComponentNgFactory = i0.ɵccf("app-page-header", i4.PageHeaderComponent, View_PageHeaderComponent_Host_0, { data: "data" }, {}, []);
export { PageHeaderComponentNgFactory as PageHeaderComponentNgFactory };
