var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnInit } from '@angular/core';
import { CarLocalItemsService } from '../../../core/services/car-local-items.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { getQueryParamsStr } from '../../profile/utils';
import { RouterLoaderService } from '../../../core/services/router-loader.service';
import { CarService } from '../../../core/services/car.service';
import { urlFragment } from '../../../shared/utils/utils';
var FilteredItemsComponent = /** @class */ (function () {
    function FilteredItemsComponent(localItems, activatedRoute, router, carService, routerLoaderService, translateService) {
        var _this = this;
        this.localItems = localItems;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.carService = carService;
        this.routerLoaderService = routerLoaderService;
        this.translateService = translateService;
        this.page = 1;
        this.language = {};
        this.activatedRoute.queryParams.subscribe(function (queryParams) {
            _this.limit = +queryParams['limit'] || +localStorage.getItem('limit') || 10;
            _this.page = Math.ceil((+queryParams['offset'] || 0) / _this.limit) + 1;
            _this.routerLoaderService.$stopLoader.next();
        });
        this.translateService.get('CAR').subscribe(function (language) {
            _this.language = language;
        });
        this.getUrlFragment = urlFragment;
    }
    Object.defineProperty(FilteredItemsComponent.prototype, "items", {
        get: function () {
            return this.cars.body.results;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilteredItemsComponent.prototype, "count", {
        get: function () {
            return this.cars.body.count;
        },
        enumerable: true,
        configurable: true
    });
    FilteredItemsComponent.prototype.ngOnInit = function () {
    };
    FilteredItemsComponent.prototype.getQueryParams = function (event) {
        var queryParams = __assign({}, this.activatedRoute.snapshot.queryParams);
        queryParams['offset'] = (event - 1) * this.limit;
        return queryParams;
    };
    FilteredItemsComponent.prototype.getCars = function (event) {
        var _this = this;
        this.routerLoaderService.$loader.next(true);
        var queryParams = this.getQueryParams(event);
        this.carService.getCars(getQueryParamsStr(queryParams)).subscribe(function (res) {
            _this.cars = res;
            _this.page = event;
            _this.router.navigate(['/filter'], { queryParams: queryParams });
            _this.routerLoaderService.$stopLoader.next();
            // goToTop();
        });
    };
    FilteredItemsComponent.prototype.getCurrency = function (type) {
        return this.localItems.currency.find(function (cr) { return cr.id === type; }).name;
    };
    return FilteredItemsComponent;
}());
export { FilteredItemsComponent };
