var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { interval } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, mergeMap, take, takeWhile, tap } from 'rxjs/operators';
// services
import { BaseHttpService } from '../../../../core/services/base-http-service';
import { AuthorizationService } from '../../../../core/services/authorization.service';
import { InfoBoxService } from '../../../info-box/services/info-box.service';
import { AppLanguageService } from '../../../../core/services/app-language.service';
import { CarService } from '../../../../core/services/car.service';
// models
import { ADD_CAR_STATUSES } from '../models/add-car-from-other-website-payload.model';
// utils
import { getDateDifferenceInMinutes } from '../../utils';
import { urlFragment } from '../../../../shared/utils/utils';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../core/services/authorization.service";
import * as i3 from "../../../info-box/services/info-box.service";
import * as i4 from "../../../../core/services/car.service";
var AddCarFromOtherWebsiteService = /** @class */ (function (_super) {
    __extends(AddCarFromOtherWebsiteService, _super);
    function AddCarFromOtherWebsiteService(httpClient, authService, infoBoxService, carsService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authService = authService;
        _this.infoBoxService = infoBoxService;
        _this.carsService = carsService;
        _this.POST_URL = 'car-scraper/';
        _this.GET_STATUS_URL = 'car-scraper-info/';
        _this.alreadyStartedChecking = false;
        return _this;
    }
    AddCarFromOtherWebsiteService.prototype.createRequest = function (payload) {
        var _this = this;
        return this.post("/" + this.POST_URL, payload, {
            headers: new HttpHeaders({
                'Accept-Language': AppLanguageService.API_LANGUAGE,
                Authorization: 'Token ' + this.authService.getToken(),
            })
        }).pipe(tap(function (res) {
            res.title = AddCarFromOtherWebsiteService.getHostnameFromUrl(payload.url);
            AddCarFromOtherWebsiteService.data.set(res.task_id, res);
            _this.infoBoxService.updateData(AddCarFromOtherWebsiteService.data.size, AddCarFromOtherWebsiteService.completedTasks.size, res);
            _this.statusChecker();
        }));
    };
    AddCarFromOtherWebsiteService.prototype.getStatuses = function () {
        return this.get("/" + this.GET_STATUS_URL + "?limit=-1", {
            headers: new HttpHeaders({
                'Accept-Language': AppLanguageService.API_LANGUAGE,
                Authorization: 'Token ' + this.authService.getToken(),
            })
        })
            .pipe(map(function (res) { return res; }));
    };
    AddCarFromOtherWebsiteService.prototype.statusChecker = function () {
        var _this = this;
        if (AddCarFromOtherWebsiteService.data.size <= 0) {
            this.stopStatusChecking();
            return;
        }
        if (this.alreadyStartedChecking) {
            return;
        }
        this.alreadyStartedChecking = true;
        interval(7000)
            .pipe(mergeMap(function () {
            return _this.getStatuses();
        }), map(function (result) {
            if (result.count === 0 || result.results.length === 0) {
                // no status updates yet
                return;
            }
            AddCarFromOtherWebsiteService.data.forEach(function (car) {
                if (!car.requestStartDate) {
                    car.requestStartDate = new Date();
                }
                var index = result.results.findIndex(function (item) { return item.task_id === car.task_id; });
                if (index >= 0 && AddCarFromOtherWebsiteService.data.has(result.results[index].task_id)) {
                    var item = result.results[index];
                    item.slug = car.slug;
                    item.title = car.title;
                    switch (item.status) {
                        case ADD_CAR_STATUSES.SUCCESS:
                            _this.changeItemStatus(item, ADD_CAR_STATUSES.SUCCESS);
                            break;
                        case ADD_CAR_STATUSES.FAILURE:
                            _this.changeItemStatus(item, ADD_CAR_STATUSES.FAILURE);
                            break;
                        default:
                            _this.changeItemStatus(item, ADD_CAR_STATUSES.PENDING);
                            break;
                    }
                }
                else {
                    var now = new Date();
                    var minutes = getDateDifferenceInMinutes(car.requestStartDate, now);
                    if (minutes >= AddCarFromOtherWebsiteService.TIMEOUT) {
                        _this.changeItemStatus(car, ADD_CAR_STATUSES.FAILURE);
                    }
                }
            });
            if (AddCarFromOtherWebsiteService.data.size <= 0) {
                _this.stopStatusChecking();
            }
        }))
            .pipe(takeWhile(function () { return AddCarFromOtherWebsiteService.data.size > 0; }))
            .subscribe();
    };
    // stops polling and updates pending/completed
    AddCarFromOtherWebsiteService.prototype.stopStatusChecking = function () {
        this.infoBoxService.updateData(0, AddCarFromOtherWebsiteService.completedTasks.size, null);
        this.alreadyStartedChecking = false;
    };
    AddCarFromOtherWebsiteService.prototype.changeItemStatus = function (item, status) {
        var _this = this;
        item.status = status;
        if (status === ADD_CAR_STATUSES.SUCCESS || status === ADD_CAR_STATUSES.FAILURE) {
            if (AddCarFromOtherWebsiteService.data.has(item.task_id)) {
                AddCarFromOtherWebsiteService.data.delete(item.task_id);
            }
            if (status === ADD_CAR_STATUSES.SUCCESS) {
                AddCarFromOtherWebsiteService.completedTasks.add(item.task_id);
                this.carsService.getCars("slug=" + item.slug)
                    .pipe(take(1))
                    .subscribe(function (response) {
                    var car = response.body.results[0];
                    if (car) {
                        item.routerLink = [
                            '/detail',
                            "" + car.id,
                            urlFragment(car)
                        ];
                        item.title = car.car_model.brand.name + ' ' + car.car_model.name;
                    }
                    _this.infoBoxService.updateData(AddCarFromOtherWebsiteService.data.size, AddCarFromOtherWebsiteService.completedTasks.size, item);
                });
            }
            else {
                // console.log('update FAILURE:', item);
                this.infoBoxService.updateData(AddCarFromOtherWebsiteService.data.size, AddCarFromOtherWebsiteService.completedTasks.size, item);
            }
        }
    };
    AddCarFromOtherWebsiteService.getHostnameFromUrl = function (url) {
        var hostname;
        // remove protocol
        if (url.indexOf('//') > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
        if (url.indexOf('www.') > -0) {
            hostname = url.split('www.')[1];
        }
        // hostname = hostname.split('.')[0];
        // remove port
        hostname = hostname.split(':')[0];
        // remove query
        hostname = hostname.split('?')[0];
        hostname = hostname.split('/')[0];
        return hostname;
    };
    AddCarFromOtherWebsiteService.completedTasks = new Set(); // completed tasks
    AddCarFromOtherWebsiteService.data = new Map(); // pending tasks
    AddCarFromOtherWebsiteService.TIMEOUT = 10; // in minutes
    AddCarFromOtherWebsiteService.ngInjectableDef = i0.defineInjectable({ factory: function AddCarFromOtherWebsiteService_Factory() { return new AddCarFromOtherWebsiteService(i0.inject(i1.HttpClient), i0.inject(i2.AuthorizationService), i0.inject(i3.InfoBoxService), i0.inject(i4.CarService)); }, token: AddCarFromOtherWebsiteService, providedIn: "root" });
    return AddCarFromOtherWebsiteService;
}(BaseHttpService));
export { AddCarFromOtherWebsiteService };
