/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../carousel-item/carousel-item.component.ngfactory";
import * as i2 from "../carousel-item/carousel-item.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../core/services/car-local-items.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/ngx-owl-carousel/src/owl-carousel.component.ngfactory";
import * as i7 from "ngx-owl-carousel/src/owl-carousel.component";
import * as i8 from "./top-vehicles.component";
import * as i9 from "../../../core/services/car.service";
var styles_TopVehiclesComponent = [];
var RenderType_TopVehiclesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TopVehiclesComponent, data: {} });
export { RenderType_TopVehiclesComponent as RenderType_TopVehiclesComponent };
function View_TopVehiclesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-carousel-item", [], null, null, null, i1.View_CarouselItemComponent_0, i1.RenderType_CarouselItemComponent)), i0.ɵdid(2, 638976, null, 0, i2.CarouselItemComponent, [i3.TranslateService, i4.CarLocalItemsService], { car: [0, "car"], enabled: [1, "enabled"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "thumbnail-image"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i5.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.enabled; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ""; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_TopVehiclesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "owl-carousel", [], null, null, null, i6.View_OwlCarousel_0, i6.RenderType_OwlCarousel)), i0.ɵdid(1, 311296, null, 0, i7.OwlCarousel, [i0.IterableDiffers], { carouselClasses: [0, "carouselClasses"], options: [1, "options"], items: [2, "items"] }, null), i0.ɵpad(2, 3), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_TopVehiclesComponent_1)), i0.ɵdid(4, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "owl-theme", "row", "sliding"); var currVal_1 = _co.options; var currVal_2 = _co.carouselTileItems; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.carouselTileItems; _ck(_v, 4, 0, currVal_3); }, null); }
export function View_TopVehiclesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-top-vehicles", [], null, null, null, View_TopVehiclesComponent_0, RenderType_TopVehiclesComponent)), i0.ɵdid(1, 245760, null, 0, i8.TopVehiclesComponent, [i9.CarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopVehiclesComponentNgFactory = i0.ɵccf("app-top-vehicles", i8.TopVehiclesComponent, View_TopVehiclesComponent_Host_0, { topParam: "topParam" }, {}, []);
export { TopVehiclesComponentNgFactory as TopVehiclesComponentNgFactory };
