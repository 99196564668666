import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DirectiveModule} from '../shared/directives/directive.module';
import {CookieService} from 'ngx-cookie-service';
import {AuthorizationService} from './services/authorization.service';
import {RouterLoaderService} from './services/router-loader.service';
import {CarLocalItemsService} from './services/car-local-items.service';
import {CarService} from './services/car.service';
import {HeaderInterceptorService} from './services/header-interceptor.service';
import {SharedModule} from '../shared/shared.module';
import {NguCarouselModule} from '@ngu/carousel';
import {OwlModule} from 'ngx-owl-carousel';
import {LayoutModule} from './layout/layout.module';
import {throwIfAlreadyLoaded} from './guards/module-import.guard';
import {UiModule} from '../shared/ui/ui.module';

@NgModule({
  imports: [
    HttpClientModule,
    DirectiveModule,
    SharedModule,
    NguCarouselModule,
    OwlModule,
    LayoutModule,
    UiModule
  ],
  exports: [
    HttpClientModule,
    DirectiveModule,
    SharedModule,
    NguCarouselModule,
    OwlModule,
    LayoutModule,
    UiModule
  ],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        CookieService,
        AuthorizationService,
        RouterLoaderService,
        CarLocalItemsService,
        CarService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HeaderInterceptorService,
          multi: true
        }
      ]
    };
  }
}
