import {Component, Input, forwardRef, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import PerfectScrollbar from 'perfect-scrollbar';
import SimpleBar from 'simplebar';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent implements ControlValueAccessor, AfterViewInit {
  @Input() _selectValue;
  @Input() items: Array<any> = [];
  @Input() disabled: boolean;
  @Input() virtualScroll = true;
  @Input() placehold: string;
  @Input() loading: boolean;
  @Input() notFoundText = 'SHARED.NOT_FOUND_MSG';
  @Input() typeToSearchText = 'SHARED.TAP_TO_SEARCH';
  @Input() loadingText = 'SHARED.LOADING_TEXT';
  @Input() enableScroll = true;
  @Input() searchable = false;
  @Input() onCloseEvent;
  @Input() onChangeEvent;
  @Input() multiple: boolean;
  @Input() groupBy;
  @Input() clearable = true;
  @Input() bindValue = 'id';
  @Input() bindLabel = 'name';
  private ps: PerfectScrollbar;
  private simplebar: SimpleBar;
  private searchElementRef: ElementRef;
  @ViewChild('searchInput', {read: ElementRef}) set searchInput(elRef: ElementRef) {
    this.searchElementRef = elRef;
    if (elRef) {
      elRef.nativeElement.focus();
    }
  }

  get selectValue() {
    return this._selectValue;
  }

  set selectValue(val) {
    this._selectValue = val;
    this.propagateChange(this._selectValue);
  }

  constructor() {
  }

  ngAfterViewInit() {
    if (this.onCloseEvent !== undefined) {
      this.onCloseHandler = this.onCloseEvent;
    }
    if (this.onChangeEvent !== undefined) {
      this.onChangeHandler = this.onChangeEvent;
    }

    // if (this.searchInput) {
    //   this.searchInput.focus();
    //   console.log(1);
    // }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.selectValue = value;
    }
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  onOpenHandler = () => {
    // if (this.enableScroll) {
    // setTimeout(() => {
    // const element = <HTMLElement>document.querySelector('.ng-dropdown-panel-items');
    // this.simplebar = new SimpleBar(element);
    // this.ps = new PerfectScrollbar(element, {
    //   // suppressScrollY: false,
    //   wheelPropagation: false,
    //   wheelSpeed: 1.4,
    //   minScrollbarLength: 30,
    //   scrollYMarginOffset: 5
    //   // suppressScrollX: true
    // });
    // });
    // }
  };

  onCloseHandler = () => {
    // if (this.enableScroll) {
    // this.ps.destroy();
    // }
  };

  onChangeHandler() {
  }

  public onClear(): void {
    if (this.searchElementRef && this.searchElementRef.nativeElement) {
      this.searchElementRef.nativeElement.value = '';
      this.searchElementRef.nativeElement.focus();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
