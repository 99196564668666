var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CarService } from '../../../core/services/car.service';
import { CarLocalItemsService } from '../../../core/services/car-local-items.service';
import { Router } from '@angular/router';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { VehicleTypeComponent } from './vehicle-type/vehicle-type.component';
var SearchSectionComponent = /** @class */ (function () {
    function SearchSectionComponent(fb, carService, router, carLocalItems) {
        var _this = this;
        this.fb = fb;
        this.carService = carService;
        this.router = router;
        this.carLocalItems = carLocalItems;
        this.isAdvancedSearchHidden = true;
        /*  minValue = 0;
          maxValue = 10;
          options: Options = {
            floor: 0,
            ceil: 100,
            step: 1,
            noSwitching: true
          };*/
        this.dropDownsLoading = {
            modelIsLoading: false,
            generationIsLoading: false,
        };
        this.brands = [];
        this.models = [];
        this.generations = [];
        this.onBrandSelect = function () {
            var brand = _this.form.get('brand');
            var model = _this.form.get('model');
            if (model.value || !brand.value) {
                model.setValue(null);
                _this.form.get('generation').setValue(null);
                _this.carLocalItems.model = [];
                _this.carLocalItems.generation = [];
            }
            if (brand.value) {
                _this.dropDownsLoading.modelIsLoading = true;
                _this.carService.getModel(brand.value).subscribe(function (res) {
                    _this.carLocalItems.model = res.body.results;
                    _this.dropDownsLoading.modelIsLoading = false;
                    SearchSectionComponent.autocomplete(_this.models, model);
                });
            }
        };
        this.onModelSelect = function () {
            var selectedModel = _this.form.get('model');
            var selectedGeneration = _this.form.get('generation');
            if (!selectedModel.value || selectedGeneration.value) {
                selectedGeneration.setValue(null);
                _this.carLocalItems.generation = [];
            }
            if (selectedModel.value) {
                _this.dropDownsLoading.generationIsLoading = true;
                _this.getGenerations(selectedModel.value, undefined);
            }
        };
    }
    SearchSectionComponent.prototype.ngOnInit = function () {
        this.initCarBrands();
        this.createForm();
    };
    SearchSectionComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            model: [null],
            brand: [null],
            generation: [null],
            production_date_range_min: [null],
            production_date_range_max: [null],
            price_range_min: [null],
            price_range_max: [null],
        }, {
            updateOn: 'submit'
        });
    };
    SearchSectionComponent.prototype.initCarBrands = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.carService.getBrands().subscribe(function (res) {
                var popular = res.body.results.filter(function (brand) { return brand.popular; });
                if (popular.length !== 0) {
                    var all = res.body.results.diff(popular);
                    _this.carLocalItems.brand = popular.concat([{ id: '-----', name: '', disabled: true }], all); // name: '-----------------------'
                }
                else {
                    _this.carLocalItems.brand = res.body.results;
                }
                resolve(true);
            });
        });
    };
    SearchSectionComponent.prototype.getGenerations = function (id, callbackFn) {
        var _this = this;
        this.carService.getGenerations(id).subscribe(function (res) {
            _this.carLocalItems.generation = res.body.results.slice();
            _this.dropDownsLoading.generationIsLoading = false;
            if (callbackFn) {
                callbackFn();
            }
            else {
                SearchSectionComponent.autocomplete(_this.generations, _this.form.get('generation'));
            }
        });
    };
    SearchSectionComponent.prototype.getQueryParams = function () {
        var params = __assign({}, this.vehicleType.form.value, this.form.value, this.advancedSearch.form.value);
        Object.keys(params).forEach(function (paramsKey) {
            if (typeof params[paramsKey] === 'boolean') {
                if (params[paramsKey]) {
                    params[paramsKey] = 1;
                }
                else if (paramsKey === 'is_crashed') {
                    params[paramsKey] = 0;
                }
                else {
                    delete params[paramsKey];
                }
            }
        });
        params['limit'] = localStorage.getItem('limit') || 10;
        params['offset'] = 0;
        params['posted_time_desc'] = 1;
        return params;
    };
    SearchSectionComponent.prototype.search = function () {
        this.router.navigate(['/filter'], { queryParams: this.getQueryParams() });
    };
    SearchSectionComponent.prototype.ngOnDestroy = function () {
        this.carLocalItems.generation = [];
        this.carLocalItems.model = [];
        this.carLocalItems.country = [];
        this.carLocalItems.region = [];
        this.carLocalItems.color = [];
    };
    SearchSectionComponent.prototype.toggleAdvancedSearchSection = function () {
        this.isAdvancedSearchHidden = !this.isAdvancedSearchHidden;
    };
    Object.defineProperty(SearchSectionComponent.prototype, "advancedSearchLabel", {
        get: function () {
            return this.isAdvancedSearchHidden ? 'SEARCH.ADVANCED' : 'SEARCH.GENERAL';
        },
        enumerable: true,
        configurable: true
    });
    SearchSectionComponent.autocomplete = function (array, formControl, prop) {
        if (prop === void 0) { prop = 'id'; }
        if (array.length === 1) {
            formControl.setValue(array[0][prop]);
        }
    };
    return SearchSectionComponent;
}());
export { SearchSectionComponent };
