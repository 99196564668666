import {ErrorHandler, Inject, Injectable, InjectionToken} from '@angular/core';
import * as Rollbar from 'rollbar';

import {rollbarConfig} from './rollbar.config';
import {environment} from '../../../environments/environment';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {
  }

  handleError(error): void {
    if (environment.live && environment.production) {
      this.rollbar.error(error.orignalError || error);
    } else {
      console.error(error);
    }
  }
}

export function rollbarFactory() {
  if (environment.live && environment.production) {
    return new Rollbar(rollbarConfig);
  }
}
