import {ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {take} from 'rxjs/operators';

// components
import {InfoBoxComponent} from '../components/info-box/info-box.component';

// models
import {AddCarFromOtherWebsiteModel} from '../../profile/add-a-car/models/add-car-from-other-website-payload.model';

@Injectable()
export class InfoBoxService {
  private dialogComponentRef: ComponentRef<InfoBoxComponent>;
  private dataHistory = new Map<string, AddCarFromOtherWebsiteModel>();

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef,
    private readonly injector: Injector
  ) {
  }

  private updateRequestStatus(data: AddCarFromOtherWebsiteModel): void {
    const item = this.dialogComponentRef.instance.dataForRender.find(car => car.task_id === data.task_id);
    if (item) {
      item.status = data.status;
      item.routerLink = data.routerLink;
      item.title = data.title;

      if (this.dataHistory.has(item.task_id)) {
        const car = this.dataHistory.get(item.task_id);
        car.status = data.status;
        car.routerLink = data.routerLink;
        car.title = data.title;
      }
    } else {
      this.dialogComponentRef.instance.dataForRender.push(data);
      if (!this.dataHistory.has(data.task_id)) {
        this.dataHistory.set(data.task_id, data);
      }
    }
  }

  private updatePendingCount(count: number): void {
    this.dialogComponentRef.instance.pendingCount = count;
  }

  private updateCompletedCount(count: number): void {
    this.dialogComponentRef.instance.completedCount = count;
  }

  public updateData(pendingCount = 0, completedCount = 0, data: AddCarFromOtherWebsiteModel): void {
    this.appendInfoBoxToBody();

    this.updatePendingCount(pendingCount);
    this.updateCompletedCount(completedCount);

    if (data) {
      this.updateRequestStatus(data);
    }
  }

  public appendInfoBoxToBody(): void {
    if (this.dialogComponentRef && this.dialogComponentRef.hostView && !this.dialogComponentRef.hostView.destroyed) {
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(InfoBoxComponent);
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);

    componentRef.instance.closeEvent
      .pipe(take(1))
      .subscribe(() => {
        this.removeInfoBoxFromBody();
      });

    componentRef.instance.dataForRender = Array.from(this.dataHistory.values());

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;
  }

  private removeInfoBoxFromBody(): void {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
