/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./core/layout/page-preloader/page-preloader.component.ngfactory";
import * as i2 from "./core/layout/page-preloader/page-preloader.component";
import * as i3 from "@angular/router";
import * as i4 from "./core/services/router-loader.service";
import * as i5 from "./core/layout/header/header.component.ngfactory";
import * as i6 from "./core/layout/header/header.component";
import * as i7 from "./core/services/authorization.service";
import * as i8 from "./core/services/app-language.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./core/layout/footer/footer.component.ngfactory";
import * as i11 from "./core/layout/footer/footer.component";
import * as i12 from "./app.component";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "fadeAnimation", definitions: [{ type: 1, expr: "* <=> *", animation: [{ type: 6, styles: { opacity: 0.8 }, offset: null }, { type: 4, styles: null, timings: 800 }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["app-page-preloader", ""]], null, null, null, i1.View_PagePreloaderComponent_0, i1.RenderType_PagePreloaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.PagePreloaderComponent, [i3.Router, i4.RouterLoaderService], null, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i5.View_HeaderComponent_0, i5.RenderType_HeaderComponent)), i0.ɵdid(3, 114688, null, 0, i6.HeaderComponent, [i3.Router, i7.AuthorizationService, i8.AppLanguageService, i9.MatDialog], null, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "main", [["class", "main"]], [[24, "@fadeAnimation", 0]], null, null, null, null)), (_l()(), i0.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(6, 212992, [["o", 4]], 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "app-footer", [], null, null, null, i10.View_FooterComponent_0, i10.RenderType_FooterComponent)), i0.ɵdid(8, 114688, null, 0, i11.FooterComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 6, 0); _ck(_v, 8, 0); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 6).isActivated ? i0.ɵnov(_v, 6).activatedRoute : ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 49152, null, 0, i12.AppComponent, [i7.AuthorizationService, i8.AppLanguageService, i3.Router], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i12.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
