<div [attr.class]="className">
  <app-page-header *ngIf="showPageHeader"></app-page-header>

  <div class="b-infoBar" *ngIf="isFavouriteVisible">
    <div class="container">
      <div class="b-infoBar__btns">
        <!--
        TO DO
        In case of having the vehicle favourited please add "icon-close" class to the below included span in the "a" tag oterwise please use the following classes "fa fa-angle-right"
        -->
        <a (click)="toggleFavourite()" class="btn m-btn m-infoBtn w-icon remove">{{favLabel | translate}}<span [class]="isFavourite ? 'icon-close' : 'fa fa-angle-right'"></span></a>
      </div>
    </div>
  </div><!--b-infoBar-->

  <section class="b-detail s-shadow">
    <div class="container">
      <header class="b-detail__head s-lineDownLeft">
        <div class="row">
          <div class="col-sm-9 col-xs-12">
            <div class="b-detail__head-title">
              <div class="d-flex align-center title">
                <h1>{{car?.car_model?.brand?.name}} {{car?.car_model?.name}} {{car.generation && car.generation.name ? car.generation.name + ' ,' : ''}} {{car?.production_date}}</h1>
                <span class="b-blog__posts-one-body-head-notes-note ml-2 d-flex align-center"><span class="fa fa-eye"></span>{{car.seen_count}}</span>
              </div>
              <span class="b-items__cars-one-info-value">{{car.advert?.country?.name ? car.advert.country?.name + ', ' : ''}} {{(car.advert?.region || {}).name}}</span>
            </div>
          </div>
          <div class="col-sm-3 col-xs-12">
            <div class="b-detail__head-price">
              <div class="b-detail__head-price-num">{{priceAndCurrency?.currency}} {{priceAndCurrency?.price}}</div>
              <div class="amounts">
                <ul>
                  <li *ngFor="let prAndCurr of pricesInAntherCurrency">{{prAndCurr.currency}} {{prAndCurr.price}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div class="b-detail__main">
        <div class="col-md-8 col-xs-12">
          <div class="b-detail__main-info">
            <div class="b-detail__main-info-images">
              <div class="row m-smallPadding">
                <div class="col-xs-12">
                  <ul class="b-detail__main-info-images-big bxslider enable-bx-slider" appBxSlider data-pager-custom="#bx-pager" data-mode="horizontal" data-pager-slide="true" data-mode-pager="vertical" data-pager-qty="5">
                    <li class="s-relative" *ngIf="car?.images.length ===0">
                      <img class="img-responsive center-block" [src]="'assets/images/cars/default.png'" alt="" />
                    </li>
                  </ul>
                  <ngx-gallery *ngIf="car?.images.length !==0" [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
                  <!--<ul class="b-detail__main-info-images-big bxslider enable-bx-slider" appBxSlider data-pager-custom="#bx-pager" data-mode="horizontal" data-pager-slide="true" data-mode-pager="vertical" data-pager-qty="5">
                    <li class="s-relative" *ngIf="car?.images.length ===0">
                      <img class="img-responsive center-block" [src]="'assets/media/237x202/default.png'" alt="" />
                    </li>
                    <li class="s-relative" *ngFor="let image of car?.images">
                      <img class="img-responsive center-block" [src]="image.image" alt="" />
                      &lt;!&ndash;<app-safe-image [class]="'img-responsive center-block'" [src]="image.thumbnail"></app-safe-image>&ndash;&gt;
                    </li>
                  </ul>-->
                </div>
                <!--<div class="col-xs-2 pagerSlider pagerVertical">
                  <div class="b-detail__main-info-images-small" id="bx-pager">
                    <a *ngFor="let image of car?.images; let i = index" [attr.data-slide-index]="i" class="b-detail__main-info-images-small-one">
                      &lt;!&ndash;<app-safe-image [class]="'img-responsive'"  [src]="image.thumbnail"></app-safe-image>&ndash;&gt;
                      <img class="img-responsive" [src]="image.thumbnail" alt="" />
                    </a>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="b-detail__main-info-text" *ngIf="!!car?.advert.additional_information">
              <div class="b-detail__main-info-extra">
                <h5 class="s-titleDet">{{"CAR_DETAIL.GENERAL" | translate}}</h5>
              </div>
              <div id="info1" class="break-word">
                <p>{{car?.advert.additional_information}}</p>
              </div>
            </div>
            <div class="col-lg-12 p-0 items">
              <div class="col-lg-4 item pl-0" *ngFor="let category of extraFeatures">
                <div class="b-detail__main-info-extra">
                  <h2 class="s-titleDet">{{category.label | translate}}</h2>
                  <div class="row">
                    <div class="col-lg-12">
                      <ul *ngFor="let feature of category.fields">
                        <li *ngIf="!!feature.head">
                          <p class="b-detail__value">{{feature.head | translate}} </p>
                          <span>{{feature.label | translate}}</span>
                        </li>
                        <ng-container *ngIf="feature.labels && feature.labels.length && feature.labels.length > 0">
                          <li *ngFor="let label of feature.labels">{{label | translate}}</li>
                        </ng-container>
                        <ng-container *ngIf="feature.label">
                          <li *ngIf="!feature.head">{{feature.label | translate}}</li>
                        </ng-container>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-xs-12">
          <aside class="b-detail__main-aside">
            <div class="b-detail__main-aside-desc">
              <h2 class="s-titleDet">{{"CAR_DETAIL.DESCRIPTION" | translate}}</h2>
              <div class="row" *ngFor="let info of descriptionInfo">
                <div class="col-xs-6">
                  <h4 class="b-detail__main-aside-desc-title">{{info.label | translate}}</h4>
                </div>
                <div class="col-xs-6">
                  <div *ngIf="info.value !== null">
                    <p class="b-detail__main-aside-desc-value" *ngIf="info.type === 'number'">{{info.value}} {{info.measurementUnit ? (info.measurementUnit | translate) : ''}}</p>
                    <p class="b-detail__main-aside-desc-value" *ngIf="info.type !== 'number'">{{info.value | translate}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="b-detail__main-aside-about">
              <h2 class="s-titleDet">{{"CAR_DETAIL.INQUIRE" | translate}}</h2>
              <div class="b-detail__main-aside-about-call b-detail__contact">
                <span [class]="car.advert.tel_num !== 'none' ? 'fa fa-phone' : 'fa fa-envelope-o'"></span>
                <a [href]="car.advert.tel_num !== 'none' ? 'tel:' + car.advert.tel_num : 'mailto:' + car.advert.email">
                  {{car.advert.tel_num !== 'none' ? car.advert.tel_num : car.advert.email}}
                </a>
              </div>
              <div class="b-detail__main-aside-about-seller">
                <p>{{"CAR_DETAIL.SELLER_INFO" | translate}}: <span>{{car?.advert.contact_person}}</span></p>
                <!--    <button class="btn-link email" type="submit" (click)="openEmailPopup()">Գրել վաճառողին</button>-->
              </div>
            </div>
            <button *ngIf="showFacebookBtn" (click)="fbShareBtnClick()" class="fb-share">Share on facebook</button>
          </aside>
        </div>
      </div>
    </div>
  </section>
  <section class="b-featured">
    <div class="container">
      <h2 class="s-title">{{"OTHER.SIMILAR_CARS" | translate}}</h2>
      <!-- Need to include similar component like "top-vehicles.component.html -->
      <app-similar-cars-carousel></app-similar-cars-carousel>
    </div>
  </section>
</div>
