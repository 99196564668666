var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { Router } from '@angular/router';
import { EMPTY, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CarService } from '../../core/services/car.service';
import { ProfileService } from '../profile/services/profile.service';
import { AuthorizationService } from '../../core/services/authorization.service';
import { CarLocalItemsService } from '../../core/services/car-local-items.service';
import { FiltersConfig } from './filters.config';
import { getQueryParamsStr } from '../profile/utils';
import { QueryParamsService } from './services/query-params.service';
var ResolveFilterService = /** @class */ (function () {
    function ResolveFilterService(carService, router, carLocalItems, profileService, authService, queryParamsService) {
        this.carService = carService;
        this.router = router;
        this.carLocalItems = carLocalItems;
        this.profileService = profileService;
        this.authService = authService;
        this.queryParamsService = queryParamsService;
    }
    ResolveFilterService.prototype.resolve = function (route, state) {
        var _this = this;
        var requests = [];
        var obj = {};
        var models = [];
        var brands = [];
        var responses = [];
        var returnedObjOfObservables = [];
        this.queryParamsService.reset();
        return forkJoin([
            this.carService.getBrands(),
            this.carService.getCountries(),
            this.carService.getColors(),
            this.carService.getCarSeries(),
            this.carService.getCars(getQueryParamsStr(route.queryParams))
        ]).pipe(switchMap(function (results) {
            if (results[0] && results[1] && results[2] && results[3]) {
                _this.carLocalItems.brand = results[0].body.results;
                if (route.queryParams.hasOwnProperty('brand') && route.queryParams['brand']) {
                    brands = route.queryParams['brand'].split(',');
                    brands.forEach(function (brandId) {
                        _this.queryParamsService.brands.add(+brandId);
                        var findedBrand = results[0].body.results.find(function (brand) { return +brand.id === +brandId; });
                        if (findedBrand) {
                            requests.push(_this.carService.getModel(brandId));
                            FiltersConfig.filters.splice(1, 0, {
                                property: findedBrand.name,
                                isCollapsed: false,
                                multiple: true,
                                type: 'select',
                                label: findedBrand.name,
                                shadowProperty: 'model'
                            });
                            responses.push({ highProp: 'model', property: findedBrand.name });
                        }
                    });
                }
                if (route.queryParams.hasOwnProperty('model') && route.queryParams['model']) {
                    models = route.queryParams['model'].split(',');
                    models.forEach((function (modelId) { return requests.push(_this.carService.getGenerations(modelId)); }));
                    responses.push({ highProp: 'generation' });
                }
            }
            obj = {
                brand: of(results[0]),
                country: of(results[1]),
                color: of(results[2]),
                car_type: of(results[3]),
                results: of(results[4])
            };
            if (requests.length > 0) {
                return forkJoin(requests);
            }
            else {
                return of(null);
            }
        })).pipe(map(function (value) {
            if (value) {
                var _loop_1 = function (i) {
                    returnedObjOfObservables[responses[i].property] = of(value[i]);
                    // add selected models to service
                    if (route.queryParams.hasOwnProperty('model') && route.queryParams.model) {
                        var modelIDs_1 = new Set((route.queryParams.model || '').split(','));
                        value[i].body.results.forEach(function (model) {
                            if (modelIDs_1.has("" + model.id)) {
                                _this.queryParamsService.models.set(+model.id, model);
                            }
                        });
                    }
                };
                for (var i = 0; i < brands.length; i++) {
                    _loop_1(i);
                }
                var generationBrandName_1;
                var _loop_2 = function (i) {
                    // for (let j = 0; j < modelsCount; j++) {
                    // some models don't have generation list
                    if (value[i] && value[i].body && value[i].body.results && value[i].body.results.length > 0) {
                        generationBrandName_1 = value[i].body.results[0]['model']['brand'].name;
                        var index = FiltersConfig.filters.findIndex(function (item) { return item.property === generationBrandName_1; });
                        if (index !== -1) {
                            // add selected generations to service
                            var genIDs_1 = new Set((route.queryParams.generation || '').split(','));
                            value[i].body.results.forEach(function (gen) {
                                if (genIDs_1.has("" + gen.id) &&
                                    gen && gen.model && gen.model.brand && // in case of empty generation
                                    _this.queryParamsService.brands.has(gen.model.brand.id) &&
                                    _this.queryParamsService.models.has(gen.model.id)) {
                                    _this.queryParamsService.generation.set(gen.id, gen);
                                }
                            });
                            var modelName = value[i].body.results[0]['model'].name;
                            FiltersConfig.filters.splice(index + 1, 0, {
                                property: modelName,
                                isCollapsed: false,
                                multiple: true, type: 'select',
                                label: modelName,
                                shadowProperty: 'generation'
                            });
                            returnedObjOfObservables[modelName] = of(value[i]);
                        }
                    }
                    // }
                };
                for (var i = brands.length; i < models.length + brands.length; i++) {
                    _loop_2(i);
                }
                return __assign({}, obj, returnedObjOfObservables);
            }
            else {
                return obj;
            }
        }));
    };
    ResolveFilterService.prototype.errorHandler = function (error) {
        if (error.status === 404) {
            this.router.navigate(['/filter']);
            return EMPTY;
        }
        else if (error.status === 401) {
            this.authService.logOut();
            return EMPTY;
        }
    };
    return ResolveFilterService;
}());
export { ResolveFilterService };
