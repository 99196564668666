import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var CarLocalItemsService = /** @class */ (function () {
    function CarLocalItemsService(translate) {
        var _this = this;
        this.translate = translate;
        this.brand = [];
        this.model = [];
        this.generation = [];
        this.region = [];
        this.car_type = [];
        this.color = [];
        this.year = [];
        this.cubic_capacity = [];
        this.power = [];
        this.country = [];
        this.purchased_year = [];
        this.$translated = new Subject();
        for (var i = 3; i <= 100; i += 1) {
            this.cubic_capacity.push({
                name: i / 10,
                id: i / 10
            });
        }
        for (var i = 0; i < 1500; i += 10) {
            this.power.push({
                name: i,
                id: i
            });
        }
        var pastDate = new Date(0).getFullYear();
        var newDate = ST_Date.getFullYear();
        /*     for (let i = newDate; i >= pastDate; i--) {
              this.purchased_year.push({
                name: i,
                id: i
              });
            } */
        translate.get('CAR').subscribe(function (language) {
            _this.interior_type = [
                { name: language.INTERIOR_TYPE.TEXTILE, id: 'textile' },
                { name: language.INTERIOR_TYPE.VELOURS, id: 'velours' },
                { name: language.INTERIOR_TYPE.LEATHER, id: 'leather' },
                { name: language.INTERIOR_TYPE.COMBINED, id: 'combined' }
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.interior_flavor = [
                { name: language.INTERIOR_FLAVOR.WHITE, id: 'white' },
                { name: language.INTERIOR_FLAVOR.BLACK, id: 'black' },
                { name: language.INTERIOR_FLAVOR.GREY, id: 'grey' },
                { name: language.INTERIOR_FLAVOR.BEIGE, id: 'beige' },
                { name: language.INTERIOR_FLAVOR.RED, id: 'red' },
                { name: language.INTERIOR_FLAVOR.BROWN, id: 'brown' }
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.seat_count = [
                { name: language.SEATS_COUNT.TWO, id: 'two' },
                { name: language.SEATS_COUNT.FOUR_FIVE, id: 'four_five' },
                { name: language.SEATS_COUNT.SIX_EIGHT, id: 'six_eight' },
                { name: language.SEATS_COUNT.NINE_PLUS, id: 'nine_plus' }
            ];
            _this.climate_control = [
                { name: language.CLIMAT_CONTROL.CONDITIONER, id: 'conditioner' },
                { name: language.CLIMAT_CONTROL.ONE_ZONE, id: 'one_zone' },
                { name: language.CLIMAT_CONTROL.TWO_ZONE, id: 'two_zone' },
                { name: language.CLIMAT_CONTROL.THREE_ZONE, id: 'three_zone' }
            ];
            _this.window_regulator = [
                { name: language.WINDOW_REGULATOR.FRONT, id: 'front' },
                { name: language.WINDOW_REGULATOR.ALL, id: 'all' }
            ];
            _this.driver_seat_regulator = [
                { name: language.SEAT_REGULATOR.BY_HEIGHT, id: 'by_height' },
                { name: language.SEAT_REGULATOR.ELECTRO_REGULATOR, id: 'electro_regulator' },
                { name: language.SEAT_REGULATOR.WITH_MEMORY, id: 'with_memory' },
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.helm_regulator = [
                { name: language.HELM_REGULATOR.IN_ONE_PLANE, id: 'in_one_plane' },
                { name: language.HELM_REGULATOR.IN_TWO_PLANE, id: 'in_two_plane' },
                { name: language.HELM_REGULATOR.ELECTRO_REGULATOR, id: 'electro_regulator' },
            ];
            _this.airbag = [
                { name: language.AIRBAG.DRIVER_SIDE, id: 'driver_side' },
                { name: language.AIRBAG.DRIVER_AND_PASSENGER, id: 'driver_and_passenger' },
            ];
            _this.drive_type = [
                { name: language.DRIVE_TYPE.LEFT_HAND, id: 'left_hand' },
                { name: language.DRIVE_TYPE.RIGHT_HAND, id: 'right_hand' }
            ];
            _this.drive_wheel_type = [
                { name: language.DRIVE_WHEEL_TYPE.FRONT_WHEEL_DRIVE, id: 'front_wheel_drive' },
                { name: language.DRIVE_WHEEL_TYPE.REAR_DRIVE, id: 'rear_drive' },
                { name: language.DRIVE_WHEEL_TYPE.FOUR_WHEEL_DRIVE, id: 'four_wheel_drive' }
            ];
            _this.fuel_type = [
                { name: language.FUEL_TYPE.PETROL, id: 'petrol' },
                { name: language.FUEL_TYPE.DIESEL, id: 'diesel' },
                { name: language.FUEL_TYPE.HYBRID, id: 'hybrid' },
                { name: language.FUEL_TYPE.PETROL_GAS, id: 'petrol_gas' },
                { name: language.FUEL_TYPE.ELECTRIC, id: 'electric' }
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.owners_count = [
                { name: '1', id: 'one' },
                { name: '2', id: 'two' },
                { name: '3+', id: 'three_plus' }
            ];
            _this.usage = [
                { name: language.USAGE.UNDER_ONE_YEAR, id: 'under_one_year' },
                { name: language.USAGE.ONE_YEAR, id: 'one_year' },
                { name: language.USAGE.TWO_YEARS, id: 'two_years' },
                { name: language.USAGE.FROM_THREE_TO_FIVE, id: 'from_three_to_five' },
                { name: language.USAGE.FROM_SIX_TO_TEN, id: 'from_six_to_ten' },
                { name: language.USAGE.TEN_AND_MORE, id: 'ten_and_more' },
                { name: language.USAGE.NEW, id: 'is_new' }
            ];
            _this.currency = [
                { name: language.CURRENCY.USD, id: 'usd' },
                { name: language.CURRENCY.EUR, id: 'eur' },
                { name: language.CURRENCY.RUB, id: 'rub' },
                { name: language.CURRENCY.AMD, id: 'amd' }
            ];
            _this.transmission = [
                { name: language.TRANSMISSION.AUTOMATIC, id: 'automatic' },
                { name: language.TRANSMISSION.ROBOT, id: 'robot' },
                { name: language.TRANSMISSION.VARIATOR, id: 'variator' },
                { name: language.TRANSMISSION.MANUAL, id: 'manual' }
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.country = [
                { name: language.COUNTRY.ARMENIA, id: 'armenia' },
                { name: language.COUNTRY.USA, id: 'usa' },
                { name: language.COUNTRY.JAPAN, id: 'japan' },
                { name: language.COUNTRY.RUSSIA, id: 'russia' },
                { name: language.COUNTRY.GEORGIA, id: 'georgia' }
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.passenger_seat_regulator = [
                { name: language.SEAT_REGULATOR.BY_HEIGHT, id: 'by_height' },
                { name: language.SEAT_REGULATOR.ELECTRO_REGULATOR, id: 'electro_regulator' },
                { name: language.SEAT_REGULATOR.WITH_MEMORY, id: 'with_memory' },
            ].sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.posted_time = [
                { name: language.POSTED_TIME.TODAY, id: 'today' },
                { name: language.POSTED_TIME.TWO_DAYS, id: 'two_days' },
                { name: language.POSTED_TIME.THREE_DAYS, id: 'three_days' },
                { name: language.POSTED_TIME.ONE_WEEK, id: 'one_week' },
                { name: language.POSTED_TIME.TWO_WEEK, id: 'two_weeks' },
                { name: language.POSTED_TIME.MONTH, id: 'month' }
            ];
            _this.is_crashed = [
                { name: language.CRASHED.NO, id: false },
                { name: language.CRASHED.YES, id: true }
            ];
            _this.custom_clearance = [
                { name: language.CUSTOM_CLEARANCE.YES, id: true },
                { name: language.CUSTOM_CLEARANCE.NO, id: false }
            ];
            _this.guarantee = [
                { name: language.GUARANTEE.YES, id: true },
                { name: language.GUARANTEE.NO, id: false }
            ];
            _this.exchange = [
                { name: language.EXCHANGE.NO, id: false },
                { name: language.EXCHANGE.YES, id: true }
            ];
            _this.$translated.next(true);
        });
    }
    CarLocalItemsService.ngInjectableDef = i0.defineInjectable({ factory: function CarLocalItemsService_Factory() { return new CarLocalItemsService(i0.inject(i1.TranslateService)); }, token: CarLocalItemsService, providedIn: "root" });
    return CarLocalItemsService;
}());
export { CarLocalItemsService };
