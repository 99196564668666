<pagination-template #p="paginationApi" class="b-items__pagination-main"
                     (pageChange)="pageChange.emit($event)">

  <a class="m-left" style="cursor: pointer" *ngIf="!p.isFirstPage()" [class.disabled]="p.isFirstPage()" (click)="p.previous()"><span class="fa fa-angle-left"></span></a>
  <a class="m-left" *ngIf="p.isFirstPage()"><span class="fa fa-angle-left"></span></a>

  <ng-container *ngFor="let page of p.pages">
    <span [class.current]="p.getCurrent() === page.value" [class.m-active]="p.getCurrent() === page.value">
      <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{page.label}}</a>
    </span>
    <span [class.current]="p.getCurrent() === page.value" [class.m-active]="p.getCurrent() === page.value">
      <a *ngIf="p.getCurrent() === page.value">{{page.label}}</a>
    </span>
  </ng-container>

  <a class="m-right" style="cursor: pointer" *ngIf="!p.isLastPage()" [class.disabled]="p.isLastPage()" (click)="p.next()"><span class="fa fa-angle-right"></span></a>
  <a class="m-right" *ngIf="p.isLastPage()"><span class="fa fa-angle-right"></span></a>

</pagination-template>

<!--<div class="b-items__pagination-main">
  <a href="#" class="m-left"><span class="fa fa-angle-left"></span></a>
  <span class="m-active"><a href="#">1</a></span>
  <span><a href="#">2</a></span>
  <span><a href="#">3</a></span>
  <span><a href="#">4</a></span>
  <a href="#" class="m-right"><span class="fa fa-angle-right"></span></a>
</div>-->
