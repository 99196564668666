<div class="row">
  <div class="modal-dialog post-modal">
    <div class="col-lg-12 no-padding">
      <div class="b-password__form modal-content col-lg-12">
        <div class="b-contacts__form-header s-lineDownLeft">
          <button type="button" (click)="closeDialog()" class="close no-outline"><span aria-hidden="true">&times;</span></button>
          <h2 class="s-titleDet">{{'ADD_CAR.FROM_OTHER_SITE.MODAL_TITLE' | translate}}</h2>
          <p class="mt-2 subtitle">{{"PROFILE.AVAILABLE_SITES" | translate}} Auto.am, List.am, Copart.com, Iaai.com</p>
        </div>
        <form [formGroup]="form" (ngSubmit)="submitForm(form.value)" class="s-noPadding s-form">
          <ul class="list-unstyled">
            <li class="mb-2">
              <div class="text-field">
                <label>{{'SHARED.TITLE' | translate}} <span class="star">*</span></label>
                <input formControlName="url" type="text" [placeholder]="'ADD_CAR.FROM_OTHER_SITE.URL' | translate">
                <ng-container *ngFor="let error of validationMessages.url">
                  <p class="error-message absolute"
                     *ngIf="form.get('url').hasError(error.type) && form.get('url').touched && form.get('url').dirty || form.get('url').pending">{{error.message | translate}}</p>
                </ng-container>
              </div>
            </li>
            <li class="mb-2">
              <div class="pricing-field">
                <div class="text-field">
                  <label>{{'SHARED.PRICE' | translate}} <span class="star">*</span></label>
                  <div class="d-flex align-center row">
                    <div class="col-lg-8 col-md-8 col-xs-7 pr-0">
                      <div class="text-field no-margin">
                        <input appDigitOnly [maxDigits]="19" formControlName="price" maxlength="19" typcurrencye="text" [placeholder]="'SHARED.PRICE' | translate" class="pricing-input">
                        <ng-container *ngFor="let error of validationMessages.price">
                          <p class="error-message absolute"
                             *ngIf="form.get('price').hasError(error.type) && form.get('price').touched && form.get('price').dirty || form.get('price').pending">{{error.message | translate}}</p>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-5 pl-0">
                     <div class="select-box no-margin">
                        <app-select [items]="carLocalItemsService.currency" [clearable]="false" class="currency"
                                    formControlName="currency" [enableScroll]="true" [id]="'car-currency'"
                                    [placehold]="'SHARED.AMD' | translate"></app-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <button [disabled]="submitted" class="btn m-btn" type="submit">{{"ADD_CAR.ADD" | translate}}<span class="fa fa-angle-right"></span></button>
        </form>
      </div>
    </div>
  </div>
</div>
