var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseHttpService } from './base-http-service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service/cookie-service/cookie.service";
var AuthorizationService = /** @class */ (function (_super) {
    __extends(AuthorizationService, _super);
    function AuthorizationService(httpClient, cookieService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.cookieService = cookieService;
        _this.LOGIN_URL = '/auth-users/login/';
        _this.REGISTER_URL = '/auth-users/register/';
        _this.RESEND_EMAIL_URL = '/auth-users/resend-email/';
        _this.RESET_PASSWORD_URL = '/auth-users/reset-password/';
        _this.FORGOT_PASSWORD_URL = '/auth-users/forgot-password/';
        _this.CONFIRM_ACCOUNT_URL = '/auth-users/confirm-account/';
        _this.TOKEN_VERIFICATION = '/auth-users/token_verification/';
        return _this;
    }
    Object.defineProperty(AuthorizationService, "token", {
        get: function () { return AuthorizationService.authToken; },
        set: function (token) { AuthorizationService.authToken = token; },
        enumerable: true,
        configurable: true
    });
    AuthorizationService.prototype.login = function (credentials) {
        return this.post(this.LOGIN_URL, credentials);
    };
    AuthorizationService.prototype.register = function (credentials) {
        return this.post(this.REGISTER_URL, credentials);
    };
    AuthorizationService.prototype.confirmAccount = function (credentials) {
        return this.post(this.CONFIRM_ACCOUNT_URL, credentials);
    };
    AuthorizationService.prototype.resendEmail = function (credentials) {
        return this.post(this.RESEND_EMAIL_URL, credentials);
    };
    AuthorizationService.prototype.verifyToken = function (tokenObj) {
        return this.post(this.TOKEN_VERIFICATION, tokenObj);
    };
    AuthorizationService.prototype.resetPassword = function (credentials, resetKey) {
        return this.post("" + this.RESET_PASSWORD_URL + resetKey + "/", credentials);
    };
    AuthorizationService.prototype.forgotPassword = function (credentials) {
        return this.post(this.FORGOT_PASSWORD_URL, credentials);
    };
    AuthorizationService.prototype.saveTokenAndUserId = function (rememberMe, res) {
        if (rememberMe) {
            this.cookieService.set('token', res.body.token);
            this.cookieService.set('id', res.body.id);
            localStorage.setItem('token', res.body.token);
            localStorage.setItem('id', res.body.id);
        }
        else {
            AuthorizationService.token = res.body.token;
            AuthorizationService.id = res.body.id;
        }
    };
    AuthorizationService.prototype.isLoggedIn = function () {
        return !!localStorage.getItem('token') || this.cookieService.check('token') || !!AuthorizationService.token;
    };
    AuthorizationService.prototype.getToken = function () {
        return localStorage.getItem('token') || this.cookieService.get('token') || AuthorizationService.token;
    };
    AuthorizationService.prototype.getUserId = function () {
        return localStorage.getItem('id') || AuthorizationService.id;
    };
    AuthorizationService.prototype.logOut = function () {
        this.cookieService.delete('token');
        this.cookieService.delete('id');
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        AuthorizationService.token = null;
        AuthorizationService.id = null;
    };
    AuthorizationService.prototype.getEmailDomain = function (email) {
        return 'https://' + email.slice(email.lastIndexOf('@') + 1);
    };
    AuthorizationService.ngInjectableDef = i0.defineInjectable({ factory: function AuthorizationService_Factory() { return new AuthorizationService(i0.inject(i1.HttpClient), i0.inject(i2.CookieService)); }, token: AuthorizationService, providedIn: "root" });
    return AuthorizationService;
}(BaseHttpService));
export { AuthorizationService };
