import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header/header.component';
import {FooterComponent} from './footer/footer.component';
import {PagePreloaderComponent} from './page-preloader/page-preloader.component';
import {RouterModule} from '@angular/router';
import {MyTranslateRootModule} from '../../shared/modules/my-translate-root.module';
import {MatProgressBarModule} from '@angular/material';
import {InfoBoxModule} from '../../pages/info-box/info-box.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MyTranslateRootModule,
    MatProgressBarModule,
    InfoBoxModule
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    PagePreloaderComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    PagePreloaderComponent,
    MatProgressBarModule,
    MyTranslateRootModule
  ]
})
export class LayoutModule {
}
