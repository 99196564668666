export const addCarFromOtherSiteValidationMessages = {
  'url': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'unsupported-website', message: 'VALIDATION.UNSUPPORTED_WEBSITE_URL'}
  ],
  'price': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'notOnlyNumbers', message: 'VALIDATION.PRICE'},
    {type: 'min', message: 'VALIDATION.MIN_NUMBER_VALUE'}
  ],
};
