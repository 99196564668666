import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/router";
var languages;
(function (languages) {
    languages["arm"] = "am";
    languages["rus"] = "ru";
    languages["eng"] = "en";
    languages["am"] = "arm";
    languages["ru"] = "rus";
    languages["en"] = "eng";
})(languages || (languages = {}));
var AppLanguageService = /** @class */ (function () {
    function AppLanguageService(translator, router) {
        this.translator = translator;
        this.router = router;
        this.translator.setDefaultLang('arm');
        this.detectChanges();
        var lang = this.getLocalLanguage();
        this.defineLanguage(lang);
    }
    Object.defineProperty(AppLanguageService, "CURRENT_LANG", {
        get: function () {
            return languages[AppLanguageService.API_LANGUAGE];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppLanguageService, "TRANSFORMED_LANGUAGES", {
        get: function () {
            return AppLanguageService.langList.map(function (item) { return languages[item]; });
        },
        enumerable: true,
        configurable: true
    });
    AppLanguageService.prototype.detectChanges = function () {
        var lang = window.location.pathname.slice(1);
        if (languages[lang]) {
            this.setLocalLanguage(languages[lang]);
        }
    };
    AppLanguageService.prototype.defineLanguage = function (lang) {
        if (!lang)
            return;
        var isExist = AppLanguageService.langList.find(function (elem) { return elem === lang; });
        if (!isExist)
            return;
        this.translator.use(lang);
        this.setLocalLanguage(lang);
    };
    AppLanguageService.prototype.getLocalLanguage = function () {
        var lang = localStorage.getItem('language');
        if (!lang)
            return 'arm';
        return lang;
    };
    AppLanguageService.prototype.setLocalLanguage = function (lang) {
        localStorage.setItem('language', lang);
        AppLanguageService.API_LANGUAGE = languages[lang];
    };
    AppLanguageService.langList = ['arm', 'eng', 'rus'];
    AppLanguageService.API_LANGUAGE = "am";
    AppLanguageService.ngInjectableDef = i0.defineInjectable({ factory: function AppLanguageService_Factory() { return new AppLanguageService(i0.inject(i1.TranslateService), i0.inject(i2.Router)); }, token: AppLanguageService, providedIn: "root" });
    return AppLanguageService;
}());
export { AppLanguageService };
