var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
// abstract classes
import { BaseHttpService } from 'src/app/core/services/base-http-service';
var RelatedCarRequestService = /** @class */ (function (_super) {
    __extends(RelatedCarRequestService, _super);
    function RelatedCarRequestService(http) {
        return _super.call(this, http) || this;
    }
    RelatedCarRequestService.prototype.getCars = function () {
        return this.get('/related-car?limit=8');
    };
    return RelatedCarRequestService;
}(BaseHttpService));
export { RelatedCarRequestService };
