import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

// services
import { AddCarFromOtherWebsiteService } from '../services/add-car-from-other-website.service';
import { ValidationService } from '../../../../core/services/validation.service';
import { CarLocalItemsService } from '../../../../core/services/car-local-items.service';
import { MyToastrService } from '../../../../shared/toastr/my-toastr.service';

// models
import { AddCarFromOtherWebsitePayload } from '../models/add-car-from-other-website-payload.model';

// utils
import { AbstractForm } from '../../../../shared/form/abstract-form';
import { addCarFromOtherSiteValidationMessages } from './validation-messages';
import { SupportedWebsitesToAddCarFromValidator } from '../../../../shared/validators/supported-websites-validator';
import { backendErrorMessagesHandler } from 'src/app/shared/utils/utils';

@Component({
	selector: 'app-post-link-modal',
	templateUrl: './add-post-link-modal.component.html',
})
export class AddPostLinkModalComponent extends AbstractForm implements OnDestroy {
	public submitted = false;
	private $subscription: Subscription;

	constructor(
		private readonly addCarFromOtherWebsiteService: AddCarFromOtherWebsiteService,
		public readonly carLocalItemsService: CarLocalItemsService,
		private readonly customValidatorService: ValidationService,
		protected readonly fb: FormBuilder,
		private toastr: MyToastrService,
		protected dialogRef: MatDialogRef<AddPostLinkModalComponent>
	) {
		super(addCarFromOtherSiteValidationMessages, fb);
		this.controlsConfig = {
			url: [null, [Validators.required, SupportedWebsitesToAddCarFromValidator]],
			price: [null, [Validators.required, Validators.min(1), this.customValidatorService.onlyNumbers]],
			currency: ['amd']
		};
	}

	closeDialog(): void {
		this.dialogRef.close();
	}

	makeRequest(data: AddCarFromOtherWebsitePayload): void {
		if (this.submitted) {
			return;
		}

		this.submitted = true;
		this.$subscription = this.addCarFromOtherWebsiteService.createRequest(data).subscribe(
			() => this.closeDialog(),
			(error) => {
				if (error.status == 500) {
					this.toastr.error()
				}
				else {
					backendErrorMessagesHandler(error).forEach(err => this.toastr.error(err.field, err.error));
				}
				this.submitted = false;
			}
		);
	}

	ngOnDestroy(): void {
		if (this.$subscription) {
			this.$subscription.unsubscribe();
		}
		this.submitted = false;
	}
}
