import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// models
import { ADD_CAR_STATUSES } from '../../../profile/add-a-car/models/add-car-from-other-website-payload.model';
var InfoBoxComponent = /** @class */ (function () {
    function InfoBoxComponent(translate) {
        this.translate = translate;
        this.closeEvent = new EventEmitter();
        this.completedCount = 0;
        this.pendingCount = 0;
        this.dataForRender = [];
        this.statuses = ADD_CAR_STATUSES;
    }
    Object.defineProperty(InfoBoxComponent.prototype, "infoBoxHeaderText", {
        // instant is sync after lang change it not retranslate 
        get: function () {
            if (this.pendingCount > 0) {
                return this.pendingCount === 1 ? 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.IN_PROGRESS_SINGLE'
                    : this.pendingCount + ' ' + this.translate.instant('ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.IN_PROGRESS_MULTIPLE');
            }
            else if (this.completedCount > 0) {
                return this.completedCount === 1 ? 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.SUCCESS_SINGLE'
                    : 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.SUCCESS_MULTIPLE';
            }
            else if (this.dataForRender.length > 0) {
                return 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.FAILURE';
            }
        },
        enumerable: true,
        configurable: true
    });
    InfoBoxComponent.prototype.closeInfoBox = function (minimize) {
        if (minimize) {
            // console.log('Minimize info bar');
            return;
        }
        this.dataForRender = [];
        this.closeEvent.emit();
    };
    return InfoBoxComponent;
}());
export { InfoBoxComponent };
