import * as i0 from "@angular/core";
var QueryParamsService = /** @class */ (function () {
    function QueryParamsService() {
        this.brands = new Set();
        this.models = new Map();
        this.generation = new Map();
    }
    QueryParamsService.prototype.deleteGenerationsForUncheckedBrand = function (brandID) {
        var _this = this;
        this.generation.forEach(function (gen) {
            if (gen.model && gen.model.brand && +gen.model.brand.id === +brandID) {
                _this.generation.delete(+gen.id);
            }
        });
    };
    QueryParamsService.prototype.deleteGenerationsForUncheckedModel = function (modelID) {
        var _this = this;
        this.generation.forEach(function (gen) {
            if (gen.model && +gen.model.id === +modelID) {
                _this.generation.delete(+gen.id);
            }
        });
    };
    QueryParamsService.prototype.deleteModelsForUncheckedBrand = function (brandID) {
        var _this = this;
        this.models.forEach(function (model) {
            if (model && model.brand && +model.brand.id === +brandID) {
                _this.models.delete(+model.id);
            }
        });
    };
    QueryParamsService.prototype.reset = function () {
        this.brands.clear();
        this.models.clear();
        this.generation.clear();
    };
    QueryParamsService.ngInjectableDef = i0.defineInjectable({ factory: function QueryParamsService_Factory() { return new QueryParamsService(); }, token: QueryParamsService, providedIn: "root" });
    return QueryParamsService;
}());
export { QueryParamsService };
