import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {BaseHttpService} from '../../../core/services/base-http-service';
import {ChangePassword} from '../../../shared/models/change-password.model';
import {AccountInformation} from '../../../shared/models/account-information.model';
import {AuthorizationService} from '../../../core/services/authorization.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends BaseHttpService {
  private CHANGE_PASSWORD_URL = '/auth-users/change-password/';
  private ACCOUNT_INFORMATION = '/users/{id}/';

  constructor(private httpClient: HttpClient, private authService: AuthorizationService) {
    super(httpClient);
  }

  changePassword(body: ChangePassword) {
    return this.post(this.CHANGE_PASSWORD_URL, body);
  }

  manageAccountInformation(body: Partial<AccountInformation>) {
    return this.patch(this.ACCOUNT_INFORMATION.replace('{id}', this.authService.getUserId), body);
  }

  getAccountInformation<T>(headers?: Headers): Observable<HttpResponse<T>> {
    return this.get<T>(this.ACCOUNT_INFORMATION.replace('{id}', this.authService.getUserId), headers);
  }

  logOut() {
    this.authService.logOut();
  }
}
