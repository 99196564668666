import { Car } from '../models/car.model';
import { AccountInformation } from '../models/account-information.model';
import { AbstractControl, Validators } from '@angular/forms';
import { isEmailValid } from '../validators/email-or-phone-number-validator';
import { emailPattern } from '../../pages/authorization/validation-helper-model';
import { PhoneNumberValidator } from '../validators/phone-number-validator';
import { HttpErrorResponse } from '@angular/common/http';
import { IErrMsgByBackend } from '../models/error-message.interface';

export function urlFragment(car: Car) {
	return ((car.car_model && car.car_model.brand ? car.car_model.brand.name + '-' : '')
		+ (car.car_model ? car.car_model.name + '-' : '')
		+ (car.generation && car.generation.name ? car.generation.name + '-' : '') + car.production_date)
		// return (car.generation.model.brand.name + '-'
		//   + car.generation.model.name + '-' + car.generation.name + '-' + car.production_date)
		.replace(/"/g, '')
		.replace(/'/g, '')
		// .replace(/[()]/g, '-')
		// .replace(/\s/g, '-')
		.replace(/[^a-zA-Z0-9-]/g, '-');
}


export function animate(elem: HTMLElement, style: string, unit: string, from: number, to: number, time: number, prop: boolean): void {
	if (elem) {
		const start = new Date().getTime(),
			timer = setInterval((): void => {
				const step = Math.min(1, (new Date().getTime() - start) / time);
				if (prop) {
					elem[style] = (from + step * (to - from)) + unit;
				} else {
					elem.style[style] = (from + step * (to - from)) + unit;
				}
				if (step === 1) {
					clearInterval(timer);
				}
			}, 5);
		if (prop) {
			elem[style] = from + unit;
		} else {
			elem.style[style] = from + unit;
		}
	}
}

export function emailPhoneRequirementManager(accountInfo: AccountInformation, email: AbstractControl, phone: AbstractControl): {
	emailRequired: boolean; phoneRequired: boolean
} {
	let emailRequired = false;
	let phoneRequired = false;

	if (email.value && phone.value) {
		if (accountInfo.username.includes('@') && isEmailValid(accountInfo.username)) {
			email.setValidators(Validators.compose([Validators.required, Validators.pattern(emailPattern)]));
			phone.setValidators(Validators.compose([PhoneNumberValidator('am')]));
			emailRequired = true;
			phoneRequired = false;
		} else {
			email.setValidators(Validators.compose([Validators.pattern(emailPattern)]));
			phone.setValidators(Validators.compose([Validators.required, PhoneNumberValidator('am')]));
			emailRequired = false;
			phoneRequired = true;
		}
	} else if (!email.value && !phone.value) {
		email.setValidators(Validators.compose([Validators.required, Validators.pattern(emailPattern)]));
		phone.setValidators(Validators.compose([Validators.required, PhoneNumberValidator('am')]));
		emailRequired = true;
		phoneRequired = true;
	} else {
		if (email.value) { // => !phone.value
			email.setValidators(Validators.compose([Validators.required, Validators.pattern(emailPattern)]));
			phone.setValidators(Validators.compose([PhoneNumberValidator('am')]));
			emailRequired = true;
			phoneRequired = false;
		} else { // phone.value
			email.setValidators(Validators.compose([Validators.pattern(emailPattern)]));
			phone.setValidators(Validators.compose([Validators.required, PhoneNumberValidator('am')]));
			emailRequired = false;
			phoneRequired = true;
		}
	}

	email.updateValueAndValidity({ onlySelf: true, emitEvent: false });
	phone.updateValueAndValidity({ onlySelf: true, emitEvent: false });

	return {
		emailRequired,
		phoneRequired
	};
}

export function backendErrorMessagesHandler(err: HttpErrorResponse): IErrMsgByBackend[] {
	if (!err || !err.error || !err.error.errors) return [];
	console.log(err.error.errors);
	return err.error.errors;
}
