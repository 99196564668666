import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDigitOnly]'
})
export class DigitOnlyDirective {
  @Input() public isPhoneNumber = false;
  @Input() public maxDigits = 0;
  @Input() public allowMultipleZeros = false;
  @Input() public disableZerosAtStart = false;
  public inputElement: HTMLElement;

  private previousValue = '';

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: any) {
    this.previousValue = this.el.nativeElement.value;
    // if (e.altKey) {
    //   return;
    // }
    // if (
    //   [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 || // Allow: Delete, Backspace, Tab, Escape, Enter
    //   (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
    //   (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
    //   (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
    //   (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
    //   (e.keyCode === 65 && e.metaKey === true) || // Allow: Cmd+A (Mac)
    //   (e.keyCode === 67 && e.metaKey === true) || // Allow: Cmd+C (Mac)
    //   (e.keyCode === 86 && e.metaKey === true) || // Allow: Cmd+V (Mac)
    //   (e.keyCode === 88 && e.metaKey === true) || // Allow: Cmd+X (Mac)
    //   (e.keyCode >= 35 && e.keyCode <= 39) // Allow: Home, End, Left, Right
    // ) {
    //   // let it happen, don't do anything
    //   return;
    // }
    //
    // // disable multiple zeros (ex: 0000000...)
    // if (e.keyCode === 48 || e.keyCode === 96) {
    //   if (!allowTypeZerosForNumberInputs(e) && !this.allowMultipleZeros) {
    //     e.preventDefault();
    //   }
    // }
    //
    // // check for maxDigits
    // // if input length >= this.maxDigits - preventDefault();
    // if (
    //   this.maxDigits && this.maxDigits > 0
    //   && this.el.nativeElement && this.el.nativeElement.value
    //   && this.el.nativeElement.value.length >= this.maxDigits
    // ) {
    //   // if maximum character length is set for input
    //   if (!DigitOnlyDirective.isInputValueSelected(e.target)) {
    //     e.preventDefault();
    //   }
    // }
    //
    // // if we are writing a + sign, and we are NOT at start - preventDefault()
    //
    // // check for isPhoneNumber
    // if (this.isPhoneNumber) {
    //   // allow + character at start if it's a phone number
    //   if (
    //     this.el.nativeElement
    //     && (!this.el.nativeElement.value || this.el.nativeElement.selectionStart === 0)
    //     && (
    //       e.keyCode === 107 || // numpad +
    //       e.keyCode === 187 && e.shiftKey // Shift + Plus sign
    //     )
    //   ) {
    //     return;
    //   }
    // }
    //
    // // Ensure that it is a number and stop the keypress
    // if (
    //   (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
    //   (e.keyCode < 96 || e.keyCode > 105)
    // ) {
    //   e.preventDefault();
    // }
  }

  // for preventing Alt+Numpad symbols
  @HostListener('input', ['$event'])
  onInput(e: any) {
    if (!e.target.value || (this.isPhoneNumber && e.target.value === '+')) { //  || e.target.value.length === 1
      return;
    }

    if (e.target.value === '0') {
      if (this.disableZerosAtStart) {
        e.preventDefault();
      } else {
        return;
      }
    }

    // isPhoneNumber -> allow '+' sign
    // maxDigits -> substring
    // allowMultipleZeros -> disable multiple 0s at start
    let pattern: RegExp;

    if (this.isPhoneNumber) {
      pattern = /^(\+?[0-9]+$)/g;
      if (!pattern.test(e.target.value)) {
        // e.target.value = this.previousValue;
        e.target.value = e.target.value.includes('+') ? '+' + e.target.value.replace(/\D/g, '') : e.target.value.replace(/\D/g, '');
      }

      return;
    }

    if (!this.allowMultipleZeros) {
      pattern = /^[1-9]\d*$/g; // positive integers

      if (!pattern.test(e.target.value)) {
        e.target.value = this.previousValue;
        // e.target.value = e.target.value.replace(/\D/g, '');
      }
      // return;
    }

    if (this.maxDigits && this.maxDigits > 0) {
      pattern = /^\d+$/;
      if (e.target.value && e.target.value.length >= this.maxDigits + 1) {
        e.target.value = this.previousValue.substr(0, 4);
        return;
      }

      if (!pattern.test(e.target.value)) {
        // e.target.value = this.previousValue;
        e.target.value = e.target.value.replace(/\D/g, '');
      }
      return;
    }

    if (!/\d+$/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, '');
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    event.preventDefault();
    let pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D/g, ''); // get a digit-only string

    if (this.maxDigits && this.maxDigits > 0 &&
      (pastedInput.length > this.maxDigits ||
        (this.inputElement as HTMLInputElement).value && (this.inputElement as HTMLInputElement).value.length + pastedInput.length
        > this.maxDigits)
    ) {
      if ((this.inputElement as HTMLInputElement).value) {
        const selectionLength = (this.inputElement as HTMLInputElement).selectionEnd
          - (this.inputElement as HTMLInputElement).selectionStart;

        const inputActualLength = (this.inputElement as HTMLInputElement).value.length - selectionLength;

        const max = this.maxDigits - inputActualLength;
        pastedInput = !max ? '' : pastedInput.substr(0, max);
      } else {
        pastedInput = pastedInput.substr(0, this.maxDigits);
      }
    }
    pastedInput = !pastedInput ? '' : `${Number(pastedInput)}`;
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    let textData = event.dataTransfer.getData('text').replace(/\D/g, '');
    this.inputElement.focus();
    if (this.maxDigits && this.maxDigits > 0 &&
      (textData.length > this.maxDigits ||
        (this.inputElement as HTMLInputElement).value && (this.inputElement as HTMLInputElement).value.length + textData.length
        > this.maxDigits)
    ) {
      if ((this.inputElement as HTMLInputElement).value) {
        const selectionLength = (this.inputElement as HTMLInputElement).selectionEnd
          - (this.inputElement as HTMLInputElement).selectionStart;

        const inputActualLength = (this.inputElement as HTMLInputElement).value.length - selectionLength;

        const max = this.maxDigits - inputActualLength;
        textData = !max ? '' : textData.substr(0, max);
      } else {
        textData = textData.substr(0, this.maxDigits);
      }
    }
    textData = !textData ? '' : `${Number(textData)}`;
    document.execCommand('insertText', false, textData);
  }

  // public static isInputValueSelected(input: any, fully?: boolean): boolean {
  //   if (fully) {
  //     // console.log('isInputValueSelectedFully:', typeof input.selectionStart === 'number' && +input.selectionStart === 0 &&
  //     //   input.value && +input.selectionEnd === input.value.length);
  //     // console.log(input.selectionStart);
  //     // console.log(input.selectionEnd);
  //     // console.log(+input.selectionStart === 0);
  //     // console.log(input.value);
  //     // console.log(+input.selectionEnd === input.value.length);
  //     return typeof input.selectionStart === 'number' && +input.selectionStart === 0 &&
  //       input.value && +input.selectionEnd === input.value.length;
  //   }
  //   // console.log('selectionStart:', input.selectionStart);
  //   // console.log('selectionEnd:', input.selectionEnd);
  //   return typeof +input.selectionStart === 'number' && +input.selectionEnd - +input.selectionStart > 0;
  // }
}
