import {FormBuilder} from '@angular/forms';
import {AbstractForm} from '../../shared/form/abstract-form';

export abstract class AuthForm extends AbstractForm {
  isFormValid = false;
  emailDomain: string;

  protected constructor(
    validationMessages,
    private formBuilder: FormBuilder
  ) {
    super(validationMessages, formBuilder);
  }
}
