var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { OnDestroy } from '@angular/core';
import { AuthForm } from '../authorization/auth-form';
import { signUpValidationMessages } from '../authorization/validation-helper-model';
import { FormBuilder, Validators } from '@angular/forms';
import { EmailOrPhoneNumberValidator, isEmailValid } from '../../shared/validators/email-or-phone-number-validator';
import { Subject } from 'rxjs';
import { AuthorizationService } from '../../core/services/authorization.service';
import { Router } from '@angular/router';
import { passwordMatchValidator } from '../../shared/validators/password-must-match-validator';
import { takeUntil } from 'rxjs/operators';
import { isPhoneNumberValid } from '../../shared/validators/phone-number-validator';
import { backendErrorMessagesHandler } from 'src/app/shared/utils/utils';
var HomeComponent = /** @class */ (function (_super) {
    __extends(HomeComponent, _super);
    function HomeComponent(fb, authService, router) {
        var _this = _super.call(this, signUpValidationMessages, fb) || this;
        _this.fb = fb;
        _this.authService = authService;
        _this.router = router;
        _this.$subscription = new Subject();
        _this.registrationFailed = false;
        _this.isSignInFailed = false;
        _this.errorsMsgByBackend = [];
        _this.controlsConfig = {
            email_or_phone: ['', {
                    validators: Validators.compose([Validators.required, EmailOrPhoneNumberValidator('am')])
                }],
            password: ['', {
                    validators: Validators.compose([Validators.required, Validators.minLength(6)])
                }],
            repeat_password: ['', {
                    validators: Validators.compose([Validators.required, Validators.minLength(6)])
                }]
        };
        _this.extras = {
            validator: [passwordMatchValidator],
            updateOn: 'submit'
        };
        return _this;
    }
    HomeComponent.prototype.ngOnDestroy = function () {
        this.$subscription.next();
        this.$subscription.complete();
    };
    HomeComponent.prototype.getQueryParams = function () {
        return {
            limit: localStorage.getItem('limit') || 50,
            offset: 0
        };
    };
    HomeComponent.prototype.makeRequest = function (signUpFormData) {
        var _this = this;
        this.errorsMsgByBackend = [];
        this.errorMessage = '';
        var payload = {
            password: signUpFormData.password,
            repeat_password: signUpFormData.repeat_password
        };
        if (isEmailValid(signUpFormData.email_or_phone)) {
            payload['email'] = signUpFormData.email_or_phone;
        }
        else if (isPhoneNumberValid(signUpFormData.email_or_phone, 'am')) {
            payload['phone'] = signUpFormData.email_or_phone;
        }
        else {
            this.form.get('email_or_phone').setErrors({ 'wrongEmailOrPhoneNumber': true });
            return;
        }
        this.authService.register(payload).pipe(takeUntil(this.$subscription)).subscribe(function (res) {
            _this.isFormValid = true;
            // this.isSignUpSuccess = true;
            _this.emailDomain = _this.authService.getEmailDomain(_this.form.get('email_or_phone').value);
            _this.authService.login({
                username: payload['email'] || payload['phone'],
                password: payload.password,
            }).pipe(takeUntil(_this.$subscription)).subscribe(function (response) {
                _this.authService.saveTokenAndUserId(true, __assign({}, response));
                _this.router.navigate(['/submit-a-vehicle']);
            }, function (error) {
                _this.router.navigate(['/sign-in']);
                return;
            });
        }, function (error) {
            var code = error.error.code;
            if (code >= 500 || !code) {
                _this.errorMessage = 'AUTH.ERROR';
                _this.isFormValid = false;
                _this.registrationFailed = true;
            }
            else {
                _this.errorsMsgByBackend = backendErrorMessagesHandler(error);
            }
            _this.isSignInFailed = false;
            _this.registrationFailed = true;
        });
    };
    return HomeComponent;
}(AuthForm));
export { HomeComponent };
