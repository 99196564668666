import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InfobarComponent } from './infobar/infobar.component';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CarService } from '../../core/services/car.service';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { CarLocalItemsService } from '../../core/services/car-local-items.service';
import { filterCollapse, multipleCollapse } from '../../shared/animations/animations';
import { ResponseModel } from '../../shared/models/response-model.model';
import { Brand } from '../../shared/models/brand.model';
import { CarGeneration } from '../../shared/models/car-generation.model';
import { FiltersConfig } from './filters.config';
import { PagePreloaderComponent } from '../../core/layout/page-preloader/page-preloader.component';
import { RouterLoaderService } from '../../core/services/router-loader.service';
import { getQueryParamsStr } from '../profile/utils';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MyToastrService } from '../../shared/toastr/my-toastr.service';
import { Subscription } from 'rxjs';
import { InfobarEvent } from '../../shared/models/infobar-event.model';
import { QueryParamsService } from './services/query-params.service';
import { backendErrorMessagesHandler } from 'src/app/shared/utils/utils';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	animations: [filterCollapse, multipleCollapse]
})
export class FilterComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild(InfobarComponent) infoBarComponent: InfobarComponent;
	scrollPosition: [number, number];
	form: FormGroup;
	filters;
	routerParams;
	results;
	filterConfig: { [key: string]: any } = {
		limit: 10
	};
	private $getCarsSubscription: Subscription;

	constructor(private fb: FormBuilder,
		private carService: CarService,
		private router: Router,
		private viewportScroller: ViewportScroller,
		private routerLoaderService: RouterLoaderService,
		public carLocalItems: CarLocalItemsService,
		private activatedRoute: ActivatedRoute,
		private readonly queryParamsService: QueryParamsService,
		private readonly translate: TranslateService,
		private readonly toastr: MyToastrService
	) {
		this.filters = FiltersConfig.filters;
		const extras = this.filters.find(item => item.thisIsExtraFields);
		// for ordering in alphabetical order
		// при смене языка могут быть проблемы 
		extras.fields.forEach(item => item.label = this.translate.instant(item.label));
		extras.fields.sort((a, b) => a.label.localeCompare(b.label));
		this.activatedRoute.queryParams.subscribe(params => {
			this.routerParams = params;
			this.filterConfig = {
				...params
			};
		});
		this.subscribeToRouterParams();
		this.router.events.pipe(
			filter(e => e instanceof Scroll)
		).subscribe(e => {
			if ((e as Scroll).position) {
				this.scrollPosition = (e as Scroll).position;
			} else {
				this.scrollPosition = [0, 0];
			}
		});
	}

	toggleFilter() {
		const aside = document.getElementById('aside');
		aside.classList.toggle('open');
	}

	resetFilter() {

		this.form.reset();
		const queryParams = this.activatedRoute.snapshot.queryParams;
		const newParams = { limit: queryParams['limit'], offset: queryParams['offset'], posted_time_desc: 1 };
		this.filterConfig = newParams;

		// clear checkboxes (from back to forward order matters somehow??)
		const filters = [...this.filters];
		for (let i = filters.length - 1; i >= 0; i--) {
			if (filters[i].hasOwnProperty('shadowProperty')) {
				this.form.removeControl(filters[i].property);
				delete this.carLocalItems[filters[i].property];
				this.filters.splice(i, 1);
			}
		}

		// this.queryParamsService.reset();

		this.getCars(newParams);
	}

	ngOnInit() {
		PagePreloaderComponent.isGoToTopEnabled = false;
	}

	ngAfterViewInit(): void {
		setTimeout(() => this.viewportScroller.scrollToPosition(this.scrollPosition));
	}

	ngOnDestroy(): void {
		PagePreloaderComponent.isGoToTopEnabled = true;
		const filters = [...this.filters];
		let deletedCount = 0;
		filters.forEach((filteredItem, i) => {
			if (filteredItem.hasOwnProperty('shadowProperty')) {
				delete this.carLocalItems[filteredItem.property];
				this.filters.splice(i - deletedCount, 1);
				deletedCount++;
			}
		});

		if (this.$getCarsSubscription) {
			this.$getCarsSubscription.unsubscribe();
		}
	}

	get count() {
		return this.results.body.count;
	}

	search() {
		// this.getCars(queryParams);
	}

	getCars(queryParams) {
		this.filterConfig = {
			...this.filterConfig,
			...queryParams
		};

		if (this.$getCarsSubscription) {
			this.$getCarsSubscription.unsubscribe();
		}

		this.routerLoaderService.$loader.next(true);
		this.$getCarsSubscription = this.carService.getCars(getQueryParamsStr(this.filterConfig)).subscribe(async (res) => {
			this.results = res;
			const a = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			let bool = false;
			if (document.documentElement.scrollTop) {
				bool = true;
			}
			await this.router.navigate(['/filter'], {
				queryParams: this.filterConfig,
				relativeTo: this.activatedRoute
			});
			if (bool) {
				document.documentElement.scrollTop = a;
			} else {
				document.body.scrollTo(0, a);
			}
			this.routerLoaderService.$stopLoader.next();
		}, (error) => {
			backendErrorMessagesHandler(error).forEach(err => this.toastr.error(err.field, err.error));
			this.routerLoaderService.$stopLoader.next();
		});
	}

	listingAndSortingParamsChange(event: InfobarEvent) {
		event.remove.forEach(item => {
			if (this.filterConfig[item]) {
				delete this.filterConfig[item];
			}
		});

		const params = {};
		event.add.forEach(item => params[item] = 1);

		this.getCars({
			...params,
			limit: event.limit,
			offset: 0
		});
	}

	filterMultipleData(i, property, shadowProperty) {
		if (property === 'brand') {
			this.manageControl(i, property, 'model', this.carService.getModel<ResponseModel<Brand>>(this.carLocalItems[property][i].id));
			this.searchWithMultipleParams(property, property);
		} else {
			const modelIndex = this.carLocalItems.brand.findIndex(item => item.name === property);
			if (modelIndex !== -1 && shadowProperty && shadowProperty !== 'generation') {
				if ((this.form.get(property) as FormArray).at(i).value) {
					this.manageControl(i, property, 'generation',
						this.carService
							.getGenerations<ResponseModel<CarGeneration>>(this.carLocalItems[this.carLocalItems.brand[modelIndex].name][i].id));
				} else {
					this.manageControl(i, property, null, undefined);
				}
				this.searchWithMultipleParams(property, shadowProperty);
			} else if (shadowProperty === 'generation') {
				this.searchWithMultipleParams(property, shadowProperty);
			} else {
				this.searchWithMultipleParams(property, property);
			}
		}
	}

	searchWithMultipleParams(formProp, queryProp) {
		const queryParams = { ...this.filterConfig };
		const set = new Set();

		// merge with previous values
		if (this.filterConfig[queryProp]) {
			this.filterConfig[queryProp].split(',').forEach(id => {
				set.add(`${id}`);
			});
		}

		// get true values
		this.getFormArray(formProp).forEach((val, index) => {
			if (val.value) {
				set.add(`${this.carLocalItems[formProp][index].id}`);

				// brand/model/generation case is different, as they are connected
				if (queryProp === 'model') {
					const model = this.carLocalItems[formProp][index];
					if (!this.queryParamsService.models.has(model)) {
						this.queryParamsService.models.set(model.id, model);
					}
				}
				if (queryProp === 'generation') {
					const generation = this.carLocalItems[formProp][index];
					if (!this.queryParamsService.generation.has(generation)) {
						this.queryParamsService.generation.set(generation.id, generation);
					}
				}
			} else {
				if (set.has(`${this.carLocalItems[formProp][index].id}`)) {
					set.delete(`${this.carLocalItems[formProp][index].id}`);
				}

				// delete selected models/generations in case of brand deselect
				// and generations in case of model deselect
				if (queryProp === 'brand' || queryProp === 'model') {
					// itemID is either id of a brand or id of a model
					const itemID = this.carLocalItems[formProp][index].id;

					if (queryProp === 'model') {
						this.queryParamsService.deleteGenerationsForUncheckedModel(+itemID);
					}

					if (queryProp === 'brand') {
						this.queryParamsService.deleteGenerationsForUncheckedBrand(+itemID);
						this.queryParamsService.deleteModelsForUncheckedBrand(+itemID);
						this.filterConfig.model = Array.from(this.queryParamsService.models.keys()).join(',');
						queryParams['model'] = Array.from(this.queryParamsService.models.keys()).join(',');
					}

					// these 2 lines need to be executed both for brand and model deselect
					this.filterConfig.generation = Array.from(this.queryParamsService.generation.keys()).join(',');
					queryParams['generation'] = Array.from(this.queryParamsService.generation.keys()).join(',');
				}
			}
		});

		queryParams['offset'] = 0;
		queryParams[queryProp] = Array.from(set.values()).join(',');

		this.getCars(queryParams);
	}

	filterSingle(i, property, isCheckbox = false, defaultValue?: any) {
		const queryParams = {};
		if (this.filters[i].type === 'input') {
			// support for decimals with one number after dot
			// if no value => allow
			// if there is value =>
			//    a) it's 0
			//    b) it's < 0
			//    c) it is integer
			//    d) it is decimal


			let value = this.form.get(property).value;

			if (!value) {
				value = null;
			} else {
				if (!Number(value)) {
					value = defaultValue || 1;
				} else {
					if (value < 0) {
						value = value * -1;
					} else if (value === 0) {
						value = defaultValue || 1;
					}

					if (!Number.isInteger(+value)) {
						value = Number(value).toFixed(1);
					}
				}
			}

			this.form.get(property).setValue(value);
			queryParams[property] = this.form.get(property).value ? this.form.get(property).value : '';
		} else {
			// selects can have '' and false
			// for checkboxes change false/null to ''

			if (isCheckbox) {
				// this part refers to checkboxes (extra features i think)
				queryParams[property] = this.form.get(property).value ? true : '';
			} else {
				// this part refers to these filters: TAirbag, posted_time, is_crashed
				// this.form.get(property).value can be string, boolean or null
				queryParams[property] = this.form.get(property).value === null ? '' : this.form.get(property).value;
			}
		}
		queryParams['offset'] = 0;
		this.getCars(queryParams);
	}

	manageControl(index, property, shadowProperty, callback) {
		const name = this.carLocalItems[property][index].name;
		const value = (this.form.get(property) as FormArray).at(index).value;
		if (value) {
			callback.subscribe(res => {
				if ((this.form.get(property) as FormArray).at(index).value) {
					this.carLocalItems[name] = res.body.results;
					const controls = [];
					res.body.results.forEach(() => {
						controls.push(this.fb.control(null));
					});
					this.form.addControl(name, this.fb.array(controls));
					const i = this.filters.findIndex(item => item.property === property);
					this.filters.splice(i + 1, 0, {
						property: name, isCollapsed: false, multiple: true,
						type: 'select', label: name, shadowProperty: shadowProperty
					});
				}
			});
		} else if (this.carLocalItems.hasOwnProperty(name)) {
			const i = this.filters.findIndex(item => item.property === name);
			this.filters.splice(i, 1);
			this.form.removeControl(name);
			this.carLocalItems[name].forEach(item => {
				// item is model, carLocalItems[item.name] is generation
				if (this.carLocalItems.hasOwnProperty(item.name)) {
					delete this.carLocalItems[item.name];
					const j = this.filters.findIndex(filteredItem => filteredItem.property === item.name);
					this.filters.splice(j, 1);
				}
				if (this.form.get(item.name)) {
					this.form.removeControl(item.name);
				}
			});
			delete this.carLocalItems[name];
		}
	}

	private createForm() {
		const formGroup = {};
		this.filters.forEach(filteredItem => {
			let values = [];
			if (filteredItem.multiple) {
				if (this.routerParams.hasOwnProperty(filteredItem.property)) {
					values = (this.routerParams[filteredItem.property] as string).split(',');
				} else if (filteredItem.hasOwnProperty('shadowProperty') && this.routerParams.hasOwnProperty(filteredItem.shadowProperty)) {
					const routerP = (this.routerParams[filteredItem.shadowProperty] as string).split(',');
					let parentPropName;
					if (filteredItem.shadowProperty === 'model') {
						parentPropName = 'brand';
					} else if (filteredItem.shadowProperty === 'generation') {
						parentPropName = 'model';
					}
					this.carLocalItems[filteredItem.property].forEach(prop => {
						if (routerP.find(data => (+data === +prop.id) && (prop[parentPropName].name === filteredItem.property))) {
							values.push(prop.id);
						}
					});
				}

				formGroup[filteredItem.property] = this.buildCheckboxes(this.carLocalItems[filteredItem.property], values);

			} else if (!filteredItem.multiple && filteredItem.type === 'select') {
				if (!this.routerParams[filteredItem.property]) {
					// no value, maybe '' empty string
					formGroup[filteredItem.property] = this.fb.control(null);
				} else if (
					this.routerParams[filteredItem.property] === 'true' || this.routerParams[filteredItem.property] === 'false' ||
					+this.routerParams[filteredItem.property] === 1 || +this.routerParams[filteredItem.property] === 0
				) {
					// this is boolean
					formGroup[filteredItem.property] = this.fb.control(!!this.routerParams[filteredItem.property]);
				} else {
					// others have their string values
					formGroup[filteredItem.property] = this.fb.control(this.routerParams[filteredItem.property]);
				}
			} else if (filteredItem.type === 'input') {
				filteredItem.fields.forEach(field => {
					formGroup[field.property] = this.fb.control(this.routerParams[field.property]);
				});
			} else if (filteredItem.type === 'checkbox' && filteredItem.hasOwnProperty('fields')) {
				filteredItem.fields.forEach(field => {
					// convert to boolean otherwise 0 or '0' is valid value for checkbox
					formGroup[field.property] = this.fb.control(!!Number(this.routerParams[field.property]));
				});
			} else {
				formGroup[filteredItem.property] = this.fb.control(!!Number(this.routerParams[filteredItem.property]));
			}
		});
		this.form = this.fb.group(formGroup);


		// const queryParams = this.routerParams;
		// if (queryParams.brand) {
		//   this.queryParamsService.brands = new Set((queryParams.brand as string).split(','));
		// }
		// if (queryParams.model) {
		//   const modelIDs = (queryParams.model as string).split(',');
		//   modelIDs.forEach(id => {
		//     const model = this.getFormArray('')
		//   });
		// }
	}

	private buildCheckboxes(config, values: Array<any>): FormArray {
		if (!config) {
			return this.fb.array([]);
		}
		let arr: FormControl[];
		if (values) {
			arr = config.map(conf => {
				if (values.find(val => val.toString() === conf.id.toString())) {
					return this.fb.control(conf.id);
				} else {
					return this.fb.control(null);
				}
			});
		} else {
			arr = config.map(conf => {
				return this.fb.control(null);
			});
		}
		return this.fb.array(arr);
	}

	getFormArray(property) {
		return (this.form.get(property) as FormArray).controls;
	}

	getFormArrayLength(property) {
		return (this.form.get(property) as FormArray).controls.length;
	}

	private subscribeToRouterParams() {
		this.activatedRoute.data
			.subscribe((data: { car: any }) => {
				if (data.car) {
					Object.keys(data.car).forEach(prop => {
						this.initData(data.car[prop], prop);
					});
					this.createForm();
				}
			}).unsubscribe();
	}

	initData($response, property) {
		$response.subscribe(res => {
			if (res) {
				if (property === 'results') {
					this.results = res;
				}
				this.carLocalItems[property] = res.body.results;
			}
		});
	}

}
