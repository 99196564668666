<div class="header-content">
	<div id="this-is-top" class="b-topBar">
		<div class="container">
			<div class="row">
				<div class="col-xs-12 d-flex align-center justify-between flex-wrap b-topBar-items">
					<div class="b-topBar__addr b-topBar-item">
						<span class="fa fa-map-marker"></span>{{'HEADER.ADDRESS' | translate}}
					</div>
					<div class="d-flex justify-end flex-wrap b-topBar-items-right">
						<nav class="b-topBar__nav d-flex align-center">
							<ul class="no-margin">
								<li *ngIf="!isUserLoggedIn()"><a routerLink="/sign-up"
										routerLinkActive="true">{{'HEADER.SIGN_UP' | translate}}</a></li>
								<li *ngIf="!isUserLoggedIn()"><a routerLink="/sign-in"
										routerLinkActive="true">{{'HEADER.SIGN_IN' | translate}}</a></li>
								<li class="pointer" *ngIf="isUserLoggedIn()"><a routerLink="/sign-in"
										(click)="signOut()">{{'HEADER.LOGOUT' | translate}}</a></li>
							</ul>
						</nav>
						<div class="lang b-topBar-item d-flex align-center">
							<div class="b-topBar__lang">
								<div class="dropdown">

									<a (click)="onLangChangePanelClick()" class="m-langLink dropdown-toggle d-flex align-center clicable">
										<span class="b-topBar__lang-flag" [ngClass]="'m-' + currentLang"></span>{{currentLang | uppercase}}<span class="fa fa-caret-down"></span>
									</a>
									<ul *ngIf="isLangChangePanelOpen" class="dropdown-menu" style="display: block;">
										<li *ngFor="let lang of languages">
											<a (click)="onLangChange(lang)" class="m-langLink dropdown-toggle d-flex align-center clicable">
												<span class="b-topBar__lang-flag" [ngClass]="'m-' + lang"></span>{{lang | uppercase}}
											</a>
										</li>
									</ul>


								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--b-topBar-->

	<nav class="b-nav">
		<div class="container">
			<div class="row">
				<div class="col-sm-2 col-xs-4 logo">
					<div class="b-nav__logo">
						<a routerLink="/"><img src="../../../../assets/images/logo/logo.png" alt="Logo"></a>
					</div>
				</div>
				<div class="col-sm-10 col-xs-8">
					<div class="b-nav__list">
						<div class="navbar-header">
							<button type="button" class="navbar-toggle" (click)="toggleMenu()">
								<span class="sr-only">Toggle navigation</span>
								<span class="icon-bar"></span>
								<span class="icon-bar"></span>
								<span class="icon-bar"></span>
							</button>
						</div>
						<div class="navbar-collapse navbar-main-slide" id="nav" [@navBarCollapse]="menuIsOpened">
							<ul class="navbar-nav-menu">
								<li>
									<a routerLink="/" (click)="closeMenu()">{{'HEADER.HOME' | translate}}</a>
								</li>
								<li>
									<a routerLink="/about" (click)="closeMenu()">{{'HEADER.ABOUT' | translate}}</a>
								</li>
								<!--<li>-->
								<!--<a routerLink="/blog/posts" (click)="closeMenu()">{{'HEADER.BLOG' | translate}}</a>-->
								<!--</li>-->
								<li>
									<a routerLink="/contacts" (click)="closeMenu()">{{'HEADER.CONTACTS' | translate}}</a>
								</li>
								<li class="dropdown custom profile-items" [ngClass]="{ 'open' : isAnimated }" *ngIf="isUserLoggedIn()"
									(click)="toggleState()">
									<a class="dropdown-item">{{"CAR_DETAIL.MY_PROFILE" | translate}}<span
											class="fa fa-caret-down"></span></a>
									<ul class="dropdown-menu h-nav " [@navBarCollapseSubNav]="isProfileUlOpened" *ngIf="isAnimated">
										<li><a routerLink="/account-information"
												(click)="closeMenu()">{{"USER.ACCOUNT_INFORMATION" | translate}}</a></li>
										<li><a routerLink="/favourites" [queryParams]="{page: 1}"
												(click)="closeMenu()">{{"CAR_DETAIL.FAVORITES" | translate}}</a></li>
										<li><a routerLink="/own-adds" [queryParams]="{page: 1}"
												(click)="closeMenu()">{{"CAR_DETAIL.MY_ADDS" | translate}}</a></li>
									</ul>
									<ul class="dropdown-menu h-nav" *ngIf="!isAnimated">
										<li><a routerLink="/account-information"
												(click)="closeMenu()">{{"USER.ACCOUNT_INFORMATION" | translate}}</a></li>
										<li><a routerLink="/favourites" [queryParams]="{page: 1}"
												(click)="closeMenu()">{{"CAR_DETAIL.FAVORITES" | translate}}</a></li>
										<li><a routerLink="/own-adds" [queryParams]="{page: 1}"
												(click)="closeMenu()">{{"CAR_DETAIL.MY_ADDS" | translate}}</a></li>
									</ul>
								</li>
								<li *ngIf="isUserLoggedIn()">
									<button class="btn outline-primary bold"
										(click)="openAddPostLinkModal()">{{"ADD_CAR.FROM_OTHER_SITE.BUTTON_TEXT" | translate}}</button>
								</li>
								<li>
									<button class="btn primary no-outline bold" routerLink="/submit-a-vehicle"
										*ngIf="isUserLoggedIn()">{{"ADD_CAR.SELL_A_CAR" | translate}}</button>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
	<!--b-nav-->
</div>