import {ValidatorFn, AbstractControl} from '@angular/forms';
import {PhoneNumberUtil} from 'google-libphonenumber';

const phoneNumberUtil = PhoneNumberUtil.getInstance();

export function PhoneNumberValidator(regionCode?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    let validNumber = false;
    try {
      if (control.value) {
        let amconf = true;
        if (regionCode === 'am') {
          amconf = /^[+0-9]*$/.test(control.value);
        }
        if (amconf) {
          const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
            control.value, regionCode
          );
          validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
        } else {
          return {'onlyNumbers': {value: control.value}};
        }
      } else {
        validNumber = true;
      }
    } catch (e) {
    }

    return validNumber ? null : {'wrongNumber': {value: control.value}};
  };
}

export function isPhoneNumberValid(value: string, regionCode = 'am'): boolean {
  let validNumber = true;
  try {
    if (value) {
      let amconf = true;
      if (regionCode === 'am') {
        amconf = /^[+0-9]*$/.test(value);
      }
      if (amconf) {
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(
          value, regionCode
        );
        validNumber = phoneNumberUtil.isValidNumber(phoneNumber);
      }
    }
  } catch (e) {
  }

  return validNumber;
}
