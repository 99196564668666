<form [formGroup]="form" [ngClass]="{'collapse' : collapsed}" [@navBarCollapse]="collapsed">
  <div class="row">
    <div class="col-xs-12 col-md-12 " id="advanced-options">
      <div class="col-xs-12 col-md-12 ">
        <div class="m-advancedSelects">
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.COUNTRY" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select
                      [items]="countries"
                      formControlName="country"
                      [onChangeEvent]="getRegions"
                      [placehold]="'ADD_CAR.COUNTRY' | translate"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.REGION" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select
                      formControlName="region"
                      [items]="regions"
                      [placehold]="'ADD_CAR.REGION' | translate"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"CAR_DETAIL.USAGE" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select formControlName="usage"
                      [items]="carLocalItemsService.usage"
                      [placehold]="'CAR_DETAIL.USAGE' | translate"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"HOME.MILE" | translate}}</p>
            <div class="col-xs-6 pr-1 pl-0">
              <div class="text-field no-margin">
                <input appDigitOnly [disableZerosAtStart]="true" formControlName="kilometer_range_min" type="text" [placeholder]="'CAR.FROM' | translate" />
              </div>
            </div>
            <div class="col-xs-6 pl-1 pr-0">
              <div class="text-field no-margin">
                <input appDigitOnly [disableZerosAtStart]="true" formControlName="kilometer_range_max" type="text" [placeholder]="'CAR.TO' | translate" />
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"CAR_DETAIL.DAMAGE" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select
                      formControlName="is_crashed"
                      [items]="carLocalItemsService.is_crashed" [enableScroll]="false" [id]="'car-damage'"
                      [placehold]="'ADD_CAR.DAMAGE' | translate"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"CAR_DETAIL.TRANSMISSION" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select
                      formControlName="transmission" [items]="carLocalItemsService.transmission"
                      [enableScroll]="true" [id]="'car-transmission-type'"
                      [placehold]="'ADD_CAR.TRANSMISSION' | translate"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{'CAR_DETAIL.FUEL_TYPE' | translate}}</p>
            <div class="select-box no-margin">
              <app-select formControlName="fuel_type" [items]="carLocalItemsService.fuel_type" [enableScroll]="true" [id]="'car-fuel-type'"
                          [placehold]="'CAR_DETAIL.FUEL_TYPE' | translate"></app-select>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.DRIVE_TYPE" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select
                      formControlName="drive_type" [items]="carLocalItemsService.drive_type"
                      [enableScroll]="true" [id]="'car-drive-type'" [placehold]="'ADD_CAR.DRIVE_TYPE' |
                    translate"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{'ADD_CAR.DRIVE' | translate}}</p>
            <div class="select-box no-margin">
              <app-select formControlName="drive_wheel_type" [items]="carLocalItemsService.drive_wheel_type" [enableScroll]="true" [id]="'car-drive'"
                          [placehold]="'ADD_CAR.DRIVE' | translate"></app-select>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.CUBIC_CAPACITY" | translate}}</p>
            <div class="col-xs-6 pr-1 pl-0">
              <!--SELECT COMPONENT-->
              <div class="select-box no-margin">
                <app-select
                        formControlName="cubic_min"
                        [items]="carLocalItemsService.cubic_capacity"
                        [placehold]="'CAR.FROM' | translate"
                ></app-select>
              </div>
              <!--SELECT COMPONENT-->
            </div>
            <div class="col-xs-6 pr-0 pl-1">
              <!--SELECT COMPONENT-->
              <div class="select-box no-margin">
                <app-select
                        formControlName="cubic_max"
                        [items]="carLocalItemsService.cubic_capacity"
                        [placehold]="'CAR.TO' | translate"
                ></app-select>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.POWER" | translate}}</p>
            <div class="col-xs-6 pr-1 pl-0">
              <div class="text-field no-margin">
                <input appDigitOnly [disableZerosAtStart]="true" formControlName="power_min" type="text" [placeholder]="'CAR.FROM' | translate" />
              </div>
            </div>
            <div class="col-xs-6 pr-0 pl-1">
              <div class="text-field no-margin">
                <input appDigitOnly [disableZerosAtStart]="true" formControlName="power_max" type="text" [placeholder]="'CAR.TO' | translate" />
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"HOME.COLOR" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box options no-margin">
              <ng-select formControlName="color" [items]="colors" [searchable]="false"
                         [clearable]="true" [placeholder]="'ADD_CAR.COLOR' | translate"
                         bindLabel="name"
                         bindValue="id">
                <ng-template ng-label-tmp let-item="item">
                  <svg height="15" width="15">
                    <circle cx="6" cy="6" r="5" stroke="black" stroke-width="0.5" attr.fill="{{item.hash}}" />
                  </svg><i>{{item.name}}</i>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <svg height="15" width="15">
                    <circle cx="6" cy="6" r="5" stroke="black" stroke-width="0.5" attr.fill="{{item.hash}}" />
                  </svg><i>{{item.name}}</i>
                </ng-template>
              </ng-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item" *ngFor="let select of extraSelects">
            <p class="c-label">{{select.label | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select
                      [items]="carLocalItemsService[select.property]"
                      [placehold]="select.label | translate"
                      [enableScroll]="false"
                      id="filter-{{select.label}}"
                      [formControlName]="select.property"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.NUMBER_OF_OWNERS" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select formControlName="owners_count" [items]="carLocalItemsService.owners_count"
                          [enableScroll]="true" [id]="'car-mile'"
                          [placehold]="'ADD_CAR.NUMBER_OF_OWNERS' | translate"></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
          <div class="col-xs-12 col-md-4 col-sm-6 mb-4 item">
            <p class="c-label">{{"ADD_CAR.POSTED_TIME" | translate}}</p>
            <!--SELECT COMPONENT-->
            <div class="select-box no-margin">
              <app-select formControlName="posted_time"
                          [placehold]="'ADD_CAR.POSTED_TIME' | translate"
                          [items]="carLocalItemsService.posted_time"
              ></app-select>
            </div>
            <!--SELECT COMPONENT-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-md-12">
      <div class="col-xs-12 col-md-12">
        <div class="m-advancedSelects">
          <div class="m-advancedSelects">
            <div class="col-xs-12 col-md-6 col-sm-6 col-lg-4 mb-4 b-submit__main-element item">
              <input formControlName="custom_clearance" value="false" type="checkbox" name="check28" id="check28"/>
              <label class="s-submitCheckLabel" for="check28"><span class="fa fa-check"></span></label>
              <label class="s-submitCheck" for="check28">{{'CAR.CUSTOM_CLEARANCE.LABEL' | translate}}</label>
            </div>
            <div class="col-xs-12 col-md-6 col-sm-6 col-lg-4 mb-4 b-submit__main-element item">
              <input formControlName="guarantee" value="false" type="checkbox" name="check29" id="check29"/>
              <label class="s-submitCheckLabel" for="check29"><span class="fa fa-check"></span></label>
              <label class="s-submitCheck" for="check29">{{'ADD_CAR.GUARANTEE' | translate}}</label>
            </div>
            <div class="col-xs-12 col-md-6 col-sm-6 col-lg-4 mb-4 b-submit__main-element item" *ngFor="let checkbox of checkboxes; index as i">
              <input [formControlName]="checkbox.property" type="checkbox" name="check1" id="check1{{i}}">
              <label class="s-submitCheckLabel" for="check1{{i}}"><span class="fa fa-check"></span></label>
              <label class="s-submitCheck" for="check1{{i}}">{{checkbox.label | translate}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
