import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// modules
import {MyTranslateChildModule} from '../../shared/modules/my-translate-child.module';
import {RouterModule} from '@angular/router';

// services
import {InfoBoxService} from './services/info-box.service';

// components
import {InfoBoxComponent} from './components/info-box/info-box.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MyTranslateChildModule
  ],
  declarations: [
    InfoBoxComponent
  ],
  providers: [
    InfoBoxService
  ],
  entryComponents: [
    InfoBoxComponent
  ]
})
export class InfoBoxModule {
}
