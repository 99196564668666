import { AppLanguageService } from './app-language.service';
import * as i0 from "@angular/core";
var HeaderInterceptorService = /** @class */ (function () {
    function HeaderInterceptorService() {
    }
    HeaderInterceptorService.prototype.intercept = function (request, next) {
        request = request.clone({
            setHeaders: {
                'Accept-Language': AppLanguageService.API_LANGUAGE,
            }
        });
        return next.handle(request);
    };
    HeaderInterceptorService.ngInjectableDef = i0.defineInjectable({ factory: function HeaderInterceptorService_Factory() { return new HeaderInterceptorService(); }, token: HeaderInterceptorService, providedIn: "root" });
    return HeaderInterceptorService;
}());
export { HeaderInterceptorService };
