var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../../../core/services/base-http-service';
import { AuthorizationService } from '../../../core/services/authorization.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../core/services/authorization.service";
var ProfileService = /** @class */ (function (_super) {
    __extends(ProfileService, _super);
    function ProfileService(httpClient, authService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authService = authService;
        _this.CHANGE_PASSWORD_URL = '/auth-users/change-password/';
        _this.ACCOUNT_INFORMATION = '/users/{id}/';
        return _this;
    }
    ProfileService.prototype.changePassword = function (body) {
        return this.post(this.CHANGE_PASSWORD_URL, body);
    };
    ProfileService.prototype.manageAccountInformation = function (body) {
        return this.patch(this.ACCOUNT_INFORMATION.replace('{id}', this.authService.getUserId), body);
    };
    ProfileService.prototype.getAccountInformation = function (headers) {
        return this.get(this.ACCOUNT_INFORMATION.replace('{id}', this.authService.getUserId), headers);
    };
    ProfileService.prototype.logOut = function () {
        this.authService.logOut();
    };
    ProfileService.ngInjectableDef = i0.defineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.inject(i1.HttpClient), i0.inject(i2.AuthorizationService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}(BaseHttpService));
export { ProfileService };
