import {OnInit} from '@angular/core';

export class Carousel implements OnInit {
  imgags = [
    'assets/media/237x202/default.png'
  ];
  public carouselTileItems: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  public carouselTiles = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: []
  };
  public options = {
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 20000,
    autoplaySpeed: 400,
    navSpeed: 400,
    autoplayHoverPause: true,
    lazyLoad: true,
    slideBy: 4,
    margin: 14,
    responsive: {
      '0': {items: 1, margin: 5},
      '480': {items: 2, margin: 5},
      '640': {items: 3, margin: 5},
      '1200': {items: 4, margin: 5}
    }
  };

  constructor() {
  }

  ngOnInit() {
    // this.carouselTileItems.forEach(el => {
    //   this.carouselTileLoad(el);
    // });
  }

  public carouselTileLoad(j) {
    const len = this.carouselTiles[j].length;
    if (len <= 30) {
      for (let i = len; i < len + 15; i++) {
        this.carouselTiles[j].push(
          this.imgags[Math.floor(Math.random() * this.imgags.length)]
        );
      }
    }
  }

}
