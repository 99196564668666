import {EMPTY} from 'rxjs';
import {Params, Router} from '@angular/router';

export function errorHandler(error, router: Router, urlToNavigate) {
  if (error.status === 404) {
    router.navigate([urlToNavigate]);
    return EMPTY;
  } else if (error.status === 401) {
    // authService.logOut();
    return EMPTY;
  }
}

export function getPageFromQueryParams(queryParams: Params) {
  let page = 1;
  if (!queryParams.hasOwnProperty('page') ||
    (typeof +queryParams['page']) === 'number') {
    page = +queryParams['page'];
  }
  return page;
}

export function getQueryParamsStr(obj) {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
}

export function getDateDifferenceInMinutes(start: Date, end: Date): number {
  const difference = end.getTime() - start.getTime();
  return Math.round(difference / 60000);
}
