import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[appLetterOnly]'
})
export class LetterOnlyDirective implements OnInit {
  @Input() allowDigits = false;
  @Input() allowSpaces = false;
  @Input() isEmailField = false;
  public inputElement: HTMLInputElement;
  private pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё]*$/;
  private removePattern = /[^a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё]+/gi;
  private previousValue = '';

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  ngOnInit(): void {
    // allowDigits but disableSpaces
    // allowDigits and allowSpaces
    // disableDigits but allowSpaces
    // disableDigits and disableSpaces

    if (this.isEmailField) {
      this.allowDigits = true;
      this.allowSpaces = false;
    }

    // default -> disableDigits and disableSpaces
    if (this.allowDigits) {
      if (this.allowSpaces) {
        this.pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё0-9 ]*$/;
      } else {
        this.pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё0-9!#$%@&'*+-./=?^_`{|}~]*$/;
      }
    } else if (this.allowSpaces) {
      this.pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё ]*$/;
    }

    // // default => disableDigits and disableSpaces
    // if (this.allowDigits) {
    //   if (this.disableSpaces) {
    //     this.pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё0-9 ]*$/;
    //   } else {
    //     this.pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё0-9 ]*$/;
    //   }
    // } else if (!this.disableSpaces) {
    //   this.pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-яևЁё ]*$/;
    // }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    this.previousValue = this.inputElement.value.replace(/\s+/g, ' ').trim();

    // if (
    //   [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 || // Allow: Delete, Backspace, Tab, Escape, Enter
    //   (e.keyCode === 65 && e.ctrlKey === true) || // Allow: Ctrl+A
    //   (e.keyCode === 67 && e.ctrlKey === true) || // Allow: Ctrl+C
    //   (e.keyCode === 86 && e.ctrlKey === true) || // Allow: Ctrl+V
    //   (e.keyCode === 88 && e.ctrlKey === true) || // Allow: Ctrl+X
    //   (e.keyCode === 65 && e.metaKey === true) || // Allow: Cmd+A (Mac)
    //   (e.keyCode === 67 && e.metaKey === true) || // Allow: Cmd+C (Mac)
    //   (e.keyCode === 86 && e.metaKey === true) || // Allow: Cmd+V (Mac)
    //   (e.keyCode === 88 && e.metaKey === true) || // Allow: Cmd+X (Mac)
    //   (e.keyCode >= 35 && e.keyCode <= 39) // Allow: Home, End, Left, Right
    // ) {
    //   // let it happen, don't do anything
    //   return;
    // }
    //
    // const value = e.key;
    //
    // if (this.isEmailField) {
    //   console.log(1);
    //   if (emailPattern.test(e.key) || e.keyCode === 32) {
    //     e.preventDefault();
    //     console.log(2);
    //     return;
    //   }
    //   // if (this.isEmailField) {
    //   //   if (e.keyCode === 32) {
    //   //     e.preventDefault();
    //   //     return;
    //   //   }
    //   // }
    //   return;
    // }
    //
    // // check for spaces
    // if (this.disableSpaces) {
    //   // disable spaces before/after a space (allow only 1 space between words)
    //   if (
    //     ( // this.inputElement.selectionStart === 0 || // start of the input
    //       // this.inputElement.selectionStart === this.el.nativeElement.value.length || // end of the input
    //       this.el.nativeElement.value[this.inputElement.selectionStart - 1] === e.key || // previous char is space
    //       this.el.nativeElement.value[this.inputElement.selectionStart] === e.key // current(next) char is space
    //     )
    //     &&
    //     (e.keyCode === 32)
    //   ) {
    //     e.preventDefault();
    //   }
    // }
    // if (!this.pattern.test(value)) {
    //   e.preventDefault();
    // }
  }

  @HostListener('input', ['$event'])
  onInput(e: any) {
    if (!e.target.value) { // || e.target.value.length === 1
      return;
    }

    if (this.isEmailField) {
      // if (e.target.value.includes(' ') || e.keyCode === 32) {
      //   e.target.value = e.target.value.replace(/\s+/g, '').trim();
      // }
      e.target.value = e.target.value.replace(/[^a-zA-Z0-9-_.@]/g, '').trim();
      return;
    }

    if (!this.pattern.test(e.target.value)) {
      e.target.value = this.previousValue;
    }

    if (/\s\s+/g.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\s+/g, ' ');
    }
  }

  @HostListener('blur', ['$event'])
  onFocusOut(e: any) {
    if (e.target.value) {
      e.target.value = e.target.value.replace(/\ss+/g, ' ').trim();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: any) {
    event.preventDefault();
    let pastedInput: string = event.clipboardData
      .getData('text/plain');
    // .replace(this.removePattern, ''); // get a string matching this.pattern
    if (this.isEmailField) {
      pastedInput = pastedInput.replace(/\s\s+/g, '').trim();
    } else {
      pastedInput = pastedInput.replace(this.removePattern, ' ');
      if (!this.allowSpaces) {
        pastedInput = pastedInput.replace(/\s\s+/g, ' ').trim();
      }
    }
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    let textData = event.dataTransfer.getData('text');
    if (this.isEmailField) {
      textData = textData.replace(/\s\s+/g, '').trim();
    } else {
      textData = textData.replace(this.removePattern, '');
      if (!this.allowSpaces) {
        textData = textData.replace(/\s\s+/g, ' ').trim();
      }
    }
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}
