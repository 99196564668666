/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./page-not-found.component";
var styles_PageNotFoundComponent = [];
var RenderType_PageNotFoundComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageNotFoundComponent, data: {} });
export { RenderType_PageNotFoundComponent as RenderType_PageNotFoundComponent };
export function View_PageNotFoundComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "section", [["class", "b-pageHeader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error 404 Page"])), (_l()(), i0.ɵeld(4, 0, null, null, 8, "div", [["class", "b-breadCumbs s-shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "a", [["class", "b-breadCumbs__page"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Home"])), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "fa fa-angle-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "a", [["class", "b-breadCumbs__page m-active"], ["routerLink", "/page-not-found"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["Page Not Found"])), (_l()(), i0.ɵeld(13, 0, null, null, 12, "section", [["class", "b-error s-shadow"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 11, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error"])), (_l()(), i0.ɵeld(17, 0, null, null, 4, "p", [["class", "not-found"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["4"])), (_l()(), i0.ɵeld(19, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["0"])), (_l()(), i0.ɵted(-1, null, ["4"])), (_l()(), i0.ɵeld(22, 0, null, null, 1, "h2", [["class", "s-lineDownCenter"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["page not found"])), (_l()(), i0.ɵeld(24, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The page you are looking for is not available and might have been removed, its name changed or is temporarily unavailable."]))], function (_ck, _v) { var currVal_2 = "/"; _ck(_v, 7, 0, currVal_2); var currVal_5 = "/page-not-found"; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 7).target; var currVal_1 = i0.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 11).target; var currVal_4 = i0.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); }); }
export function View_PageNotFoundComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-not-found", [], null, null, null, View_PageNotFoundComponent_0, RenderType_PageNotFoundComponent)), i0.ɵdid(1, 114688, null, 0, i3.PageNotFoundComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageNotFoundComponentNgFactory = i0.ɵccf("app-page-not-found", i3.PageNotFoundComponent, View_PageNotFoundComponent_Host_0, {}, {}, []);
export { PageNotFoundComponentNgFactory as PageNotFoundComponentNgFactory };
