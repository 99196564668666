import {ErrorHandler, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './pages/home/home.component';
import {FilterComponent} from './pages/filter-pages/filter.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';
import {TopSliderComponent} from './pages/home/top-slider/top-slider.component';
import {SearchSectionComponent} from './pages/home/search-section/search-section.component';
import {VehicleTypeComponent} from './pages/home/search-section/vehicle-type/vehicle-type.component';
import {AdvancedSearchComponent} from './pages/home/search-section/advanced-search/advanced-search.component';
import {TopVehiclesComponent} from './pages/home/top-vehicles/top-vehicles.component';
import {UrgentSaleComponent} from './pages/home/urgent-sale/urgent-sale.component';
import {CarouselItemComponent} from './pages/home/carousel-item/carousel-item.component';
import {RangeSliderComponent} from './shared/ui/range-slider/range-slider.component';
import {SafeImageComponent} from './shared/ui/safe-image/safe-image.component';
import {InfobarComponent} from './pages/filter-pages/infobar/infobar.component';
import {FilteredItemsComponent} from './pages/filter-pages/filtered-items/filtered-items.component';
import {CoreModule} from './core/core.module';
import {MatDialogModule} from '@angular/material/dialog';
import {EmailPopupComponent} from './pages/detail-view/email-popup/email-popup.component';
import {AddPostLinkModalComponent} from './pages/profile/add-a-car/add-post-link-modal/add-post-link-modal.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RollbarErrorHandler, rollbarFactory, RollbarService} from './shared/rollbar/rollbar-error-handler.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FilterComponent,
    PageNotFoundComponent,
    TopSliderComponent,
    SearchSectionComponent,
    VehicleTypeComponent,
    AdvancedSearchComponent,
    TopVehiclesComponent,
    UrgentSaleComponent,
    CarouselItemComponent,
    RangeSliderComponent,
    SafeImageComponent,
    InfobarComponent,
    FilteredItemsComponent,
    EmailPopupComponent,
    AddPostLinkModalComponent,
  ],
  imports: [
    CoreModule.forRoot(),
    AppRoutingModule,
    MatDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: [EmailPopupComponent, AddPostLinkModalComponent],
  providers: [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
  ]
})
export class AppModule { }
