import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CarService} from '../../core/services/car.service';
import {ProfileService} from '../profile/services/profile.service';
import {AuthorizationService} from '../../core/services/authorization.service';
import {ResponseModel} from '../../shared/models/response-model.model';
import {Brand} from '../../shared/models/brand.model';
import {Country} from '../../shared/models/region.model';
import {Color} from '../../shared/models/color.model';
import {Model} from '../../shared/models/model.model';
import {CarGeneration} from '../../shared/models/car-generation.model';
import {CarLocalItemsService} from '../../core/services/car-local-items.service';
import {FiltersConfig} from './filters.config';
import {getQueryParamsStr} from '../profile/utils';
import {Car} from '../../shared/models/car.model';
import {QueryParamsService} from './services/query-params.service';

@Injectable()
export class ResolveFilterService implements Resolve<any> {

  constructor(private carService: CarService,
              private router: Router,
              private carLocalItems: CarLocalItemsService,
              private profileService: ProfileService,
              private authService: AuthorizationService,
              private readonly queryParamsService: QueryParamsService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const requests: any = [];
    let obj = {};
    let models = [];
    let brands = [];
    const responses = [];
    const returnedObjOfObservables = [];
    this.queryParamsService.reset();
    return forkJoin([
      this.carService.getBrands<ResponseModel<Brand>>(),
      this.carService.getCountries<Country>(),
      this.carService.getColors<ResponseModel<Color>>(),
      this.carService.getCarSeries<ResponseModel<{ id: number, name: string, image: string }>>(),
      this.carService.getCars<ResponseModel<Car>>(getQueryParamsStr(route.queryParams))
    ]).pipe(
      switchMap((results) => {
        if (results[0] && results[1] && results[2] && results[3]) {
          this.carLocalItems.brand = results[0].body.results;
          if (route.queryParams.hasOwnProperty('brand') && route.queryParams['brand']) {
            brands = (route.queryParams['brand'] as string).split(',');
            brands.forEach(brandId => {
              this.queryParamsService.brands.add(+brandId);
              const findedBrand = results[0].body.results.find(brand => +brand.id === +brandId);
              if (findedBrand) {
                requests.push(this.carService.getModel<ResponseModel<Model>>(brandId));
                FiltersConfig.filters.splice(1, 0,
                  {
                    property: findedBrand.name,
                    isCollapsed: false,
                    multiple: true,
                    type: 'select',
                    label: findedBrand.name,
                    shadowProperty: 'model'
                  });
                responses.push({highProp: 'model', property: findedBrand.name});
              }
            });
          }
          if (route.queryParams.hasOwnProperty('model') && route.queryParams['model']) {
            models = (route.queryParams['model'] as string).split(',');
            models.forEach((modelId => requests.push(this.carService.getGenerations<ResponseModel<CarGeneration>>(modelId))));
            responses.push({highProp: 'generation'});
          }
        }
        obj = {
          brand: of(results[0]),
          country: of(results[1]),
          color: of(results[2]),
          car_type: of(results[3]),
          results: of(results[4])
        };
        if (requests.length > 0) {
          return forkJoin(requests);
        } else {
          return of(null);
        }
      })).pipe(map(value => {
      if (value) {
        for (let i = 0; i < brands.length; i++) {
          returnedObjOfObservables[responses[i].property] = of(value[i]);

          // add selected models to service
          if (route.queryParams.hasOwnProperty('model') && route.queryParams.model) {
            const modelIDs = new Set(((route.queryParams.model as string) || '').split(','));
            value[i].body.results.forEach(model => {
              if (modelIDs.has(`${model.id}`)) {
                this.queryParamsService.models.set(+model.id, model);
              }
            });
          }
        }
        let generationBrandName;
        for (let i = brands.length; i < models.length + brands.length; i++) {
          // for (let j = 0; j < modelsCount; j++) {

          // some models don't have generation list
          if (value[i] && value[i].body && value[i].body.results && value[i].body.results.length > 0) {
            generationBrandName = value[i].body.results[0]['model']['brand'].name;
            const index = FiltersConfig.filters.findIndex(item => item.property === generationBrandName);
            if (index !== -1) {

              // add selected generations to service
              const genIDs = new Set(((route.queryParams.generation as string) || '').split(','));
              value[i].body.results.forEach(gen => {
                if (
                  genIDs.has(`${gen.id}`) &&
                  gen && gen.model && gen.model.brand && // in case of empty generation
                  this.queryParamsService.brands.has(gen.model.brand.id) &&
                  this.queryParamsService.models.has(gen.model.id)
                ) {
                  this.queryParamsService.generation.set(gen.id, gen);
                }
              });

              const modelName = value[i].body.results[0]['model'].name;
              FiltersConfig.filters.splice(index + 1, 0,
                {
                  property: modelName,
                  isCollapsed: false,
                  multiple: true, type: 'select',
                  label: modelName,
                  shadowProperty: 'generation'
                });
              returnedObjOfObservables[modelName] = of(value[i]);
            }
          }
          // }
        }
        return {
          ...obj,
          ...returnedObjOfObservables
        };
      } else {
        return obj;
      }
    }));
  }

  private errorHandler(error) {
    if (error.status === 404) {
      this.router.navigate(['/filter']);
      return EMPTY;
    } else if (error.status === 401) {
      this.authService.logOut();
      return EMPTY;
    }
  }
}
