var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseHttpService } from './base-http-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from './authorization.service';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./authorization.service";
var CarService = /** @class */ (function (_super) {
    __extends(CarService, _super);
    function CarService(httpClient, authService) {
        var _this = _super.call(this, httpClient) || this;
        _this.httpClient = httpClient;
        _this.authService = authService;
        _this.CAR = '/car/';
        _this.CAR_SERIES = '/car-series/';
        _this.BRAND = '/brand/';
        _this.MODEL = '/model/';
        _this.COLOR_URL = '/color/';
        _this.GENERAATION = '/car-generation/';
        _this.COUNTRIES = '/country/';
        _this.REGIONS = '/{country_id}/regions/';
        _this.CONTACT_US = '/core/contact-us/';
        _this.FAVOURITES_ADD = '/favorites/add_favorite/';
        _this.FAVOURITES = '/favorites/';
        return _this;
    }
    CarService.prototype.getCars = function (query) {
        return this.get(this.CAR + "?" + query);
    };
    CarService.prototype.getCar = function (id) {
        if (this.authService.isLoggedIn()) {
            var headers_object = new HttpHeaders();
            headers_object = headers_object.append('Authorization', 'Token ' + this.authService.getToken());
            return this.get("" + this.CAR + id + "/", { observe: 'response', headers: headers_object });
        }
        else {
            return this.get("" + this.CAR + id + "/");
        }
    };
    CarService.prototype.getCarSeries = function () {
        return this.get(this.CAR_SERIES + "?limit=-1")
            .pipe(map(function (response) {
            response.body.results = response.body.results.sort(function (a, b) { return a.name.localeCompare(b.name); });
            return response;
        }));
    };
    CarService.prototype.getBrands = function () {
        return this.get(this.BRAND + "?limit=-1")
            .pipe(map(function (response) {
            response.body.results = response.body.results.sort(function (a, b) { return a.name.localeCompare(b.name); });
            return response;
        }));
    };
    CarService.prototype.getColors = function () {
        return this.get(this.COLOR_URL + "?limit=-1")
            .pipe(map(function (response) {
            response.body.results = response.body.results.sort(function (a, b) { return a.name.localeCompare(b.name); });
            return response;
        }));
    };
    CarService.prototype.getBrand = function (id) {
        return this.get("" + this.BRAND + id + "/");
    };
    CarService.prototype.getModels = function () {
        return this.get("" + this.MODEL);
    };
    CarService.prototype.getModel = function (id) {
        return this.get(this.MODEL + "?brand=" + id + "&limit=-1")
            .pipe(map(function (response) {
            response.body.results = response.body.results.sort(function (a, b) {
                if (Number(a.name) && Number(b.name)) {
                    return a.name - b.name;
                }
                return a.name.localeCompare(b.name);
            });
            return response;
        }));
    };
    CarService.prototype.getGenerations = function (id) {
        return this.get(this.GENERAATION + "?model=" + id + "&limit=-1");
    };
    CarService.prototype.getCountries = function () {
        return this.get(this.COUNTRIES + "?limit=-1")
            .pipe(map(function (response) {
            response.body.results = response.body.results.sort(function (a, b) { return a.name.localeCompare(b.name); });
            return response;
        }));
    };
    CarService.prototype.getRegions = function (id) {
        return this.get(this.REGIONS.replace('{country_id}', id) + "?limit=-1")
            .pipe(map(function (response) {
            response.body.results = response.body.results.sort(function (a, b) { return a.name.localeCompare(b.name); });
            return response;
        }));
    };
    CarService.prototype.contactUs = function (body) {
        return this.post(this.CONTACT_US, body);
    };
    CarService.prototype.addToFavourites = function (body) {
        var headers_object = new HttpHeaders();
        headers_object = headers_object.append('Authorization', 'Token ' + this.authService.getToken());
        return this.post(this.FAVOURITES_ADD, body, { observe: 'response', headers: headers_object });
    };
    CarService.prototype.removeFromFavourites = function (id) {
        var headers_object = new HttpHeaders();
        headers_object = headers_object.append('Authorization', 'Token ' + this.authService.getToken());
        return this.delete("" + this.FAVOURITES + id, { headers: headers_object });
    };
    CarService.ngInjectableDef = i0.defineInjectable({ factory: function CarService_Factory() { return new CarService(i0.inject(i1.HttpClient), i0.inject(i2.AuthorizationService)); }, token: CarService, providedIn: "root" });
    return CarService;
}(BaseHttpService));
export { CarService };
