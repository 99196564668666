<section class="b-pageHeader">
  <div class="container">
    <h1>{{ data?.h1 | translate }}</h1>
    <!--<div class="b-pageHeader__search" *ngIf="data.h3">
      <h3>{{ data.h3 }}</h3>
    </div>-->
  </div>
</section><!--b-pageHeader-->

<div class="b-breadCumbs s-shadow">
  <div class="container">
    <a routerLink="/" class="b-breadCumbs__page">{{'HEADER.HOME' | translate}}</a>
    <span class="fa fa-angle-right"></span>

    <ng-container *ngIf="data && data.multiple">
      <ng-container *ngFor="let item of data.multiple; let $last = last">
        <ng-container *ngIf="!$last">
          <a
            [routerLink]="item.routerLink" [queryParams]="item.queryParams"
            routerLinkActive="false"
            class="b-breadCumbs__page">{{item.h1 | translate}}</a>

          <span class="fa fa-angle-right"></span>
        </ng-container>

        <a *ngIf="$last"
           [routerLink]="item.routerLink" [queryParams]="item.queryParams"
           routerLinkActive="true"
           class="b-breadCumbs__page m-active">{{item.h1 | translate}}</a>
      </ng-container>
    </ng-container>

    <a *ngIf="data && !data.multiple" [routerLink]="data?.routerLink" routerLinkActive="true"
       class="b-breadCumbs__page m-active">{{ data?.h1 | translate}}</a>
  </div>
</div><!--b-breadCumbs-->
