import {NgModule} from '@angular/core';
import {ErrorMessageClassDirective} from './error-message-class.directive';
import {DigitOnlyDirective} from './numeric.directive';
import { LetterOnlyDirective } from './letter-only.directive';

@NgModule({
  declarations: [
    ErrorMessageClassDirective,
    DigitOnlyDirective,
    LetterOnlyDirective
  ],
  exports: [
    ErrorMessageClassDirective,
    DigitOnlyDirective,
    LetterOnlyDirective
  ]
})
export class DirectiveModule {
}
