/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-bar";
import * as i3 from "@angular/platform-browser/animations";
import * as i4 from "@angular/common";
import * as i5 from "./page-preloader.component";
import * as i6 from "@angular/router";
import * as i7 from "../../services/router-loader.service";
var styles_PagePreloaderComponent = [];
var RenderType_PagePreloaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PagePreloaderComponent, data: {} });
export { RenderType_PagePreloaderComponent as RenderType_PagePreloaderComponent };
function View_PagePreloaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"], ["style", "z-index: 1002; position: fixed; top: 0; left: 0; right: 0; height: 3px;"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i1.View_MatProgressBar_0, i1.RenderType_MatProgressBar)), i0.ɵdid(1, 49152, null, 0, i2.MatProgressBar, [i0.ElementRef, [2, i3.ANIMATION_MODULE_TYPE], [2, i2.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "warn"; var currVal_4 = "indeterminate"; _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).value; var currVal_1 = i0.ɵnov(_v, 1).mode; var currVal_2 = (i0.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_PagePreloaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagePreloaderComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading || _co.loadingEvent); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PagePreloaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-preloader", [], null, null, null, View_PagePreloaderComponent_0, RenderType_PagePreloaderComponent)), i0.ɵdid(1, 49152, null, 0, i5.PagePreloaderComponent, [i6.Router, i7.RouterLoaderService], null, null)], null, null); }
var PagePreloaderComponentNgFactory = i0.ɵccf("app-page-preloader, [app-page-preloader]", i5.PagePreloaderComponent, View_PagePreloaderComponent_Host_0, {}, {}, []);
export { PagePreloaderComponentNgFactory as PagePreloaderComponentNgFactory };
