<!--TODO in case of table view change classes to  -> class="m-listTableTwo" data-scrolling-animations="true" data-equal-height=".b-items__cell"-->
<div class="m-listingsTwo">
  <section class="b-pageHeader">
    <div class="container">
      <h1>{{"FILTER.AUTH_LISTINGS" | translate}}</h1>
    </div>
  </section><!--b-pageHeader-->

  <div class="b-breadCumbs s-shadow">
    <div class="container">
      <a routerLink="/" class="b-breadCumbs__page">{{'HEADER.HOME' | translate}}</a>
      <span class="fa fa-angle-right"></span>
      <a class="b-breadCumbs__page m-active">{{"SEARCH.RESULTS" | translate}}</a>
    </div>
  </div><!--b-breadCumbs-->

  <div class="b-infoBar" app-infobar [count]="count" (listingAndSortingParamsChange)="listingAndSortingParamsChange($event)"></div><!--b-infoBar-->

  <div class="b-items">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-4 col-xs-12 filter">
          <i class="fa fa-filter filter-icon" (click)="toggleFilter()"></i>
          <aside class="b-items__aside filter-collapse" id="aside">
            <h2 class="s-title">{{"FILTER.REFINE" | translate}}</h2>
            <a class="block text-center pointer mb-2" (click)="resetFilter()" style="margin-left: 7px">{{"FILTER.RESET" | translate}} <i class="fa fa-remove ml-1"></i></a>
            <div class="b-items__aside-main">
              <form [formGroup]="form">
                <!--<ng-container *ngFor="let filter o filters">
                </ng-container>-->

                <div class="b-items__aside-main-body" data-simplebar data-simplebar-auto-hide="false">
                  <ng-container *ngFor="let filter of filters; index as i">
                    <div *ngIf="filter.type === 'select' && !filter.hasOwnProperty('multiple')" class="b-items__aside-main-body-item m-md">
                      <!--SELECT COMPONENT-->
                      <div class="select-box h-sm">
                        <ng-select
                                [items]="carLocalItems[filter.property]"
                                [placeholder]="filter.label | translate"
                                [formControlName]="filter.property"
                                [searchable]="false"
                                [clearable]="true"
                                bindLabel="name"
                                bindValue="id"
                                (change)="filterSingle(i, filter.property)"
                        ></ng-select>
                      </div>
                      <!--SELECT COMPONENT-->
                    </div>
                    <div *ngIf="filter.type === 'input'" class="b-items__aside-main-body-item">
                      <label class="label-name">{{filter.label | translate}}</label>
                      <div class="d-flex mb-4">

                        <ng-container *ngIf="filter.isDecimal else nonDecimal">
                          <div class="pr-1 half-width">
                            <div class="text-field">
                              <input (change)="filterSingle(i, filter.fields[0].property, false, filter.fields[0].defaultValue)" [formControlName]="filter.fields[0].property" type="text" [placeholder]="'CAR.FROM' | translate">
                            </div>
                          </div>
                          <div class="pl-1 half-width">
                            <div class="text-field">
                              <input (change)="filterSingle(i, filter.fields[1].property, false, filter.fields[1].defaultValue)" [formControlName]="filter.fields[1].property" type="text" [placeholder]="'CAR.TO' | translate">
                            </div>
                          </div>
                        </ng-container>

                        <ng-template #nonDecimal>
                          <div class="pr-1 half-width">
                            <div class="text-field">
                              <input appDigitOnly [disableZerosAtStart]="true" (change)="filterSingle(i, filter.fields[0].property, false, filter.fields[0].defaultValue)" [formControlName]="filter.fields[0].property" type="text" [placeholder]="'CAR.FROM' | translate">
                            </div>
                          </div>
                          <div class="pl-1 half-width">
                            <div class="text-field">
                              <input appDigitOnly [disableZerosAtStart]="true" (change)="filterSingle(i, filter.fields[1].property, false, filter.fields[1].defaultValue)" [formControlName]="filter.fields[1].property" type="text" [placeholder]="'CAR.TO' | translate">
                            </div>
                          </div>
                        </ng-template>

                      </div>
                    </div>
                    <div *ngIf="filter.type === 'select' && filter.hasOwnProperty('multiple') && getFormArrayLength(filter.property) !== 0" class="b-items__aside-main-body-item select-options mt-2">
                      <div class="multiple-items">
                        <div class="d-flex align-center justify-between" (click)="filter.isCollapsed = !filter.isCollapsed;">
                          <p class="item-name">{{filter.label | translate}}</p>
                          <span class="fa arrow-icon" [ngClass]="{'fa-caret-up ': !filter.isCollapsed, 'fa-caret-down': filter.isCollapsed}"></span>
                        </div>
                        <ul class="items-list" data-simplebar data-simplebar-auto-hide="false" [@multipleCollapse]="!filter.isCollapsed">
                          <li *ngFor="let item of getFormArray(filter.property); index as i">
                            <div class="b-submit__main-element">
                              <input (change)="filterMultipleData(i, filter.property, filter.shadowProperty)" [formControl]="item" type="checkbox" name="{{filter.property}}{{i}}" id="{{filter.property}}{{i}}"/>
                              <label for="{{filter.property}}{{i}}" class="b-items__cars-one-img-check"><span class="fa fa-check"></span></label>
                              <label class="s-submitCheck" for="{{filter.property}}{{i}}">{{carLocalItems[filter.property][i].name | translate}}</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngIf="filter.type === 'checkbox' && !filter.hasOwnProperty('single')" class="b-items__aside-main-body-item">
                      <div class="multiple-items">
                        <div class="d-flex align-center justify-between" (click)="filter.isCollapsed = !filter.isCollapsed;">
                          <p class="item-name">{{"FILTER.EXTRA" | translate}}</p>
                          <span class="fa arrow-icon" [ngClass]="{'fa-caret-up ': !filter.isCollapsed, 'fa-caret-down': filter.isCollapsed}"></span>
                        </div>
                        <ul class="items-list" data-simplebar data-simplebar-auto-hide="false" [@multipleCollapse]="!filter.isCollapsed">
                          <li *ngFor="let field of filter.fields; index as i">
                            <div class="b-submit__main-element">
                              <input (change)="filterSingle(i, field.property, true)" [formControlName]="field.property" type="checkbox" name="{{field.property}}{{i}}" id='{{field.property}}{{i}}'/>
                              <label for="{{field.property}}{{i}}" class="b-items__cars-one-img-check"><span class="fa fa-check"></span></label>
                              <label class="s-submitCheck" for="{{field.property}}{{i}}">{{field.label | translate}}</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div *ngIf="filter.type === 'checkbox' && filter.hasOwnProperty('single')" class="b-items__aside-main-body-item">
                      <div class="b-submit__main-element s-submit">
                        <input (change)="filterSingle(i, filter.property, true)" [formControlName]="filter.property" type="checkbox" name="{{filter.property}}a{{i}}" id="{{filter.property}}a{{i}}"/>
                        <label class="s-submitCheckLabel" for="{{filter.property}}a{{i}}"><span class="fa fa-check"></span></label>
                        <label class="s-submitCheck" for="{{filter.property}}a{{i}}">{{filter.label | translate}}</label>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </form>
            </div>
          </aside>
        </div>

        <div class="col-lg-9 col-sm-8 col-xs-12" app-filtered-items [cars]="results" [listed]="infoBarComponent.listed">

        </div>
      </div>
    </div>
  </div><!--b-items-->

</div>



















