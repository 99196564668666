import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

declare const ST_Date;

@Injectable({
  providedIn: 'root'
})
export class CarLocalItemsService {
  public brand: Array<any> = [];
  public model: Array<any> = [];
  public generation: Array<any> = [];
  public region: Array<any> = [];
  public car_type: Array<any> = [];
  public fuel_type: Array<any>;
  public drive_wheel_type: Array<any>;
  public transmission: Array<any>;
  public color: Array<any> = [];
  public year: Array<any> = [];
  public drive_type: Array<any>;
  public owners_count: Array<any>;
  public currency: Array<any>;
  public interior_type: Array<any>;
  public interior_flavor: Array<any>;
  public seat_count: Array<any>;
  public climate_control: Array<any>;
  public window_regulator: Array<any>;
  public driver_seat_regulator: Array<any>;
  public passenger_seat_regulator: Array<any>;
  public airbag: Array<any>;
  public cubic_capacity: Array<any> = [];
  public power: Array<any> = [];
  public is_crashed: Array<any>;
  public custom_clearance: Array<any>;
  public guarantee: Array<any>;
  public exchange: Array<any>;
  public helm_regulator: Array<any>;
  public country: Array<any> = [];
  public usage: Array<any>;
  public posted_time: Array<any>;
  public purchased_year: Array<any> = [];
  $translated: Subject<boolean>;

  constructor(private translate: TranslateService) {
    this.$translated = new Subject();
    for (let i = 3; i <= 100; i += 1) {
      this.cubic_capacity.push({
        name: i / 10,
        id: i / 10
      });
    }

    for (let i = 0; i < 1500; i += 10) {
      this.power.push({
        name: i,
        id: i
      });
    }
    const pastDate = new Date(0).getFullYear();
    const newDate = ST_Date.getFullYear();

    /*     for (let i = newDate; i >= pastDate; i--) {
          this.purchased_year.push({
            name: i,
            id: i
          });
        } */

    translate.get('CAR').subscribe(language => {
      this.interior_type = [
        { name: language.INTERIOR_TYPE.TEXTILE, id: 'textile' },
        { name: language.INTERIOR_TYPE.VELOURS, id: 'velours' },
        { name: language.INTERIOR_TYPE.LEATHER, id: 'leather' },
        { name: language.INTERIOR_TYPE.COMBINED, id: 'combined' }
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.interior_flavor = [
        { name: language.INTERIOR_FLAVOR.WHITE, id: 'white' },
        { name: language.INTERIOR_FLAVOR.BLACK, id: 'black' },
        { name: language.INTERIOR_FLAVOR.GREY, id: 'grey' },
        { name: language.INTERIOR_FLAVOR.BEIGE, id: 'beige' },
        { name: language.INTERIOR_FLAVOR.RED, id: 'red' },
        { name: language.INTERIOR_FLAVOR.BROWN, id: 'brown' }
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.seat_count = [
        { name: language.SEATS_COUNT.TWO, id: 'two' },
        { name: language.SEATS_COUNT.FOUR_FIVE, id: 'four_five' },
        { name: language.SEATS_COUNT.SIX_EIGHT, id: 'six_eight' },
        { name: language.SEATS_COUNT.NINE_PLUS, id: 'nine_plus' }
      ];

      this.climate_control = [
        { name: language.CLIMAT_CONTROL.CONDITIONER, id: 'conditioner' },
        { name: language.CLIMAT_CONTROL.ONE_ZONE, id: 'one_zone' },
        { name: language.CLIMAT_CONTROL.TWO_ZONE, id: 'two_zone' },
        { name: language.CLIMAT_CONTROL.THREE_ZONE, id: 'three_zone' }
      ];

      this.window_regulator = [
        { name: language.WINDOW_REGULATOR.FRONT, id: 'front' },
        { name: language.WINDOW_REGULATOR.ALL, id: 'all' }
      ];

      this.driver_seat_regulator = [
        { name: language.SEAT_REGULATOR.BY_HEIGHT, id: 'by_height' },
        { name: language.SEAT_REGULATOR.ELECTRO_REGULATOR, id: 'electro_regulator' },
        { name: language.SEAT_REGULATOR.WITH_MEMORY, id: 'with_memory' },
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.helm_regulator = [
        { name: language.HELM_REGULATOR.IN_ONE_PLANE, id: 'in_one_plane' },
        { name: language.HELM_REGULATOR.IN_TWO_PLANE, id: 'in_two_plane' },
        { name: language.HELM_REGULATOR.ELECTRO_REGULATOR, id: 'electro_regulator' },
      ];

      this.airbag = [
        { name: language.AIRBAG.DRIVER_SIDE, id: 'driver_side' },
        { name: language.AIRBAG.DRIVER_AND_PASSENGER, id: 'driver_and_passenger' },
        // {name: language.AIRBAG.FRONT_AND_LATERIAL, id: 'front_and_laterial'},
      ];

      this.drive_type = [
        { name: language.DRIVE_TYPE.LEFT_HAND, id: 'left_hand' },
        { name: language.DRIVE_TYPE.RIGHT_HAND, id: 'right_hand' }
      ];

      this.drive_wheel_type = [
        { name: language.DRIVE_WHEEL_TYPE.FRONT_WHEEL_DRIVE, id: 'front_wheel_drive' },
        { name: language.DRIVE_WHEEL_TYPE.REAR_DRIVE, id: 'rear_drive' },
        { name: language.DRIVE_WHEEL_TYPE.FOUR_WHEEL_DRIVE, id: 'four_wheel_drive' }
      ];

      this.fuel_type = [
        { name: language.FUEL_TYPE.PETROL, id: 'petrol' },
        { name: language.FUEL_TYPE.DIESEL, id: 'diesel' },
        { name: language.FUEL_TYPE.HYBRID, id: 'hybrid' },
        { name: language.FUEL_TYPE.PETROL_GAS, id: 'petrol_gas' },
        { name: language.FUEL_TYPE.ELECTRIC, id: 'electric' }
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.owners_count = [
        { name: '1', id: 'one' },
        { name: '2', id: 'two' },
        { name: '3+', id: 'three_plus' }
      ];

      this.usage = [
        { name: language.USAGE.UNDER_ONE_YEAR, id: 'under_one_year' },
        { name: language.USAGE.ONE_YEAR, id: 'one_year' },
        { name: language.USAGE.TWO_YEARS, id: 'two_years' },
        { name: language.USAGE.FROM_THREE_TO_FIVE, id: 'from_three_to_five' },
        { name: language.USAGE.FROM_SIX_TO_TEN, id: 'from_six_to_ten' },
        { name: language.USAGE.TEN_AND_MORE, id: 'ten_and_more' },
        { name: language.USAGE.NEW, id: 'is_new' }
      ];

      this.currency = [
        { name: language.CURRENCY.USD, id: 'usd' },
        { name: language.CURRENCY.EUR, id: 'eur' },
        { name: language.CURRENCY.RUB, id: 'rub' },
        { name: language.CURRENCY.AMD, id: 'amd' }
      ];

      this.transmission = [
        { name: language.TRANSMISSION.AUTOMATIC, id: 'automatic' },
        { name: language.TRANSMISSION.ROBOT, id: 'robot' },
        { name: language.TRANSMISSION.VARIATOR, id: 'variator' },
        { name: language.TRANSMISSION.MANUAL, id: 'manual' }
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.country = [
        { name: language.COUNTRY.ARMENIA, id: 'armenia' },
        { name: language.COUNTRY.USA, id: 'usa' },
        { name: language.COUNTRY.JAPAN, id: 'japan' },
        { name: language.COUNTRY.RUSSIA, id: 'russia' },
        { name: language.COUNTRY.GEORGIA, id: 'georgia' }
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.passenger_seat_regulator = [
        { name: language.SEAT_REGULATOR.BY_HEIGHT, id: 'by_height' },
        { name: language.SEAT_REGULATOR.ELECTRO_REGULATOR, id: 'electro_regulator' },
        { name: language.SEAT_REGULATOR.WITH_MEMORY, id: 'with_memory' },
      ].sort((a, b) => a.name.localeCompare(b.name));

      this.posted_time = [
        { name: language.POSTED_TIME.TODAY, id: 'today' },
        { name: language.POSTED_TIME.TWO_DAYS, id: 'two_days' },
        { name: language.POSTED_TIME.THREE_DAYS, id: 'three_days' },
        { name: language.POSTED_TIME.ONE_WEEK, id: 'one_week' },
        { name: language.POSTED_TIME.TWO_WEEK, id: 'two_weeks' },
        { name: language.POSTED_TIME.MONTH, id: 'month' }
      ];

      this.is_crashed = [
        { name: language.CRASHED.NO, id: false },
        { name: language.CRASHED.YES, id: true }
      ];
      this.custom_clearance = [
        { name: language.CUSTOM_CLEARANCE.YES, id: true },
        { name: language.CUSTOM_CLEARANCE.NO, id: false }
      ];
      this.guarantee = [
        { name: language.GUARANTEE.YES, id: true },
        { name: language.GUARANTEE.NO, id: false }
      ];
      this.exchange = [
        { name: language.EXCHANGE.NO, id: false },
        { name: language.EXCHANGE.YES, id: true }
      ];
      this.$translated.next(true);
    });
  }
}
