import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

enum languages {
	'arm' = 'am',
	'rus' = 'ru',
	'eng' = 'en',
	'am' = 'arm',
	'ru' = 'rus',
	'en' = 'eng',
}

@Injectable({ providedIn: 'root' })
export class AppLanguageService {

	public static readonly langList = ['arm', 'eng', 'rus'];
	public static API_LANGUAGE: string = "am";

	constructor(
		public readonly translator: TranslateService,
		private readonly router: Router
	) {
		this.translator.setDefaultLang('arm');
		this.detectChanges();
		const lang = this.getLocalLanguage();
		this.defineLanguage(lang);
	}

	public static get CURRENT_LANG() {
		return languages[AppLanguageService.API_LANGUAGE];
	}

	public static get TRANSFORMED_LANGUAGES() {
		return AppLanguageService.langList.map(item => languages[item])
	}

	private detectChanges() {
		const lang = window.location.pathname.slice(1);
		if(languages[lang]) {
			this.setLocalLanguage(languages[lang]);
		}
	}

	public defineLanguage(lang: string): void {
		if (!lang) return;
		
		const isExist = AppLanguageService.langList.find(elem => elem === lang);
		if (!isExist) return;

		this.translator.use(lang);
		this.setLocalLanguage(lang);
	}

	private getLocalLanguage(): string {
		const lang = localStorage.getItem('language');
		if (!lang) return 'arm';
		return lang;
	}

	private setLocalLanguage(lang: string): void {
		localStorage.setItem('language', lang);
		AppLanguageService.API_LANGUAGE = languages[lang];
	}

}
