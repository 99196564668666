import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AuthorizationService} from '../../core/services/authorization.service';
import {ProfileService} from '../profile/services/profile.service';
import {CarService} from '../../core/services/car.service';
import {Car} from '../../shared/models/car.model';
import {urlFragment} from '../../shared/utils/utils';

@Injectable()
export class ResolveDetailService implements Resolve<any> {

  constructor(private carService: CarService,
              private router: Router,
              private profileService: ProfileService,
              private authService: AuthorizationService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    return this.carService.getCar<Car>(route.params.id).pipe(
      map(results => {
        if (results) {
          if (route.params.car !== urlFragment(results.body)) {
            this.router.navigateByUrl(state.url.replace(route.params.car, urlFragment(results.body)));
            return;
          }
          return of(results);
        } else {
          return EMPTY;
        }
			}), 
			catchError((err) => this.errorHandler(err))
    );
  }

  private errorHandler(error) {
    if (error.status === 404 || error.status === 500) {
      this.router.navigate(['/']);
      return EMPTY;
    } else if (error.status === 401) {
      this.authService.logOut();
      return EMPTY;
    }
  }
}
