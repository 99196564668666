import {Component} from '@angular/core';
import {AuthorizationService} from './core/services/authorization.service';
import {Router} from '@angular/router';
import {fadeAnimation} from './shared/animations/animations';
import {AppLanguageService} from './core/services/app-language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeAnimation]
})
export class AppComponent {

  constructor(private authService: AuthorizationService, private appLanguageService: AppLanguageService, public router: Router) {

    // @ts-ignore
    Array.prototype.diff = function (a) {
      return this.filter(function (i) {
        return a.indexOf(i) === -1;
      });
    };
  }
}
