import {Injectable} from '@angular/core';
import {Meta} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private readonly meta: Meta,
  ) {
  }


  public updateTags(config): void {
    config = {
      title: 'Gear Car',
      description: 'Description',
      image: '/assets/images/logo/logo.png',
      ...config,
    };

    this.meta.updateTag({name: 'twitter:card', content: 'summary'});
    this.meta.updateTag({name: 'twitter:site', content: '@twitterusername'}); // TODO: Update this for production
    this.meta.updateTag({name: 'twitter:title', content: config.title});
    this.meta.updateTag({name: 'twitter:description', content: config.description});
    this.meta.updateTag({name: 'twitter:image', content: config.image});

    this.meta.updateTag({property: 'og:type', content: 'acticle'});
    this.meta.updateTag({property: 'og:site_name', content: 'Gear Car'});
    this.meta.updateTag({property: 'og:title', content: config.title});
    this.meta.updateTag({property: 'og:description', content: config.description});
    this.meta.updateTag({property: 'og:image', content: config.image});
    this.meta.updateTag({property: 'og:url', content: `${environment.appUrl}detail/${config.details}`});
  }
}
