import { AfterViewInit, ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var SelectComponent = /** @class */ (function () {
    function SelectComponent() {
        this.items = [];
        this.virtualScroll = true;
        this.notFoundText = 'SHARED.NOT_FOUND_MSG';
        this.typeToSearchText = 'SHARED.TAP_TO_SEARCH';
        this.loadingText = 'SHARED.LOADING_TEXT';
        this.enableScroll = true;
        this.searchable = false;
        this.clearable = true;
        this.bindValue = 'id';
        this.bindLabel = 'name';
        this.propagateChange = function (_) {
        };
        this.onOpenHandler = function () {
            // if (this.enableScroll) {
            // setTimeout(() => {
            // const element = <HTMLElement>document.querySelector('.ng-dropdown-panel-items');
            // this.simplebar = new SimpleBar(element);
            // this.ps = new PerfectScrollbar(element, {
            //   // suppressScrollY: false,
            //   wheelPropagation: false,
            //   wheelSpeed: 1.4,
            //   minScrollbarLength: 30,
            //   scrollYMarginOffset: 5
            //   // suppressScrollX: true
            // });
            // });
            // }
        };
        this.onCloseHandler = function () {
            // if (this.enableScroll) {
            // this.ps.destroy();
            // }
        };
    }
    Object.defineProperty(SelectComponent.prototype, "searchInput", {
        set: function (elRef) {
            this.searchElementRef = elRef;
            if (elRef) {
                elRef.nativeElement.focus();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SelectComponent.prototype, "selectValue", {
        get: function () {
            return this._selectValue;
        },
        set: function (val) {
            this._selectValue = val;
            this.propagateChange(this._selectValue);
        },
        enumerable: true,
        configurable: true
    });
    SelectComponent.prototype.ngAfterViewInit = function () {
        if (this.onCloseEvent !== undefined) {
            this.onCloseHandler = this.onCloseEvent;
        }
        if (this.onChangeEvent !== undefined) {
            this.onChangeHandler = this.onChangeEvent;
        }
        // if (this.searchInput) {
        //   this.searchInput.focus();
        //   console.log(1);
        // }
    };
    SelectComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this.selectValue = value;
        }
    };
    SelectComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    SelectComponent.prototype.registerOnTouched = function () {
    };
    SelectComponent.prototype.onChangeHandler = function () {
    };
    SelectComponent.prototype.onClear = function () {
        if (this.searchElementRef && this.searchElementRef.nativeElement) {
            this.searchElementRef.nativeElement.value = '';
            this.searchElementRef.nativeElement.focus();
        }
    };
    SelectComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    return SelectComponent;
}());
export { SelectComponent };
