import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EmailPopupComponent } from './email-popup/email-popup.component';
import { ActivatedRoute } from '@angular/router';
import { CarLocalItemsService } from '../../core/services/car-local-items.service';
import { descriptionInfo, extraFeaturesCategories, galleryOptions } from './detail.config';
import { AuthorizationService } from '../../core/services/authorization.service';
import { CarService } from '../../core/services/car.service';
import { MyToastrService } from '../../shared/toastr/my-toastr.service';
import { SeoService } from '../../core/services/seo.service';
import { urlFragment } from '../../shared/utils/utils';
import { environment } from '../../../environments/environment';
var DetailViewComponent = /** @class */ (function () {
    function DetailViewComponent(route, carLocalItemsService, activatedRoute, authService, toastr, carService, dialog, seoService) {
        this.route = route;
        this.carLocalItemsService = carLocalItemsService;
        this.activatedRoute = activatedRoute;
        this.authService = authService;
        this.toastr = toastr;
        this.carService = carService;
        this.dialog = dialog;
        this.seoService = seoService;
        this.className = '';
        this.showPageHeader = false;
        this.galleryImages = [];
        this.pricesInAntherCurrency = [];
        this.extraFeaturesCategories = extraFeaturesCategories;
        this.descriptionInfoConfig = descriptionInfo;
        this.descriptionInfo = [];
        this.isFavouriteVisible = false;
        this.isFavourite = false;
        this.isFavouritePending = false;
        this.extraFeatures = [];
        this.showFacebookBtn = false;
        // route.url.subscribe(() => {
        //   this.data = route.snapshot.data as IRouterDataModel;
        // });
    }
    DetailViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.className = this.route.snapshot.data.className;
        this.subscribeToRouterData();
        this.carLocalItemsService.$translated.subscribe(function (tr) {
            if (tr) {
                _this.subscribeToRouterData();
            }
        });
        this.galleryOptions = galleryOptions;
        if (!environment.live) {
            this.showFacebookBtn = true;
        }
    };
    DetailViewComponent.prototype.ngAfterViewInit = function () {
    };
    DetailViewComponent.prototype.ngOnDestroy = function () {
        if (this.$addToFavouritesSubscription) {
            this.$addToFavouritesSubscription.unsubscribe();
        }
        if (this.$deleteFromFavouritesSubscription) {
            this.$deleteFromFavouritesSubscription.unsubscribe();
        }
        if (this.$routeSubscription) {
            this.$routeSubscription.unsubscribe();
        }
        this.destroyMetaTags();
    };
    DetailViewComponent.prototype.openEmailPopup = function () {
        this.dialog.open(EmailPopupComponent);
    };
    DetailViewComponent.prototype.subscribeToRouterData = function () {
        var _this = this;
        this.$routeSubscription = this.activatedRoute.data.subscribe(function (data) {
            _this.showPageHeader = data.showPageHeader;
            if (data.car) {
                data.car.subscribe(function (res) {
                    _this.car = res.body;
                    _this.isFavourite = _this.authService.isLoggedIn() ? _this.car.favorite.favorite : false;
                    _this.isFavouriteVisible = _this.isFavVisible();
                    _this.galleryImages = [];
                    res.body.images.forEach(function (image) {
                        _this.galleryImages.push({
                            small: image.thumbnail,
                            medium: image.image,
                            big: image.image
                        });
                    });
                    _this.initPriceByCurrency(_this.car.price[0]);
                    _this.initDescriptionInfo();
                    _this.initExtraFeatures(_this.car.information);
                    _this.addMetaTags();
                }).unsubscribe();
            }
        });
    };
    DetailViewComponent.prototype.addMetaTags = function () {
        var title = this.car.car_model.brand.name + ' ' + this.car.car_model.name +
            (this.car.generation && this.car.generation.name ? this.car.generation.name + ', ' : '') + this.car.production_date;
        var details = this.car.id + "/" + urlFragment(this.car);
        this.seoService.updateTags({
            title: title,
            description: this.car.advert.additional_information ? this.car.advert.additional_information : title,
            details: details,
            image: this.galleryImages.length > 0 ? this.galleryImages[0].big.toString() : "/assets/images/logo/logo.png",
            'og:image:width': '600',
            'og:image:height': '375',
        });
    };
    DetailViewComponent.prototype.initPriceByCurrency = function (price) {
        var _this = this;
        this.pricesInAntherCurrency = [];
        var keys = Object.keys(price);
        this.carLocalItemsService.currency.forEach(function (currency) {
            var curr = keys.find(function (prKey) { return prKey.includes(currency.id); });
            if (currency.id !== _this.car.currency) {
                _this.pricesInAntherCurrency.push({
                    currency: currency.name,
                    price: price[curr] // .toFixed(2)
                });
            }
            else {
                _this.priceAndCurrency = {
                    currency: currency.name,
                    price: price[curr]
                };
            }
        });
    };
    DetailViewComponent.prototype.initDescriptionInfo = function () {
        var _this = this;
        this.descriptionInfo = [];
        this.carLocalItemsService.brand = [{ name: this.car.car_model.brand.name, id: this.car.car_model.brand.name }];
        this.carLocalItemsService.model = [{ name: this.car.car_model.name, id: this.car.car_model.name }];
        // this.carLocalItemsService.country = [{name: this.car.advert}]
        this.carLocalItemsService.generation = this.car.generation && this.car.generation.name ? [{
                id: '',
                name: this.car.generation.name
            }] : [];
        this.carLocalItemsService.car_type = [this.car.car_type];
        this.carLocalItemsService.color = [this.car.color];
        this.car['brand'] = this.car.car_model.brand.name;
        this.car['model'] = this.car.car_model.name;
        this.descriptionInfoConfig.forEach(function (info, index) {
            if (info.type === 'arrObj' && !!_this.car[info.property]) {
                _this.descriptionInfoConfig[index]['value'] = _this.carLocalItemsService[info.property]
                    .find(function (data) { return data.name === _this.car[info.property].name; }).name;
                _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
            }
            else if (info.type === 'number' && !!_this.car[info.property]) {
                if (Number(_this.car[info.property]) % 1 !== 0 && !info.disableConversion) {
                    _this.descriptionInfoConfig[index]['value'] = Number(_this.car[info.property]).toFixed(2);
                }
                else {
                    _this.descriptionInfoConfig[index]['value'] = Number.isInteger(_this.car[info.property]) ? _this.car[info.property] : _this.car[info.property].toFixed(1);
                }
                _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
            }
            else if (info.property === 'is_crashed' && _this.car[info.property] !== null) {
                _this.descriptionInfoConfig[index]['value'] = 'CAR.CRASHED' + (_this.car[info.property] ? '.YES' : '.NO');
                _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
            }
            else if (!!_this.car[info.property] && info.type !== 'checkbox') {
                var tmp = _this.carLocalItemsService[info.property]
                    .find(function (data) { return data.id === _this.car[info.property]; });
                if (tmp) {
                    _this.descriptionInfoConfig[index]['value'] = tmp.name;
                }
                _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
            }
            else if (_this.car.hasOwnProperty(info.property) && _this.car[info.property] !== null) {
                // if ((info.property === 'custom_clearance' || info.property === 'guarantee') && this.car[info.property]) {
                if ((info.property === 'custom_clearance' || info.property === 'guarantee')) {
                    if (info.property === 'guarantee' && !_this.car[info.property]) {
                        return;
                    }
                    _this.descriptionInfoConfig[index]['value'] = _this.car[info.property] ? 'CAR.CUSTOM_CLEARANCE.YES' : 'CAR.CUSTOM_CLEARANCE.NO';
                    _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
                }
            }
            else if (info.property === 'exchange' && _this.car.advert[info.property]) {
                _this.descriptionInfoConfig[index]['value'] = 'ADD_CAR.EXCHANGE';
                _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
            }
            else if (info.property === 'country' || info.property === 'region') {
                var advert = _this.car.advert;
                if (advert[info.property]) {
                    _this.descriptionInfoConfig[index]['value'] = advert[info.property].name;
                    _this.descriptionInfo.push(_this.descriptionInfoConfig[index]);
                }
            }
        });
        // this.descriptionInfoConfig = [];
    };
    DetailViewComponent.prototype.initExtraFeatures = function (information) {
        var _this = this;
        var result = [];
        this.extraFeaturesCategories.forEach(function (category, index) {
            result.push({
                label: category.label,
                fields: []
            });
            category.fields.forEach(function (feature) {
                if (feature.type === 'select' && information && information.hasOwnProperty(feature.property) && !!information[feature.property]) {
                    if (Array.isArray(information[feature.property])) {
                        if (information[feature.property].length <= 0) {
                            return;
                        }
                        var tmp_1 = [];
                        information[feature.property].forEach(function (item) {
                            tmp_1.push(_this.carLocalItemsService[feature.property].find(function (data) { return data.id === item; }).name);
                        });
                        result[index].fields.push({
                            labels: tmp_1,
                            head: feature.label
                        });
                    }
                    else {
                        result[index].fields.push({
                            label: _this.carLocalItemsService[feature.property]
                                .find(function (data) { return data.id === information[feature.property]; }).name,
                            head: feature.label
                        });
                    }
                }
                else if (information && information.hasOwnProperty(feature.property) && feature.type === 'checkbox' && !!information[feature.property]) {
                    result[index].fields.push({
                        label: feature.label
                    });
                }
            });
        });
        result = result.filter((function (res) { return res.fields.length !== 0; }));
        this.extraFeatures = result;
    };
    DetailViewComponent.prototype.chunkArray = function (array, chunkSize) {
        if (chunkSize === void 0) { chunkSize = 10; }
        var results = [];
        while (array.length) {
            results.push(array.splice(0, chunkSize));
        }
        return results;
    };
    DetailViewComponent.prototype.toggleFavourite = function () {
        var _this = this;
        if (!this.isFavouritePending) {
            this.isFavouritePending = true;
            if (this.isFavourite) {
                this.$deleteFromFavouritesSubscription = this.carService.removeFromFavourites(this.car.favorite.favorite_id).subscribe(function (res) {
                    _this.isFavourite = false;
                    _this.isFavouritePending = false;
                    _this.toastr.success('TOASTS.FAVOURITE_DELETED', '');
                }, function (error1) { return _this.toastr.error('SHARED.ERROR_MESSAGE', 'SHARED.ERROR_HEADER'); });
            }
            else {
                this.$addToFavouritesSubscription = this.carService.addToFavourites({ advert: this.car.advert.id }).subscribe(function (res) {
                    _this.isFavourite = true;
                    _this.isFavouritePending = false;
                    _this.car.favorite.favorite_id = res.body.car.favorite.favorite_id;
                    _this.toastr.success('TOASTS.FAVOURITE_ADDED', '');
                }, function (error1) { return _this.toastr.error('SHARED.ERROR_MESSAGE', 'SHARED.ERROR_HEADER'); });
            }
        }
    };
    DetailViewComponent.prototype.isFavVisible = function () {
        if (this.authService.isLoggedIn()) {
            return +this.car.advert.user !== +this.authService.getUserId();
        }
        else {
            return false;
        }
    };
    Object.defineProperty(DetailViewComponent.prototype, "favLabel", {
        get: function () {
            return this.isFavourite ? 'FAVOURITES.REMOVE_FROM_FAV' : 'FAVOURITES.ADD_TO_FAV';
        },
        enumerable: true,
        configurable: true
    });
    DetailViewComponent.prototype.destroyMetaTags = function () {
        this.seoService.updateTags({
            title: '',
            description: '',
            details: '',
            image: '',
            'og:image:width': '',
            'og:image:height': '',
        });
        // this.meta.updateTag({property: 'og:type', content: ''});
        // this.meta.updateTag({property: 'og:url', content: ''});
        // this.meta.updateTag({property: 'og:title', content: ''});
        // this.meta.updateTag({property: 'og:description', content: ''});
        // this.meta.updateTag({property: 'og:image', content: ''});
        // this.meta.updateTag({property: 'og:image:width', content: ''});
        // this.meta.updateTag({property: 'og:image:height', content: ''});
    };
    DetailViewComponent.prototype.fbShareBtnClick = function () {
        FB.ui({
            method: 'share',
            href: environment.appUrl + "detail/" + this.car.id + "/" + urlFragment(this.car),
        }, function (response) {
            // console.log('res:', response);
        });
    };
    return DetailViewComponent;
}());
export { DetailViewComponent };
