import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-email-popup',
  templateUrl: './email-popup.component.html',
  styleUrls: ['./email-popup.component.css']
})
export class EmailPopupComponent implements OnInit {

  constructor(
      protected dialogRef: MatDialogRef<EmailPopupComponent>
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}
