import {Component} from '@angular/core';
import {Toast, ToastPackage, ToastrService} from 'ngx-toastr';
import {toastrAnimation} from './toastr-animation';

@Component({
  selector: 'app-toastr, [app-toastr]',
  styleUrls: ['./toastr.component.css'],
  templateUrl: './toastr.component.html',
  animations: toastrAnimation,
  preserveWhitespaces: false,
})
export class ToastrComponent extends Toast {
  undoString = 'undo';

  constructor(protected toastrService: ToastrService,
    public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'undid';
    this.toastPackage.triggerAction();
    return false;
  }
}
