<div >
  <div class="b-featured__item">
    <a [routerLink]="['/detail', car?.id, urlFragment]">
      <img [src]="car.images && car.images[0] ? car.images[0].thumbnail : 'assets/media/237x202/default.png'" alt="" />
    </a>
    <div class="b-featured__item-price">
      {{getCurrency(car?.advert?.currency) | translate}} {{car?.advert?.price}}
    </div>
    <div class="clearfix"></div>
    <h5><a [routerLink]="['/detail', car?.id, urlFragment]">{{car?.car_model?.brand?.name}} {{car?.car_model?.name}} {{car?.generation?.name}}</a></h5>
    <div class="b-featured__item-count"><span class="fa fa-tachometer"></span>{{car?.kilometer}} KM</div>
    <div *ngIf="enabled" class="b-featured__item-links">
      <a>{{language.TRANSMISSION ? language.TRANSMISSION[(car?.transmission).toUpperCase()] : ''}}</a>
      <a>{{language.TRANSMISSION ? language.TRANSMISSION[(car?.car_type?.name).toUpperCase()] : ''}}</a>
      <a>{{language.DRIVE_TYPE ? language.DRIVE_TYPE[(car?.drive_type).toUpperCase()] : ''}}</a>
      <a>{{car?.color?.name}}</a>
      <a>{{car?.production_date}}</a>
    </div>
    <div *ngIf="!enabled" class="b-featured__item-links">
      <a >-</a>
      <a >-</a>
      <a>-</a>
      <a >-</a>
      <a >-</a>
    </div>
  </div>
</div>
