import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var RouterLoaderService = /** @class */ (function () {
    function RouterLoaderService() {
        this.$loader = new Subject();
        this.$stopLoader = new Subject();
    }
    RouterLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function RouterLoaderService_Factory() { return new RouterLoaderService(); }, token: RouterLoaderService, providedIn: "root" });
    return RouterLoaderService;
}());
export { RouterLoaderService };
