import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';

export abstract class BaseHttpService {
  private static API_URL = environment.apiUrl;

  protected constructor(private http: HttpClient) {
  }

  protected post<T>(url: string, body: T, headers: any = {observe: 'response'}): Observable<any> {
    return this.http.post(`${BaseHttpService.API_URL}${url}`, body, headers).pipe(
      map(res => res)
    );
  }

  protected get<T>(url: string, headers: any = {observe: 'response'}): Observable<HttpResponse<T>> {
    return this.http.get<T>(`${BaseHttpService.API_URL}${url}`, headers).pipe(
      map(res => res as any)
    );
  }

  protected delete<T>(url: string, headers: any = {}): Observable<any> {
    return this.http.delete<T>(`${BaseHttpService.API_URL}${url}`, headers);
  }

  protected put<T>(url: string, body: any): Observable<T> {
    return this.http.put<T>(`${BaseHttpService.API_URL}${url}`, body);
  }

  protected patch<T>(url: string, body: any): Observable<HttpResponse<T>> {
    return this.http.patch<T>(`${BaseHttpService.API_URL}${url}`, body, {observe: 'response'}).pipe(
      map(res => res as HttpResponse<T>)
    );
  }
}
