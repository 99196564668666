<div class="b-search__main-type" >
  <div class="col-xs-12 col-md-12">
    <div class="row">
      <form [formGroup]="form" >
        <div class="d-flex car-items justify-between" id="vehicle-types">
          <div class="item" *ngFor="let type of carSeries">
            <label for="type{{type.id}}" class="b-search__main-type-svg">
              <input formControlName="car_type" id="type{{type.id}}" type="radio" [value]="type.id" />
              <img [src]="type.image" alt="">
              <p class="type-name"><label for="type{{type.id}}"> {{type.name}}</label></p>
            </label>
          </div>
          <span class="fa fa-angle-down expand-icon" id="vehicle-expand" (click)="expandVehicleTypes()"></span>
        </div>
      </form>
    </div>
  </div>
</div>
