<section class="b-pageHeader">
  <div class="container">
    <h1>Error 404 Page</h1>
  </div>
</section><!--b-pageHeader-->

<div class="b-breadCumbs s-shadow">
  <div class="container">
    <a routerLink="/" class="b-breadCumbs__page">Home</a>
    <span class="fa fa-angle-right"></span>
    <a routerLink="/page-not-found" class="b-breadCumbs__page m-active">Page Not Found</a>
  </div>
</div><!--b-breadCumbs-->

<section class="b-error s-shadow">
  <div class="container">
    <h1>Error</h1>
    <p class="not-found">4<span>0</span>4</p>
    <h2 class="s-lineDownCenter">page not found</h2>
    <p>The page you are looking for is not available and might have been removed, its name changed or is temporarily unavailable.</p>
  </div>
</section><!--b-error-->
