import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IRouterDataModel } from '../../../shared/models/router-data.model';
import { ActivatedRoute, NavigationEnd, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  @Input() data: IRouterDataModel;

  private $subscription: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.execute();
    this.$subscription = this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe(() => {
        this.execute();
      });
  }

  private execute(): void {
    this.data = this.getFirstRouteWithData(this.route.snapshot).data as IRouterDataModel;
  }

  ngOnDestroy() {
    if (this.$subscription) {
      this.$subscription.unsubscribe();
    }
  }

  private getFirstRouteWithData(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while(route.firstChild && Object.keys(route.data).length === 0) {
      route = route.firstChild;
    }

    return route;
  }
}
