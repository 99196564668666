var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { InfobarComponent } from './infobar/infobar.component';
import { FormBuilder } from '@angular/forms';
import { CarService } from '../../core/services/car.service';
import { ActivatedRoute, Router, Scroll } from '@angular/router';
import { CarLocalItemsService } from '../../core/services/car-local-items.service';
import { FiltersConfig } from './filters.config';
import { PagePreloaderComponent } from '../../core/layout/page-preloader/page-preloader.component';
import { RouterLoaderService } from '../../core/services/router-loader.service';
import { getQueryParamsStr } from '../profile/utils';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MyToastrService } from '../../shared/toastr/my-toastr.service';
import { QueryParamsService } from './services/query-params.service';
import { backendErrorMessagesHandler } from 'src/app/shared/utils/utils';
var FilterComponent = /** @class */ (function () {
    function FilterComponent(fb, carService, router, viewportScroller, routerLoaderService, carLocalItems, activatedRoute, queryParamsService, translate, toastr) {
        var _this = this;
        this.fb = fb;
        this.carService = carService;
        this.router = router;
        this.viewportScroller = viewportScroller;
        this.routerLoaderService = routerLoaderService;
        this.carLocalItems = carLocalItems;
        this.activatedRoute = activatedRoute;
        this.queryParamsService = queryParamsService;
        this.translate = translate;
        this.toastr = toastr;
        this.filterConfig = {
            limit: 10
        };
        this.filters = FiltersConfig.filters;
        var extras = this.filters.find(function (item) { return item.thisIsExtraFields; });
        // for ordering in alphabetical order
        // при смене языка могут быть проблемы 
        extras.fields.forEach(function (item) { return item.label = _this.translate.instant(item.label); });
        extras.fields.sort(function (a, b) { return a.label.localeCompare(b.label); });
        this.activatedRoute.queryParams.subscribe(function (params) {
            _this.routerParams = params;
            _this.filterConfig = __assign({}, params);
        });
        this.subscribeToRouterParams();
        this.router.events.pipe(filter(function (e) { return e instanceof Scroll; })).subscribe(function (e) {
            if (e.position) {
                _this.scrollPosition = e.position;
            }
            else {
                _this.scrollPosition = [0, 0];
            }
        });
    }
    FilterComponent.prototype.toggleFilter = function () {
        var aside = document.getElementById('aside');
        aside.classList.toggle('open');
    };
    FilterComponent.prototype.resetFilter = function () {
        this.form.reset();
        var queryParams = this.activatedRoute.snapshot.queryParams;
        var newParams = { limit: queryParams['limit'], offset: queryParams['offset'], posted_time_desc: 1 };
        this.filterConfig = newParams;
        // clear checkboxes (from back to forward order matters somehow??)
        var filters = this.filters.slice();
        for (var i = filters.length - 1; i >= 0; i--) {
            if (filters[i].hasOwnProperty('shadowProperty')) {
                this.form.removeControl(filters[i].property);
                delete this.carLocalItems[filters[i].property];
                this.filters.splice(i, 1);
            }
        }
        // this.queryParamsService.reset();
        this.getCars(newParams);
    };
    FilterComponent.prototype.ngOnInit = function () {
        PagePreloaderComponent.isGoToTopEnabled = false;
    };
    FilterComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.viewportScroller.scrollToPosition(_this.scrollPosition); });
    };
    FilterComponent.prototype.ngOnDestroy = function () {
        var _this = this;
        PagePreloaderComponent.isGoToTopEnabled = true;
        var filters = this.filters.slice();
        var deletedCount = 0;
        filters.forEach(function (filteredItem, i) {
            if (filteredItem.hasOwnProperty('shadowProperty')) {
                delete _this.carLocalItems[filteredItem.property];
                _this.filters.splice(i - deletedCount, 1);
                deletedCount++;
            }
        });
        if (this.$getCarsSubscription) {
            this.$getCarsSubscription.unsubscribe();
        }
    };
    Object.defineProperty(FilterComponent.prototype, "count", {
        get: function () {
            return this.results.body.count;
        },
        enumerable: true,
        configurable: true
    });
    FilterComponent.prototype.search = function () {
        // this.getCars(queryParams);
    };
    FilterComponent.prototype.getCars = function (queryParams) {
        var _this = this;
        this.filterConfig = __assign({}, this.filterConfig, queryParams);
        if (this.$getCarsSubscription) {
            this.$getCarsSubscription.unsubscribe();
        }
        this.routerLoaderService.$loader.next(true);
        this.$getCarsSubscription = this.carService.getCars(getQueryParamsStr(this.filterConfig)).subscribe(function (res) { return __awaiter(_this, void 0, void 0, function () {
            var a, bool;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.results = res;
                        a = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
                        bool = false;
                        if (document.documentElement.scrollTop) {
                            bool = true;
                        }
                        return [4 /*yield*/, this.router.navigate(['/filter'], {
                                queryParams: this.filterConfig,
                                relativeTo: this.activatedRoute
                            })];
                    case 1:
                        _a.sent();
                        if (bool) {
                            document.documentElement.scrollTop = a;
                        }
                        else {
                            document.body.scrollTo(0, a);
                        }
                        this.routerLoaderService.$stopLoader.next();
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            backendErrorMessagesHandler(error).forEach(function (err) { return _this.toastr.error(err.field, err.error); });
            _this.routerLoaderService.$stopLoader.next();
        });
    };
    FilterComponent.prototype.listingAndSortingParamsChange = function (event) {
        var _this = this;
        event.remove.forEach(function (item) {
            if (_this.filterConfig[item]) {
                delete _this.filterConfig[item];
            }
        });
        var params = {};
        event.add.forEach(function (item) { return params[item] = 1; });
        this.getCars(__assign({}, params, { limit: event.limit, offset: 0 }));
    };
    FilterComponent.prototype.filterMultipleData = function (i, property, shadowProperty) {
        if (property === 'brand') {
            this.manageControl(i, property, 'model', this.carService.getModel(this.carLocalItems[property][i].id));
            this.searchWithMultipleParams(property, property);
        }
        else {
            var modelIndex = this.carLocalItems.brand.findIndex(function (item) { return item.name === property; });
            if (modelIndex !== -1 && shadowProperty && shadowProperty !== 'generation') {
                if (this.form.get(property).at(i).value) {
                    this.manageControl(i, property, 'generation', this.carService
                        .getGenerations(this.carLocalItems[this.carLocalItems.brand[modelIndex].name][i].id));
                }
                else {
                    this.manageControl(i, property, null, undefined);
                }
                this.searchWithMultipleParams(property, shadowProperty);
            }
            else if (shadowProperty === 'generation') {
                this.searchWithMultipleParams(property, shadowProperty);
            }
            else {
                this.searchWithMultipleParams(property, property);
            }
        }
    };
    FilterComponent.prototype.searchWithMultipleParams = function (formProp, queryProp) {
        var _this = this;
        var queryParams = __assign({}, this.filterConfig);
        var set = new Set();
        // merge with previous values
        if (this.filterConfig[queryProp]) {
            this.filterConfig[queryProp].split(',').forEach(function (id) {
                set.add("" + id);
            });
        }
        // get true values
        this.getFormArray(formProp).forEach(function (val, index) {
            if (val.value) {
                set.add("" + _this.carLocalItems[formProp][index].id);
                // brand/model/generation case is different, as they are connected
                if (queryProp === 'model') {
                    var model = _this.carLocalItems[formProp][index];
                    if (!_this.queryParamsService.models.has(model)) {
                        _this.queryParamsService.models.set(model.id, model);
                    }
                }
                if (queryProp === 'generation') {
                    var generation = _this.carLocalItems[formProp][index];
                    if (!_this.queryParamsService.generation.has(generation)) {
                        _this.queryParamsService.generation.set(generation.id, generation);
                    }
                }
            }
            else {
                if (set.has("" + _this.carLocalItems[formProp][index].id)) {
                    set.delete("" + _this.carLocalItems[formProp][index].id);
                }
                // delete selected models/generations in case of brand deselect
                // and generations in case of model deselect
                if (queryProp === 'brand' || queryProp === 'model') {
                    // itemID is either id of a brand or id of a model
                    var itemID = _this.carLocalItems[formProp][index].id;
                    if (queryProp === 'model') {
                        _this.queryParamsService.deleteGenerationsForUncheckedModel(+itemID);
                    }
                    if (queryProp === 'brand') {
                        _this.queryParamsService.deleteGenerationsForUncheckedBrand(+itemID);
                        _this.queryParamsService.deleteModelsForUncheckedBrand(+itemID);
                        _this.filterConfig.model = Array.from(_this.queryParamsService.models.keys()).join(',');
                        queryParams['model'] = Array.from(_this.queryParamsService.models.keys()).join(',');
                    }
                    // these 2 lines need to be executed both for brand and model deselect
                    _this.filterConfig.generation = Array.from(_this.queryParamsService.generation.keys()).join(',');
                    queryParams['generation'] = Array.from(_this.queryParamsService.generation.keys()).join(',');
                }
            }
        });
        queryParams['offset'] = 0;
        queryParams[queryProp] = Array.from(set.values()).join(',');
        this.getCars(queryParams);
    };
    FilterComponent.prototype.filterSingle = function (i, property, isCheckbox, defaultValue) {
        if (isCheckbox === void 0) { isCheckbox = false; }
        var queryParams = {};
        if (this.filters[i].type === 'input') {
            // support for decimals with one number after dot
            // if no value => allow
            // if there is value =>
            //    a) it's 0
            //    b) it's < 0
            //    c) it is integer
            //    d) it is decimal
            var value = this.form.get(property).value;
            if (!value) {
                value = null;
            }
            else {
                if (!Number(value)) {
                    value = defaultValue || 1;
                }
                else {
                    if (value < 0) {
                        value = value * -1;
                    }
                    else if (value === 0) {
                        value = defaultValue || 1;
                    }
                    if (!Number.isInteger(+value)) {
                        value = Number(value).toFixed(1);
                    }
                }
            }
            this.form.get(property).setValue(value);
            queryParams[property] = this.form.get(property).value ? this.form.get(property).value : '';
        }
        else {
            // selects can have '' and false
            // for checkboxes change false/null to ''
            if (isCheckbox) {
                // this part refers to checkboxes (extra features i think)
                queryParams[property] = this.form.get(property).value ? true : '';
            }
            else {
                // this part refers to these filters: TAirbag, posted_time, is_crashed
                // this.form.get(property).value can be string, boolean or null
                queryParams[property] = this.form.get(property).value === null ? '' : this.form.get(property).value;
            }
        }
        queryParams['offset'] = 0;
        this.getCars(queryParams);
    };
    FilterComponent.prototype.manageControl = function (index, property, shadowProperty, callback) {
        var _this = this;
        var name = this.carLocalItems[property][index].name;
        var value = this.form.get(property).at(index).value;
        if (value) {
            callback.subscribe(function (res) {
                if (_this.form.get(property).at(index).value) {
                    _this.carLocalItems[name] = res.body.results;
                    var controls_1 = [];
                    res.body.results.forEach(function () {
                        controls_1.push(_this.fb.control(null));
                    });
                    _this.form.addControl(name, _this.fb.array(controls_1));
                    var i = _this.filters.findIndex(function (item) { return item.property === property; });
                    _this.filters.splice(i + 1, 0, {
                        property: name, isCollapsed: false, multiple: true,
                        type: 'select', label: name, shadowProperty: shadowProperty
                    });
                }
            });
        }
        else if (this.carLocalItems.hasOwnProperty(name)) {
            var i = this.filters.findIndex(function (item) { return item.property === name; });
            this.filters.splice(i, 1);
            this.form.removeControl(name);
            this.carLocalItems[name].forEach(function (item) {
                // item is model, carLocalItems[item.name] is generation
                if (_this.carLocalItems.hasOwnProperty(item.name)) {
                    delete _this.carLocalItems[item.name];
                    var j = _this.filters.findIndex(function (filteredItem) { return filteredItem.property === item.name; });
                    _this.filters.splice(j, 1);
                }
                if (_this.form.get(item.name)) {
                    _this.form.removeControl(item.name);
                }
            });
            delete this.carLocalItems[name];
        }
    };
    FilterComponent.prototype.createForm = function () {
        var _this = this;
        var formGroup = {};
        this.filters.forEach(function (filteredItem) {
            var values = [];
            if (filteredItem.multiple) {
                if (_this.routerParams.hasOwnProperty(filteredItem.property)) {
                    values = _this.routerParams[filteredItem.property].split(',');
                }
                else if (filteredItem.hasOwnProperty('shadowProperty') && _this.routerParams.hasOwnProperty(filteredItem.shadowProperty)) {
                    var routerP_1 = _this.routerParams[filteredItem.shadowProperty].split(',');
                    var parentPropName_1;
                    if (filteredItem.shadowProperty === 'model') {
                        parentPropName_1 = 'brand';
                    }
                    else if (filteredItem.shadowProperty === 'generation') {
                        parentPropName_1 = 'model';
                    }
                    _this.carLocalItems[filteredItem.property].forEach(function (prop) {
                        if (routerP_1.find(function (data) { return (+data === +prop.id) && (prop[parentPropName_1].name === filteredItem.property); })) {
                            values.push(prop.id);
                        }
                    });
                }
                formGroup[filteredItem.property] = _this.buildCheckboxes(_this.carLocalItems[filteredItem.property], values);
            }
            else if (!filteredItem.multiple && filteredItem.type === 'select') {
                if (!_this.routerParams[filteredItem.property]) {
                    // no value, maybe '' empty string
                    formGroup[filteredItem.property] = _this.fb.control(null);
                }
                else if (_this.routerParams[filteredItem.property] === 'true' || _this.routerParams[filteredItem.property] === 'false' ||
                    +_this.routerParams[filteredItem.property] === 1 || +_this.routerParams[filteredItem.property] === 0) {
                    // this is boolean
                    formGroup[filteredItem.property] = _this.fb.control(!!_this.routerParams[filteredItem.property]);
                }
                else {
                    // others have their string values
                    formGroup[filteredItem.property] = _this.fb.control(_this.routerParams[filteredItem.property]);
                }
            }
            else if (filteredItem.type === 'input') {
                filteredItem.fields.forEach(function (field) {
                    formGroup[field.property] = _this.fb.control(_this.routerParams[field.property]);
                });
            }
            else if (filteredItem.type === 'checkbox' && filteredItem.hasOwnProperty('fields')) {
                filteredItem.fields.forEach(function (field) {
                    // convert to boolean otherwise 0 or '0' is valid value for checkbox
                    formGroup[field.property] = _this.fb.control(!!Number(_this.routerParams[field.property]));
                });
            }
            else {
                formGroup[filteredItem.property] = _this.fb.control(!!Number(_this.routerParams[filteredItem.property]));
            }
        });
        this.form = this.fb.group(formGroup);
        // const queryParams = this.routerParams;
        // if (queryParams.brand) {
        //   this.queryParamsService.brands = new Set((queryParams.brand as string).split(','));
        // }
        // if (queryParams.model) {
        //   const modelIDs = (queryParams.model as string).split(',');
        //   modelIDs.forEach(id => {
        //     const model = this.getFormArray('')
        //   });
        // }
    };
    FilterComponent.prototype.buildCheckboxes = function (config, values) {
        var _this = this;
        if (!config) {
            return this.fb.array([]);
        }
        var arr;
        if (values) {
            arr = config.map(function (conf) {
                if (values.find(function (val) { return val.toString() === conf.id.toString(); })) {
                    return _this.fb.control(conf.id);
                }
                else {
                    return _this.fb.control(null);
                }
            });
        }
        else {
            arr = config.map(function (conf) {
                return _this.fb.control(null);
            });
        }
        return this.fb.array(arr);
    };
    FilterComponent.prototype.getFormArray = function (property) {
        return this.form.get(property).controls;
    };
    FilterComponent.prototype.getFormArrayLength = function (property) {
        return this.form.get(property).controls.length;
    };
    FilterComponent.prototype.subscribeToRouterParams = function () {
        var _this = this;
        this.activatedRoute.data
            .subscribe(function (data) {
            if (data.car) {
                Object.keys(data.car).forEach(function (prop) {
                    _this.initData(data.car[prop], prop);
                });
                _this.createForm();
            }
        }).unsubscribe();
    };
    FilterComponent.prototype.initData = function ($response, property) {
        var _this = this;
        $response.subscribe(function (res) {
            if (res) {
                if (property === 'results') {
                    _this.results = res;
                }
                _this.carLocalItems[property] = res.body.results;
            }
        });
    };
    return FilterComponent;
}());
export { FilterComponent };
