import {UrlMatchResult, UrlSegment} from "@angular/router";
import {AppLanguageService} from "../services/app-language.service";

export function languageMatcher(url: UrlSegment[]): UrlMatchResult | null {
    return url.length === 1 && ifContainsLanguage(url[0].path) ? ({consumed: url}) : null;
}

export function ifContainsLanguage(lang) {
    return AppLanguageService.TRANSFORMED_LANGUAGES.includes(lang);
}

export function ifInHomepage() {
    return ifContainsLanguage(window.location.pathname.slice(1));
}