import {Component, ViewChild, HostListener, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {navBarCollapse} from '../../../shared/animations/animations';
import {navBarCollapseSubNav} from '../../../shared/animations/animations';
import {AuthorizationService} from '../../services/authorization.service';
import {MatDialog} from '@angular/material';
import {AddPostLinkModalComponent} from '../../../pages/profile/add-a-car/add-post-link-modal/add-post-link-modal.component';
import {AppLanguageService} from '../../services/app-language.service';
import { ifInHomepage } from '../../route/route-matcher';

@Component({
  selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
  animations: [
      navBarCollapse,
      navBarCollapseSubNav
  ]
})
export class HeaderComponent implements OnInit {
  public isProfileUlOpened = false;
	public menuIsOpened = false;
	public isLangChangePanelOpen = false;
	public currentLang: string = AppLanguageService.CURRENT_LANG;
	public languages: string[] = AppLanguageService.langList;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthorizationService,
    public readonly appLanguageService: AppLanguageService,
    public dialog: MatDialog) {
	}
	
	ngOnInit(): void {
		// get current lang 
	}

  toggleState() {
    if (this.isAnimated) {
      this.isProfileUlOpened = !this.isProfileUlOpened;
    }
  }

  signOut() {
    this.closeMenu();
    this.authService.logOut();
  }

  openAddPostLinkModal() {
    this.dialog.open(AddPostLinkModalComponent);
  }

  toggleMenu() {
    if (this.menuIsOpened) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  get isAnimated() {
    return window.innerWidth < 992;
  }

  openMenu(): void {
    this.menuIsOpened = true;
  }

  closeMenu(): void {
    this.menuIsOpened = false;
  }

  isUserLoggedIn(): boolean {
    return this.authService.isLoggedIn();
	}
	
	public onLangChangePanelClick(): void {
		this.isLangChangePanelOpen = !this.isLangChangePanelOpen;
	}

	public onLangChange(lang: string): void {
		this.isLangChangePanelOpen = false;
		this.appLanguageService.defineLanguage(lang);
		this.currentLang = lang;
		if (ifInHomepage()) {
            window.location.href = `/${AppLanguageService.API_LANGUAGE}`
        } else {
            window.location.reload();
        }
	}
}
