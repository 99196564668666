<section class="b-search">
	<div class="container">
		<form class="b-search__main s-submit">
			<!--VEHICLE TYPE-->
			<app-vehicle-type></app-vehicle-type>
			<!--VEHICLE TYPE-->
			<form [formGroup]="form">
				<div class="b-search__main-form">
					<div class="col-xs-12">
						<div class="row">
							<div class="col-xs-12">
								<div class="m-firstSelects no-margin">
									<div class="col-lg-3 col-md-4 col-sm-6 item mark-list">
										<p class="c-label">{{"ADD_CAR.MAKE" | translate}}</p>
										<!--SELECT COMPONENT-->
										<div class="select-box marks">
											<app-select [enableScroll]="true" [virtualScroll]="false" [items]="carLocalItems.brand"
												[id]="'car-brand'" formControlName="brand" [placehold]="'ADD_CAR.MAKE' | translate"
												[onChangeEvent]="onBrandSelect"></app-select>
										</div>
										<!--SELECT COMPONENT-->
									</div>
									<div class="col-lg-3 col-md-4 col-sm-6 item">
										<p class="c-label">{{"ADD_CAR.MODEL" | translate}}</p>
										<!--SELECT COMPONENT-->
										<div class="select-box">
											<app-select formControlName="model" [onChangeEvent]="onModelSelect" [items]="carLocalItems.model"
												[enableScroll]="true" [loading]="dropDownsLoading.modelIsLoading" [id]="'car-model'"
												[placehold]="'ADD_CAR.MODEL' | translate"></app-select>
										</div>
										<!--SELECT COMPONENT-->
									</div>
									<div class="col-lg-2 col-md-4 col-sm-6 item">
										<p class="c-label">{{"ADD_CAR.STYLE" | translate}}</p>
										<!--SELECT COMPONENT-->
										<div class="select-box">
											<app-select [items]="carLocalItems.generation" formControlName="generation" [enableScroll]="true"
												[loading]="dropDownsLoading.generationIsLoading" [id]="'car-generation'"
												[placehold]="'ADD_CAR.STYLE' | translate"></app-select>
										</div>
										<!--SELECT COMPONENT-->
									</div>
									<div class="col-lg-3 col-md-8 col-sm-6 col-xs-12 pl-0 pr-0 mb-4 item">
										<div class="col-lg-12 item">
											<p class="c-label">{{"CAR_DETAIL.YEAR" | translate}}</p>
										</div>
										<div class="col-xs-6 pr-1 item-left">
											<div class="text-field no-margin">
												<input appDigitOnly formControlName="production_date_range_min" type="text" min="0"
													[placeholder]="'CAR.FROM' | translate">
											</div>
										</div>
										<div class="col-xs-6 pl-1 pr-3 item-right">
											<div class="text-field no-margin">
												<input appDigitOnly formControlName="production_date_range_max" type="text" min="0"
													[placeholder]="'CAR.TO' | translate">
											</div>
										</div>
									</div>
									<div class="col-lg-6 col-md-8 col-sm-12 col-xs-12 pl-0 pr-0 mb-4 item">
										<div class="col-lg-12 item">
											<p class="c-label">{{"ADD_CAR.PRICE" | translate}}</p>
										</div>
										<div class="col-lg-3 col-md-6 col-sm-3 col-xs-6 pr-1 item-left">
											<div class="text-field no-margin">
												<input appDigitOnly [disableZerosAtStart]="true" formControlName="price_range_min" type="text"
													[placeholder]="'CAR.FROM' | translate" min="0" value="" name="price-from" id="price-from" />
											</div>
										</div>
										<div class="col-lg-3 col-md-6 col-sm-3 col-xs-6 pl-1 item-right">
											<div class="text-field no-margin">
												<input appDigitOnly [disableZerosAtStart]="true" formControlName="price_range_max" type="text"
													[placeholder]="'CAR.TO' | translate" min="0" value="" name="price-from" id="price-to" />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-xs-12">

									<!--ADVANCED-SEARCH-->
									<app-advanced-search [collapsed]="isAdvancedSearchHidden"></app-advanced-search>
									<!--ADVANCED-SEARCH-->

									<div class="col-lg-12 col-md-12">
										<div class="col-lg-12 col-md-12">
											<div class="b-search__main-form-submit">
												<a id="drop-label" class="drop-label"
													(click)="toggleAdvancedSearchSection()">{{advancedSearchLabel | translate}}</a>
												<button type="submit" (click)="search()"
													class="btn m-btn">{{"SEARCH.SEARCH_THE_VEHICLE" | translate}}<span
														class="fa fa-angle-right"></span></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</form>
	</div>
</section>
<!--b-search-->