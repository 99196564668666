export const signUpValidationMessages = {
  'email_or_phone': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'pattern', message: 'AUTH.WRONG_EMAIL_OR_PHONE_NUMBER'},
    {type: 'exists', message: 'AUTH.EXISITNG_USER_WITH_EMAIL_OR_PHONE'},
    {type: 'exists-email', message: 'AUTH.EXISITNG_USER_WITH_EMAIL'},
    {type: 'exists-phone', message: 'AUTH.EXISITNG_USER_WITH_PHONE'},
    {type: 'wrongEmailOrPhoneNumber', message: 'AUTH.WRONG_EMAIL_OR_PHONE_NUMBER'}
  ],
  'confirm_password': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'areEqual', message: 'AUTH.PASSWORDS_NOT_MATCH'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'maxlength', message: 'AUTH.MAX_LENGTH_PASSWORD'}
  ],
  'password': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'maxlength', message: 'AUTH.MAX_LENGTH_PASSWORD'}
  ]
};

export const signInValidationMessages = {
  'email': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'pattern', message: 'AUTH.INVALID_EMAIL'},
    {type: 'wrongEmailOrPhoneNumber', message: 'AUTH.WRONG_EMAIL_OR_PHONE_NUMBER'}
  ],
  'password': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'maxlength', message: 'AUTH.MAX_LENGTH_PASSWORD'}
  ]
};

export const forgotPasswordValidationMessages = {
  'email': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'pattern', message: 'AUTH.INVALID_EMAIL'},
    {type: 'not-exist', message: 'AUTH.NOT_EXISITNG_USER_WITH_EMAIL'},
    {type: 'already-active', message: 'AUTH.EXISITNG_USER_WITH_EMAIL'},
    {type: 'inactive', message: 'AUTH.INACTIVE_USER_WITH_EMAIL'}
  ]
};

export const resetPassValidationMessages = {
  'password': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'areEqual', message: 'AUTH.PASSWORDS_NOT_MATCH'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'}
  ]
};

export const contactUSValidationMessages = {
  'email': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'pattern', message: 'VALIDATION.EMAIL'}
  ],
  'name': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'notOnlyChars', message: 'VALIDATION.ONLY_ALPHABETICAL_CHARS'},
    {type: 'notOnlySpaces', message: 'VALIDATION.NOT_ONLY_SPACES'}
  ],
  'message': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'notOnlySpaces', message: 'VALIDATION.NOT_ONLY_SPACES'}
  ]
};

export const profileChangePassword = {
  'old_password': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'wrong_password', message: 'VALIDATION.WRONG_OLD_PASSWORD'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'maxlength', message: 'AUTH.MAX_LENGTH_PASSWORD'},
  ],
  'repeat_password': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'areEqual', message: 'AUTH.PASSWORDS_NOT_MATCH'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'maxlength', message: 'AUTH.MAX_LENGTH_PASSWORD'},
  ],
  'password': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'areEqual', message: 'AUTH.PASSWORDS_NOT_MATCH'},
    {type: 'pattern', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'minlength', message: 'AUTH.MIN_LENGTH_PASSWORD'},
    {type: 'maxlength', message: 'AUTH.MAX_LENGTH_PASSWORD'},
  ]
};

export const accoutInformation = {
  'first_name': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'pattern', message: 'VALIDATION.FIRST_NAME'},
    {type: 'notOnlyChars', message: 'VALIDATION.ONLY_ALPHABETICAL_CHARS'},
    {type: 'notOnlySpaces', message: 'VALIDATION.NOT_ONLY_SPACES'}
  ],
  'last_name': [
    {type: 'required', message: 'VALIDATION.REQUIRED'},
    {type: 'pattern', message: 'VALIDATION.LAST_NAME'},
    {type: 'notOnlyChars', message: 'VALIDATION.ONLY_ALPHABETICAL_CHARS'},
    {type: 'notOnlySpaces', message: 'VALIDATION.NOT_ONLY_SPACES'}
  ],
  'email': [
    {type: 'required', message: 'VALIDATION.REQUIRED' },
    {type: 'pattern', message: 'VALIDATION.EMAIL'},
    {type: 'exists', message: 'AUTH.EXISITNG_USER_WITH_EMAIL'}
  ],
  'phone': [
    {type: 'required', message: 'AUTH.REQUIRED_FIELD'},
    {type: 'wrongNumber', message: 'VALIDATION.PHONE_NUMBER'},
    {type: 'pattern', message: 'VALIDATION.PHONE_NUMBER'},
    {type: 'exists', message: 'AUTH.EXISITNG_USER_WITH_PHONE'},
    {type: 'onlyNumbers', message: 'VALIDATION.ONLY_NUMBERS'}
  ],
  'zip_code': [
    {type: 'pattern', message: 'VALIDATION.POST'},
    {type: 'notOnlyNumbers', message: 'VALIDATION.ONLY_NUMBERS'}
  ]
};

export const emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const passwordValidator = /^(?=.*[\d])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()\-=+\\|])[\w!@#$%^&*()\-=+\\|]{6,}$/;
