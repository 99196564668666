import {checkboxes} from '../detail-view/detail.config';
export class FiltersConfig {
  static filters: any = [
    {property: 'brand', isCollapsed: false, multiple: true, type: 'select', label: 'ADD_CAR.MAKE'},
    {type: 'input', label: 'ADD_CAR.YEAR', fields: [
        {property: 'production_date_range_min', type: 'input'},
        {property: 'production_date_range_max', type: 'input'}
      ]},
    {type: 'input', label: 'ADD_CAR.PRICE', fields: [
        {property: 'price_range_min', type: 'input'},
        {property: 'price_range_max', type: 'input'}
      ]},
    {type: 'input', label: 'CAR_DETAIL.MILE', fields: [
        {property: 'kilometer_range_min', type: 'input'},
        {property: 'kilometer_range_max', type: 'input'}
      ]},
    {property: 'car_type', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.BODY_TYPE'},
    {property: 'transmission', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.TRANSMISSION'},
    {property: 'fuel_type', isCollapsed: true, multiple: true, type: 'select', label: 'CAR_DETAIL.FUEL_TYPE'},
    {property: 'guarantee', single: true, type: 'checkbox', label: 'ADD_CAR.GUARANTEE'},
    {property: 'exchange', single: true, type: 'checkbox', label: 'ADD_CAR.EXCHANGE'},
    {property: 'custom_clearance', single: true, type: 'checkbox', label: 'CAR.CUSTOM_CLEARANCE.LABEL'},
    {property: 'color', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.COLOR'},
    {property: 'interior_flavor', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.SALON_COLOR'},
    {property: 'interior_type', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.SALON'},
    {property: 'country', isCollapsed: true, multiple: true, type: 'select', label: 'CAR_DETAIL.LOCATION'},
    {property: 'region', isCollapsed: true, multiple: true, type: 'select', label: 'CAR_DETAIL.STATE'},
    {property: 'passenger_seat_regulator', isCollapsed: true,  multiple: true, type: 'select', label: 'ADD_CAR.PASSENGER_SEAT_CONFIG'},
    {property: 'driver_seat_regulator', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.DRIVER_SEAUPLOADT_CONFIG'},
    {property: 'helm_regulator', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.DRIVE_CONFIG'},
    {property: 'window_regulator', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.GLASS_CONTROL'},
    {property: 'climate_control', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.CLIMATE_CONTROL'},
    {property: 'owners_count', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.NUMBER_OF_OWNERS'},
    {property: 'drive_type', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.DRIVE_TYPE'},
    {property: 'drive_wheel_type', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.DRIVE'},
    {property: 'usage', isCollapsed: true, multiple: true, type: 'select', label: 'CAR.USED.YES'},
    {property: 'seat_count', isCollapsed: true, multiple: true, type: 'select', label: 'ADD_CAR.SEAT_NUMBER'},
    // not multiple ranges
    {type: 'input', label: 'ADD_CAR.CUBIC_CAPACITY', isDecimal: true, fields: [
        {property: 'cubic_min', type: 'input', defaultValue: 0.3},
        {property: 'cubic_max', type: 'input', defaultValue: 0.3}
      ]},
    {type: 'input', label: 'ADD_CAR.POWER', fields: [
        {property: 'power_min', type: 'input', allowDecimals: true},
        {property: 'power_max', type: 'input', allowDecimals: true},
      ]},
    {property: 'is_crashed', type: 'select', label: 'ADD_CAR.DAMAGE'},
    {property: 'posted_time', type: 'select', label: 'ADD_CAR.POSTED_TIME'},
    // not multiple
    // {property: 'created'}, ?????
    // {property: 'owner'}, ?????
    {property: 'airbag', type: 'select', label: 'ADD_CAR.SAFETY_PADS'},
    // boolean
    {type: 'checkbox', isCollapsed: true, fields: [...checkboxes], thisIsExtraFields: true},
    /*
    {property: 'sunroof', multiple: false},
    {property: 'tined_windows', multiple: false},
    {property: 'xenon_headlights', multiple: false},
    {property: 'alloy_wheels', multiple: false},
    {property: 'board_computer', multiple: false},
    {property: 'gas_cylinder_equipment', multiple: false},
    {property: 'rain_sensor', multiple: false},
    {property: 'cruise_property', multiple: false},
    {property: 'navigation', multiple: false},
    {property: 'heated_mirrors', multiple: false},
    {property: 'headlight_washer', multiple: false},
    {property: 'central_lock', multiple: false},
    {property: 'security_alarm', multiple: false},
    {property: 'heated_helm', multiple: false},
    {property: 'heated_seal', multiple: false},
    {property: 'electric_mirrors', multiple: false},
    {property: 'abs', multiple: false},
    {property: 'anti_sleep', multiple: false},
    {property: 'esp', multiple: false},
    {property: 'parktronic', multiple: false},
    {property: 'cd', multiple: false},
    {property: 'tv', multiple: false},*/
  ];
}
// {property: 'posted_time', multiple: false}, // sorting filter

export const sorting_filters = [
  {name: 'FILTER.POSTED_TIME_DESC', id: 'posted_time_desc'},
  {name: 'FILTER.PRICE_ASC', id: 'price_asc'},
  {name: 'FILTER.PRICE_DESC', id: 'price_desc'},
  {name: 'FILTER.PRODUCTION_DATE_ASC', id: 'production_date_asc'},
  {name: 'FILTER.PRODUCTION_DATE_DESC', id: 'production_date_desc'},
  {name: 'FILTER.KILOMETER_DESC', id: 'kilometer_desc'},
];
function animate(elem: HTMLElement, style: string, unit: string, from: number, to: number, time: number, prop: boolean): void {
  if (elem) {
    const start = new Date().getTime(),
      timer = setInterval((): void => {
        const step = Math.min(1, (new Date().getTime() - start) / time);
        if (prop) {
          elem[style] = (from + step * (to - from)) + unit;
        } else {
          elem.style[style] = (from + step * (to - from)) + unit;
        }
        if (step === 1) {
          clearInterval(timer);
        }
      }, 5);
    if (prop) {
      elem[style] = from + unit;
    } else {
      elem.style[style] = from + unit;
    }
  }
}

export function goToTop() {
  animate(document.documentElement, 'scrollTop', '', document.documentElement.scrollTop, document.body.offsetTop, 0, true);
}
