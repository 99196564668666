import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {sorting_filters} from '../filters.config';
import {ActivatedRoute} from '@angular/router';
import {InfobarEvent} from '../../../shared/models/infobar-event.model';

@Component({
  selector: 'app-infobar, [app-infobar]',
  templateUrl: './infobar.component.html'
})
export class InfobarComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() count;
  @Output() listingAndSortingParamsChange: EventEmitter<InfobarEvent> = new EventEmitter();
  listed = true;
  form: FormGroup;
  limit: number;
  sorting_filters;
  itemsCount;

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute) {
    if (this.activatedRoute.snapshot.queryParams.hasOwnProperty('limit')) {
      localStorage.setItem('limit', this.activatedRoute.snapshot.queryParams['limit']);
    }
    this.limit = +localStorage.getItem('limit') || 50;
    this.sorting_filters = sorting_filters;
    if (!localStorage.getItem('listed') || localStorage.getItem('listed') === 'true') {
      this.listed = true;
    } else if (localStorage.getItem('listed') === 'false') {
      this.listed = false;
    }
  }

  ngOnInit() {
    this.createForm();

    for (const item of this.sorting_filters) {
      if (this.activatedRoute.snapshot.queryParams.hasOwnProperty(item.id)) {
        this.form.patchValue({
          sort_by: item.id
        });
        break;
      }
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const count: SimpleChange = changes.count;
    this.itemsCount = count.currentValue;
  }

  private createForm() {
    this.form = this.fb.group({
      limit: [this.limit],
      sort_by: ['posted_time_desc']
    });
  }

  changePageItemsCount() {
    localStorage.setItem('limit', this.form.get('limit').value);
    const params: InfobarEvent = {remove: [], add: [], limit: this.form.value.limit};
    this.sorting_filters.forEach(filter => {
      if (this.form.value.sort_by !== filter.id) {
        // delete queryParams[filter.id];
        params.remove.push(filter.id);
      } else {
        // queryParams[filter.id] = 1;
        params.add.push(filter.id);
      }
    });
    this.listingAndSortingParamsChange.emit(params);
  }

  changeToTable() {
    if (this.listed) {
      this.listed = false;
      localStorage.setItem('listed', 'false');
    }
  }

  changeToList() {
    if (!this.listed) {
      this.listed = true;
      localStorage.setItem('listed', 'true');
    }
  }
}
