import {AbstractControl} from '@angular/forms';
import {AddCarFromOtherWebsiteService} from '../../pages/profile/add-a-car/services/add-car-from-other-website.service';

const supportedWebsites = [
  'iaai.com',
  'copart.com',
  'auto.am',
  'list.am'
];

export function SupportedWebsitesToAddCarFromValidator(control: AbstractControl): { [key: string]: boolean } {
  if (!control.value) {
    return null;
  }

  const url = AddCarFromOtherWebsiteService.getHostnameFromUrl(control.value);

  for (let i = 0; i < supportedWebsites.length; i++) {
    if (supportedWebsites[i] === url) {
      return null;
    }
  }

  return {'unsupported-website': true};
}
