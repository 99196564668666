import {Injectable} from '@angular/core';
import {BaseHttpService} from './base-http-service';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CarGeneration} from '../../shared/models/car-generation.model';
import {Model} from '../../shared/models/model.model';
import {Brand} from '../../shared/models/brand.model';
import {AuthorizationService} from './authorization.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CarService extends BaseHttpService {
  private CAR = '/car/';
  private CAR_SERIES = '/car-series/';
  private BRAND = '/brand/';
  private MODEL = '/model/';
  private COLOR_URL = '/color/';
  private GENERAATION = '/car-generation/';
  private COUNTRIES = '/country/';
  private REGIONS = '/{country_id}/regions/';
  private CONTACT_US = '/core/contact-us/';
  private FAVOURITES_ADD = '/favorites/add_favorite/';
  private FAVOURITES = '/favorites/';

  constructor(private httpClient: HttpClient, private authService: AuthorizationService) {
    super(httpClient);
  }

  getCars<T>(query: any): Observable<HttpResponse<T>> {
    return this.get(`${this.CAR}?${query}`);
  }

  getCar<T>(id: any): Observable<HttpResponse<T>> {
    if (this.authService.isLoggedIn()) {
      let headers_object = new HttpHeaders();
      headers_object = headers_object.append('Authorization', 'Token ' + this.authService.getToken());
      return this.get(`${this.CAR}${id}/`, {observe: 'response', headers: headers_object});
    } else {
      return this.get(`${this.CAR}${id}/`);
    }
  }

  getCarSeries<T>(): Observable<HttpResponse<T>> {
    return this.get(`${this.CAR_SERIES}?limit=-1`)
      .pipe(map((response: HttpResponse<T>) => {
          (response.body as any).results = (response.body as any).results.sort((a, b) => a.name.localeCompare(b.name));
          return response;
        })
      );
  }

  getBrands<T>(): Observable<HttpResponse<T>> {
    return this.get(`${this.BRAND}?limit=-1`)
      .pipe(map((response: HttpResponse<T>) => {
        (response.body as any).results = (response.body as any).results.sort((a, b) => a.name.localeCompare(b.name));

        return response;
      }));
  }

  getColors<T>(): Observable<HttpResponse<T>> {
    return this.get(`${this.COLOR_URL}?limit=-1`)
      .pipe(map((response: HttpResponse<T>) => {
          (response.body as any).results = (response.body as any).results.sort((a, b) => a.name.localeCompare(b.name));
          return response;
        })
      );
  }

  getBrand<T>(id: Brand['id']): Observable<HttpResponse<T>> {
    return this.get(`${this.BRAND}${id}/`);
  }

  getModels<T>(): Observable<HttpResponse<T>> {
    return this.get(`${this.MODEL}`);
  }

  getModel<T>(id: Model['id']): Observable<HttpResponse<T>> {
    return this.get(`${this.MODEL}?brand=${id}&limit=-1`)
      .pipe(map((response: HttpResponse<T>) => {
        (response.body as any).results = (response.body as any).results.sort((a, b) => {
          if (Number(a.name) && Number(b.name)) {
            return a.name - b.name;
          }
          return a.name.localeCompare(b.name);
        });
        return response;
      }));
  }

  getGenerations<T>(id: CarGeneration['id']): Observable<HttpResponse<T>> {
    return this.get(`${this.GENERAATION}?model=${id}&limit=-1`);
  }

  getCountries<T>(): Observable<HttpResponse<T>> {
    return this.get(`${this.COUNTRIES}?limit=-1`)
      .pipe(
        map((response: HttpResponse<T>) => {
          (response.body as any).results = (response.body as any).results.sort((a, b) => a.name.localeCompare(b.name));
          return response;
        })
      );
  }

  getRegions<T>(id: any): Observable<HttpResponse<T>> {
    return this.get(`${this.REGIONS.replace('{country_id}', id)}?limit=-1`)
      .pipe(
        map((response: HttpResponse<T>) => {
          (response.body as any).results = (response.body as any).results.sort((a, b) => a.name.localeCompare(b.name));
          return response;
        })
      );
  }

  contactUs(body): Observable<HttpResponse<any>> {
    return this.post(this.CONTACT_US, body);
  }

  addToFavourites(body: { advert: any }) {
    let headers_object = new HttpHeaders();
    headers_object = headers_object.append('Authorization', 'Token ' + this.authService.getToken());
    return this.post(this.FAVOURITES_ADD, body, {observe: 'response', headers: headers_object});
  }

  removeFromFavourites(id: any) {
    let headers_object = new HttpHeaders();
    headers_object = headers_object.append('Authorization', 'Token ' + this.authService.getToken());
    return this.delete(`${this.FAVOURITES}${id}`, {headers: headers_object});
  }
}
