<ng-select #select
           [items]="items"
           [placeholder]="placehold"
           [bindLabel]="bindLabel"
           [bindValue]="bindValue"
           [loading]="loading"
           [virtualScroll]="virtualScroll"
           [disabled]="disabled"
           [notFoundText]="notFoundText | translate"
           [loadingText]="loadingText | translate"
           [typeToSearchText]="typeToSearchText | translate"
           [groupBy]="groupBy"
           [searchable]="false"
           [multiple]="multiple"
           [clearable]="clearable"
           [(ngModel)]="selectValue"
           (open)="onOpenHandler()"
           (change)="onChangeHandler()"
           (close)="onCloseHandler()"
           (clear)="onClear()">
  <ng-template ng-header-tmp>
    <input #searchInput *ngIf="searchable || items.length > 10" [placeholder]="typeToSearchText | translate" style="width: 100%; line-height: 24px" type="text" (input)="select.filter($event.target.value);" />
  </ng-template>
<!--  <ng-template ng-optgroup-tmp let-item="item" let-index="index" >
    <div *ngIf="index !==0">{{'-&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;'}}</div>
  </ng-template>-->
</ng-select>
