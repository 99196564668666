import {FormGroup} from '@angular/forms';

export function passwordMatchValidator(g: FormGroup): null | void {
  if (g.get('password').value === g.get('repeat_password').value &&
    g.get('password').value && !g.get('repeat_password').hasError('pattern')) {
    return g.get('repeat_password').setErrors(null);
  }

  if (!g.get('repeat_password').value) {
    return;
  }

  if (!g.get('repeat_password').errors) {
    g.get('repeat_password').setErrors({areEqual: true});
  }
}
