import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  // @ViewChild('toTop') toTop: ElementRef;
  public isFooterHidden = true;

  constructor(private router: Router) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isFooterHidden = false;
      });
  }

  ngOnInit() {
  }

  animate(elem: HTMLElement, style: string, unit: string, from: number, to: number, time: number, prop: boolean): void {
    if (elem) {
      const start = new Date().getTime(),
        timer = setInterval((): void => {
          const step = Math.min(1, (new Date().getTime() - start) / time);
          if (prop) {
            elem[style] = (from + step * (to - from)) + unit;
          } else {
            elem.style[style] = (from + step * (to - from)) + unit;
          }
          if (step === 1) {
            clearInterval(timer);
          }
        }, 5);
      if (prop) {
        elem[style] = from + unit;
      } else {
        elem.style[style] = from + unit;
      }
    }
  }

  // goToTop() {
  //   this.animate(document.documentElement, 'scrollTop', '', document.documentElement.scrollTop, document.body.offsetTop, 500, true);
  // }

  // @HostListener('window:scroll')
  // onWindowScroll() {
  //   const docScrollBottom = Math.floor(document.documentElement.scrollTop + window.innerHeight) === document.body.clientHeight;

  //   if (document.documentElement.scrollTop >= 600 || docScrollBottom) {
  //     this.toTop.nativeElement.style.display = 'block';
  //   } else {
  //     this.toTop.nativeElement.style.display = 'none';
  //   }
  // }

}
