import {Component, OnDestroy} from '@angular/core';
import {AuthForm} from '../authorization/auth-form';
import {signUpValidationMessages} from '../authorization/validation-helper-model';
import {FormBuilder, Validators} from '@angular/forms';
import {EmailOrPhoneNumberValidator, isEmailValid} from '../../shared/validators/email-or-phone-number-validator';
import {Subject} from 'rxjs';
import {AuthorizationService} from '../../core/services/authorization.service';
import {Router} from '@angular/router';
import {passwordMatchValidator} from '../../shared/validators/password-must-match-validator';
import {takeUntil} from 'rxjs/operators';
import {isPhoneNumberValid} from '../../shared/validators/phone-number-validator';
import { IErrMsgByBackend } from 'src/app/shared/models/error-message.interface';
import { backendErrorMessagesHandler } from 'src/app/shared/utils/utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent extends AuthForm implements OnDestroy {
  private $subscription = new Subject();

  public registrationFailed = false;
	public isSignInFailed = false;
	public errorsMsgByBackend: IErrMsgByBackend[] = [];

  constructor(
    private readonly fb: FormBuilder,
    public readonly authService: AuthorizationService,
    private readonly router: Router,
  ) {
    super(signUpValidationMessages, fb);
    this.controlsConfig = {
      email_or_phone: ['', {
        validators: Validators.compose([Validators.required, EmailOrPhoneNumberValidator('am')])
      }],
      password: ['', {
        validators: Validators.compose([Validators.required, Validators.minLength(6)])
      }],
      repeat_password: ['', {
        validators: Validators.compose([Validators.required, Validators.minLength(6)])
      }]
    };
    this.extras = {
      validator: [passwordMatchValidator],
      updateOn: 'submit'
    };
  }

  ngOnDestroy() {
    this.$subscription.next();
    this.$subscription.complete();
  }

  getQueryParams() {
    return {
      limit: localStorage.getItem('limit') || 50,
      offset: 0
    };
  }

  makeRequest(signUpFormData): void {
		this.errorsMsgByBackend = [];
    this.errorMessage = '';
    const payload = {
      password: signUpFormData.password,
      repeat_password: signUpFormData.repeat_password
    };
    if (isEmailValid(signUpFormData.email_or_phone)) {
      payload['email'] = signUpFormData.email_or_phone;
    } else if (isPhoneNumberValid(signUpFormData.email_or_phone, 'am')) {
      payload['phone'] = signUpFormData.email_or_phone;
    } else {
      this.form.get('email_or_phone').setErrors({'wrongEmailOrPhoneNumber': true});
      return;
    }
    this.authService.register(payload).pipe(takeUntil(this.$subscription)).subscribe(res => {
      this.isFormValid = true;
      // this.isSignUpSuccess = true;
      this.emailDomain = this.authService.getEmailDomain(this.form.get('email_or_phone').value);

      this.authService.login({
        username: payload['email'] || payload['phone'],
        password: payload.password,
      }).pipe(takeUntil(this.$subscription)).subscribe(response => {
        this.authService.saveTokenAndUserId(true, {...response});
        this.router.navigate(['/submit-a-vehicle']);
      }, error => {
        this.router.navigate(['/sign-in']);
        return;
      });
    }, error => {
      const code = error.error.code;
      if (code >= 500 || !code) {
        this.errorMessage = 'AUTH.ERROR';
        this.isFormValid = false;
        this.registrationFailed = true;
			}
			else {
				this.errorsMsgByBackend = backendErrorMessagesHandler(error);
			}

      this.isSignInFailed = false;
      this.registrationFailed = true;
    });
  }
}
