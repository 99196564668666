import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EmailPopupComponent } from './email-popup/email-popup.component';
import { ActivatedRoute } from '@angular/router';
import { Car } from '../../shared/models/car.model';
import { CarLocalItemsService } from '../../core/services/car-local-items.service';
import { descriptionInfo, extraFeaturesCategories, galleryOptions } from './detail.config';
import { Price } from '../../shared/models/Price.model';
import { Information } from '../../shared/models/information.model';
import { AuthorizationService } from '../../core/services/authorization.service';
import { CarService } from '../../core/services/car.service';
import { MyToastrService } from '../../shared/toastr/my-toastr.service';
import { NgxGalleryImage, NgxGalleryOptions } from 'ngx-gallery';
import { Subscription } from 'rxjs';
import { SeoService } from '../../core/services/seo.service';
import { urlFragment } from '../../shared/utils/utils';
import { IRouterDataModel } from '../../shared/models/router-data.model';
import { environment } from '../../../environments/environment';

declare let FB;

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html'
})
export class DetailViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public className = '';
  public showPageHeader = false;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  data: IRouterDataModel;
  car: Car;
  pricesInAntherCurrency: Array<{ currency: string; price: number }> = [];
  priceAndCurrency: any;
  extraFeaturesCategories = extraFeaturesCategories;
  descriptionInfoConfig = descriptionInfo;
  descriptionInfo = [];
  isFavouriteVisible = false;
  public isFavourite = false;
  isFavouritePending = false;
  extraFeatures: Array<any> = [];
  private $addToFavouritesSubscription: Subscription;
  private $deleteFromFavouritesSubscription: Subscription;
  private $routeSubscription: Subscription;
  public showFacebookBtn = false;

  constructor(private readonly route: ActivatedRoute,
    private carLocalItemsService: CarLocalItemsService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthorizationService,
    private toastr: MyToastrService,
    private carService: CarService,
    private dialog: MatDialog,
    private readonly seoService: SeoService,
  ) {
    // route.url.subscribe(() => {
    //   this.data = route.snapshot.data as IRouterDataModel;
		// });
  }

  ngOnInit() {
    this.className = this.route.snapshot.data.className;
    this.subscribeToRouterData();
    this.carLocalItemsService.$translated.subscribe(tr => {
      if (tr) {
        this.subscribeToRouterData();
      }
    });
    this.galleryOptions = galleryOptions;
    if (!environment.live) {
      this.showFacebookBtn = true;
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    if (this.$addToFavouritesSubscription) {
      this.$addToFavouritesSubscription.unsubscribe();
    }
    if (this.$deleteFromFavouritesSubscription) {
      this.$deleteFromFavouritesSubscription.unsubscribe();
    }
    if (this.$routeSubscription) {
      this.$routeSubscription.unsubscribe();
    }
    this.destroyMetaTags();
  }

  openEmailPopup() {
    this.dialog.open(EmailPopupComponent);
  }


  subscribeToRouterData() {
    this.$routeSubscription = this.activatedRoute.data.subscribe((data: any) => {
      this.showPageHeader = data.showPageHeader;

      if (data.car) {
        data.car.subscribe(res => {
          this.car = res.body;
          this.isFavourite = this.authService.isLoggedIn() ? this.car.favorite.favorite : false;
          this.isFavouriteVisible = this.isFavVisible();
          this.galleryImages = [];
          res.body.images.forEach(image => {
            this.galleryImages.push({
              small: image.thumbnail,
              medium: image.image,
              big: image.image
            });
          });
          this.initPriceByCurrency(this.car.price[0]);
          this.initDescriptionInfo();
          this.initExtraFeatures(this.car.information);
          this.addMetaTags();
        }).unsubscribe();
      }
    });
  }

  private addMetaTags(): void {
    const title =
      this.car.car_model.brand.name + ' ' + this.car.car_model.name +
      (this.car.generation && this.car.generation.name ? this.car.generation.name + ', ' : '') + this.car.production_date;
    const details = `${this.car.id}/${urlFragment(this.car)}`;
    this.seoService.updateTags({
      title: title,
      description: this.car.advert.additional_information ? this.car.advert.additional_information : title,
      details: details,
      image: this.galleryImages.length > 0 ? this.galleryImages[0].big.toString() : `/assets/images/logo/logo.png`,
      'og:image:width': '600',
      'og:image:height': '375',
    });
  }

  initPriceByCurrency(price: Price) {
    this.pricesInAntherCurrency = [];
    const keys = Object.keys(price);
    this.carLocalItemsService.currency.forEach((currency) => {
      const curr = keys.find(prKey => prKey.includes(currency.id));
      if (currency.id !== this.car.currency) {
        this.pricesInAntherCurrency.push({
          currency: currency.name,
          price: price[curr] // .toFixed(2)
        });
      } else {
        this.priceAndCurrency = {
          currency: currency.name,
          price: price[curr]
        };
      }
    });
  }

  initDescriptionInfo() {
    this.descriptionInfo = [];
    this.carLocalItemsService.brand = [{ name: this.car.car_model.brand.name, id: this.car.car_model.brand.name }];
		this.carLocalItemsService.model = [{ name: this.car.car_model.name, id: this.car.car_model.name }];
		// this.carLocalItemsService.country = [{name: this.car.advert}]
    this.carLocalItemsService.generation = this.car.generation && this.car.generation.name ? [{
      id: '',
      name: this.car.generation.name
    }] : [];
    this.carLocalItemsService.car_type = [this.car.car_type];
    this.carLocalItemsService.color = [this.car.color];
    this.car['brand'] = this.car.car_model.brand.name;
    this.car['model'] = this.car.car_model.name;
    this.descriptionInfoConfig.forEach((info, index) => {
      if (info.type === 'arrObj' && !!this.car[info.property]) {
        this.descriptionInfoConfig[index]['value'] = (this.carLocalItemsService[info.property] as Array<any>)
          .find(data => data.name === this.car[info.property].name).name;
        this.descriptionInfo.push(this.descriptionInfoConfig[index]);
      } else if (info.type === 'number' && !!this.car[info.property]) {
        if (Number(this.car[info.property]) % 1 !== 0 && !info.disableConversion) {
          this.descriptionInfoConfig[index]['value'] = Number(this.car[info.property]).toFixed(2);
        } else {
          this.descriptionInfoConfig[index]['value'] = Number.isInteger(this.car[info.property]) ? this.car[info.property] : this.car[info.property].toFixed(1);
        }
        this.descriptionInfo.push(this.descriptionInfoConfig[index]);
      } else if (info.property === 'is_crashed' && this.car[info.property] !== null) {
        this.descriptionInfoConfig[index]['value'] = 'CAR.CRASHED' + (this.car[info.property] ? '.YES' : '.NO');
        this.descriptionInfo.push(this.descriptionInfoConfig[index]);
      } else if (!!this.car[info.property] && info.type !== 'checkbox') {
        const tmp = (this.carLocalItemsService[info.property] as Array<any>)
          .find(data => data.id === this.car[info.property]);
        if (tmp) {
          this.descriptionInfoConfig[index]['value'] = tmp.name;
        }
        this.descriptionInfo.push(this.descriptionInfoConfig[index]);
      } else if (this.car.hasOwnProperty(info.property) && this.car[info.property] !== null) {

        // if ((info.property === 'custom_clearance' || info.property === 'guarantee') && this.car[info.property]) {
        if ((info.property === 'custom_clearance' || info.property === 'guarantee')) {
          if (info.property === 'guarantee' && !this.car[info.property]) {
            return;
          }
          this.descriptionInfoConfig[index]['value'] = this.car[info.property] ? 'CAR.CUSTOM_CLEARANCE.YES' : 'CAR.CUSTOM_CLEARANCE.NO';
          this.descriptionInfo.push(this.descriptionInfoConfig[index]);
        }
      } else if (info.property === 'exchange' && this.car.advert[info.property]) {
        this.descriptionInfoConfig[index]['value'] = 'ADD_CAR.EXCHANGE';
        this.descriptionInfo.push(this.descriptionInfoConfig[index]);
			}
			else if(info.property === 'country' || info.property === 'region') {
				const advert = this.car.advert;
				if(advert[info.property]) {
					this.descriptionInfoConfig[index]['value'] = advert[info.property].name;
					this.descriptionInfo.push(this.descriptionInfoConfig[index]);
				}
			}
    });
    // this.descriptionInfoConfig = [];
  }

  initExtraFeatures(information: Information) {
    let result = [];
    this.extraFeaturesCategories.forEach((category, index) => {
      result.push({
        label: category.label,
        fields: []
      });
      category.fields.forEach(feature => {
        if (feature.type === 'select' && information && information.hasOwnProperty(feature.property) && !!information[feature.property]) {
          if (Array.isArray(information[feature.property])) {
            if (information[feature.property].length <= 0) {
              return;
            }
            const tmp = [];
            information[feature.property].forEach(item => {
              tmp.push((this.carLocalItemsService[feature.property] as Array<any>).find(data => data.id === item).name);
            });

            result[index].fields.push({
              labels: tmp,
              head: feature.label
            });
          } else {
            result[index].fields.push({
              label: (this.carLocalItemsService[feature.property] as Array<any>)
                .find(data => data.id === information[feature.property]).name,
              head: feature.label
            });
          }
        } else if (
          information && information.hasOwnProperty(feature.property) && feature.type === 'checkbox' && !!information[feature.property]) {
          result[index].fields.push({
            label: feature.label
          });
        }
      });
    });
    result = result.filter((res => res.fields.length !== 0));
    this.extraFeatures = result;
  }

  chunkArray(array, chunkSize = 10) {
    const results = [];
    while (array.length) {
      results.push(array.splice(0, chunkSize));
    }
    return results;
  }

  toggleFavourite() {
    if (!this.isFavouritePending) {
      this.isFavouritePending = true;
      if (this.isFavourite) {
        this.$deleteFromFavouritesSubscription = this.carService.removeFromFavourites(this.car.favorite.favorite_id).subscribe(res => {
          this.isFavourite = false;
          this.isFavouritePending = false;
          this.toastr.success('TOASTS.FAVOURITE_DELETED', '');
				},
				error1 => this.toastr.error('SHARED.ERROR_MESSAGE', 'SHARED.ERROR_HEADER'));
      } else {
        this.$addToFavouritesSubscription = this.carService.addToFavourites({ advert: this.car.advert.id }).subscribe(res => {
          this.isFavourite = true;
          this.isFavouritePending = false;
          this.car.favorite.favorite_id = res.body.car.favorite.favorite_id;
          this.toastr.success('TOASTS.FAVOURITE_ADDED', '');
        }, error1 => this.toastr.error('SHARED.ERROR_MESSAGE', 'SHARED.ERROR_HEADER'));
      }
    }
  }

  isFavVisible(): boolean {
    if (this.authService.isLoggedIn()) {
      return +this.car.advert.user !== +this.authService.getUserId();
    } else {
      return false;
    }
  }

  get favLabel() {
    return this.isFavourite ? 'FAVOURITES.REMOVE_FROM_FAV' : 'FAVOURITES.ADD_TO_FAV';
  }

  private destroyMetaTags(): void {
    this.seoService.updateTags({
      title: '',
      description: '',
      details: '',
      image: '',
      'og:image:width': '',
      'og:image:height': '',
    });
    // this.meta.updateTag({property: 'og:type', content: ''});
    // this.meta.updateTag({property: 'og:url', content: ''});
    // this.meta.updateTag({property: 'og:title', content: ''});
    // this.meta.updateTag({property: 'og:description', content: ''});
    // this.meta.updateTag({property: 'og:image', content: ''});
    // this.meta.updateTag({property: 'og:image:width', content: ''});
    // this.meta.updateTag({property: 'og:image:height', content: ''});
  }

  public fbShareBtnClick(): void {
    FB.ui({
      method: 'share',
      href: `${environment.appUrl}detail/${this.car.id}/${urlFragment(this.car)}`,
    }, function (response) {
      // console.log('res:', response);
    });
  }
}
