<div class="info-box">
  <div class="box-header d-flex justify-between align-center">
    <p class="mb-0">{{infoBoxHeaderText | translate}}</p>
    <button (click)="closeInfoBox(false)" class="btn-link text-white">
      <i class="fa fa-remove"></i>
    </button>
  </div>
  <div class="box-container">
    <div *ngFor="let item of dataForRender; index as i" class="d-flex align-center justify-between mt-1">
      <!-- TODO: styles for 'a' tag below -->
      <p class="mb-0" *ngIf="item.routerLink"><span>{{i+1}}. </span><a  [routerLink]="item.routerLink || ['/']" class="mb-0 mr-1 title underline">  {{item.title || '' | titlecase}}</a></p>
      <p *ngIf="!item.routerLink" class="mb-0 mr-1 title"> <span>{{i+1}}. </span> {{item.title || '' | titlecase}}</p>
      <!--for error message -->
      <!--  <p class="mb-0 mr-1 error-message">Կապի խափանում</p>-->
      <div class="progress-icon">
        <!-- Start of the check icon for in progress state -->
          <!-- loader start-->
          <span *ngIf="item.status !== statuses.SUCCESS && item.status !== statuses.FAILURE" class="loader"></span>
          <!-- Loader end -->
        <svg
          *ngIf="item.status === statuses.FAILURE"
          class="progress-ring"
          width="20"
          height="20">
          <!-- For error state add "error" class to the "progress-ring-circle" -->
          <circle
            class="progress-ring-circle"
            stroke-width="2"
            fill="transparent"
            r="8"
            cx="10"
            cy="10"></circle>
        </svg>
        <!-- End of the check icon for in progress state -->
        <!-- Start of the check icon for completed state -->
        <svg *ngIf="item.status === statuses.SUCCESS"
             class="check-mark"
             width="20"
             height="20"
             viewBox="0 0 24 24"
             style=" fill:#000000;">
          <path fill="none" stroke="#2f904b" stroke-miterlimit="10" stroke-width="2" d="M21 6L9 18 4 13"></path>
        </svg>
        <!-- End of the check icon for completed state -->

        <svg *ngIf="item.status === statuses.FAILURE"
             class="check-mark"
             width="16"
             height="16"
             viewBox="0 0 24 24"
             style=" fill:#ff0c0c;">
          <line x1="1" y1="16"
                x2="16" y2="1"
                stroke="#ff0c0c"
                stroke-width="2"/>
          <line x1="1" y1="1"
                x2="16" y2="16"
                stroke="#ff0c0c"
                stroke-width="2"/>
        </svg>
      </div>
      <!---->
    </div>
  </div>
</div>
