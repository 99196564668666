import {Component, Input, OnInit} from '@angular/core';
import {CarLocalItemsService} from '../../../core/services/car-local-items.service';
import {TranslateService} from '@ngx-translate/core';
import {ResponseModel} from '../../../shared/models/response-model.model';
import {Car} from '../../../shared/models/car.model';
import {HttpResponse} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {getQueryParamsStr} from '../../profile/utils';
import {RouterLoaderService} from '../../../core/services/router-loader.service';
import {CarService} from '../../../core/services/car.service';
import {urlFragment} from '../../../shared/utils/utils';

@Component({
  selector: 'app-filtered-items, [app-filtered-items]',
  templateUrl: './filtered-items.component.html'
})
export class FilteredItemsComponent implements OnInit {
  @Input() listed: boolean;
  @Input() cars: HttpResponse<ResponseModel<Car>>;
  @Input() limit;
  page = 1;
  language: {[key: string]: any} = {};
  getUrlFragment: (car: Car) => string;

  constructor(private localItems: CarLocalItemsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private carService: CarService,
              private routerLoaderService: RouterLoaderService,
              private translateService: TranslateService) {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      this.limit = +queryParams['limit'] || +localStorage.getItem('limit') || 10;
      this.page = Math.ceil((+queryParams['offset'] || 0) / this.limit) + 1;
      this.routerLoaderService.$stopLoader.next();
    });
    this.translateService.get('CAR').subscribe(language => {
      this.language = language;
    });
    this.getUrlFragment = urlFragment;
  }

  get items() {
    return this.cars.body.results;
  }

  get count() {
    return this.cars.body.count;
  }

  ngOnInit(): void {
  }

  getQueryParams(event) {
    const queryParams = {...this.activatedRoute.snapshot.queryParams};
    queryParams['offset'] = (event - 1) * this.limit;
    return queryParams;
  }

  getCars(event) {
    this.routerLoaderService.$loader.next(true);
    const queryParams = this.getQueryParams(event);
    this.carService.getCars<ResponseModel<Car>>(getQueryParamsStr(queryParams)).subscribe(res => {
      this.cars = res;
      this.page = event;
      this.router.navigate(['/filter'], {queryParams: queryParams});
      this.routerLoaderService.$stopLoader.next();
      // goToTop();
    });
  }

  getCurrency(type): string {
    return this.localItems.currency.find(cr => cr.id === type).name;
  }

}
