import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Brand} from '../../../shared/models/brand.model';
import {Model} from '../../../shared/models/model.model';
import {CarGeneration} from '../../../shared/models/car-generation.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CarService} from '../../../core/services/car.service';
import {CarLocalItemsService} from '../../../core/services/car-local-items.service';
import {ResponseModel} from '../../../shared/models/response-model.model';
import {Router} from '@angular/router';
import {AdvancedSearchComponent} from './advanced-search/advanced-search.component';
import {VehicleTypeComponent} from './vehicle-type/vehicle-type.component';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html'
})
export class SearchSectionComponent implements OnInit, OnDestroy {
  isAdvancedSearchHidden = true;
  @ViewChild(VehicleTypeComponent) vehicleType: VehicleTypeComponent;
  @ViewChild(AdvancedSearchComponent) advancedSearch: AdvancedSearchComponent;
  form: FormGroup;
  /*  minValue = 0;
    maxValue = 10;
    options: Options = {
      floor: 0,
      ceil: 100,
      step: 1,
      noSwitching: true
    };*/
  dropDownsLoading = {
    modelIsLoading: false,
    generationIsLoading: false,
  };
  brands: Array<Brand> = [];
  models: Array<Model> = [];
  generations: Array<CarGeneration> = [];
  private var;

  constructor(private fb: FormBuilder,
              private carService: CarService,
              private router: Router,
              public carLocalItems: CarLocalItemsService) {
  }

  ngOnInit() {
    this.initCarBrands();
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      model: [null],
      brand: [null],
      generation: [null],
      production_date_range_min: [null],
      production_date_range_max: [null],
      price_range_min: [null],
      price_range_max: [null],
    }, {
      updateOn: 'submit'
    });
  }

  initCarBrands() {
    return new Promise((resolve, reject) => {
      this.carService.getBrands<ResponseModel<Brand>>().subscribe(res => {
        const popular = res.body.results.filter(brand => brand.popular);
        if (popular.length !== 0) {
          const all = (res.body.results as any).diff(popular);
          this.carLocalItems.brand = [...popular, {id: '-----', name: '', disabled: true}, ...all]; // name: '-----------------------'
        } else {
          this.carLocalItems.brand = res.body.results;
        }
        resolve(true);
      });
    });
  }

  onBrandSelect = () => {
    const brand = this.form.get('brand');
    const model = this.form.get('model');
    if (model.value || !brand.value) {
      model.setValue(null);
      this.form.get('generation').setValue(null);
      this.carLocalItems.model = [];
      this.carLocalItems.generation = [];
    }
    if (brand.value) {
      this.dropDownsLoading.modelIsLoading = true;
      this.carService.getModel<ResponseModel<Model>>(brand.value).subscribe(res => {
        this.carLocalItems.model = res.body.results;
        this.dropDownsLoading.modelIsLoading = false;
        SearchSectionComponent.autocomplete(this.models, model);
      });
    }
  }

  onModelSelect = () => {
    const selectedModel = this.form.get('model');
    const selectedGeneration = this.form.get('generation');
    if (!selectedModel.value || selectedGeneration.value) {
      selectedGeneration.setValue(null);
      this.carLocalItems.generation = [];
    }
    if (selectedModel.value) {
      this.dropDownsLoading.generationIsLoading = true;
      this.getGenerations(selectedModel.value, undefined);
    }
  }

  getGenerations(id, callbackFn) {
    this.carService.getGenerations<ResponseModel<CarGeneration>>(id).subscribe(res => {
      this.carLocalItems.generation = [...res.body.results];
      this.dropDownsLoading.generationIsLoading = false;
      if (callbackFn) {
        callbackFn();
      } else {
        SearchSectionComponent.autocomplete(this.generations, this.form.get('generation'));
      }
    });
  }

  private getQueryParams() {
    const params = {...this.vehicleType.form.value, ...this.form.value, ...this.advancedSearch.form.value};
    Object.keys(params).forEach(paramsKey => {
      if (typeof params[paramsKey] === 'boolean') {
        if (params[paramsKey]) {
          params[paramsKey] = 1;
        } else if (paramsKey === 'is_crashed') {
          params[paramsKey] = 0;
        } else {
          delete params[paramsKey];
        }
      }
    });
    params['limit'] = localStorage.getItem('limit') || 10;
    params['offset'] = 0;
    params['posted_time_desc'] = 1;
    return params;
  }

  search() {
    this.router.navigate(['/filter'], {queryParams: this.getQueryParams()});
  }

  ngOnDestroy(): void {
    this.carLocalItems.generation = [];
    this.carLocalItems.model = [];
    this.carLocalItems.country = [];
    this.carLocalItems.region = [];
    this.carLocalItems.color = [];
  }

  toggleAdvancedSearchSection() {
    this.isAdvancedSearchHidden = !this.isAdvancedSearchHidden;
  }

  get advancedSearchLabel() {
    return this.isAdvancedSearchHidden ? 'SEARCH.ADVANCED' : 'SEARCH.GENERAL';
  }

  static autocomplete(array, formControl, prop = 'id') {
    if (array.length === 1) {
      formControl.setValue(array[0][prop]);
    }
  }

}
