import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CarLocalItemsService} from '../../../core/services/car-local-items.service';
import {Car} from '../../../shared/models/car.model';
import {urlFragment} from '../../../shared/utils/utils';

@Component({
  selector: 'app-carousel-item',
  templateUrl: './carousel-item.component.html'
})
export class CarouselItemComponent implements OnInit, OnChanges {
  @Input() car: Car;
  @Input() enabled: boolean;
  public language: { [key: string]: any } = {};
  urlFragment: string;

  constructor(private translateService: TranslateService, private localItems: CarLocalItemsService) {
    this.translateService.get('CAR').subscribe(language => {
      this.language = language;
    }).unsubscribe();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof this.car !== 'number') {
      this.urlFragment = urlFragment(this.car);
    }
  }

  getCurrency(type): string {
    return type && this.localItems &&
    this.localItems.currency &&
    Array.isArray(this.localItems.currency) ? this.localItems.currency.find(cr => cr.id === type).name : '-';
  }

}
