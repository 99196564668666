import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPaginationModule} from 'ngx-pagination';
import {SelectComponent} from './select/select.component';
import {PaginationComponent} from './pagination/pagination.component';
import {MyTranslateChildModule} from '../modules/my-translate-child.module';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxPaginationModule,
    MyTranslateChildModule,
  ],
  declarations: [
    SelectComponent,
    PaginationComponent
  ],
  exports: [
    NgSelectModule,
    NgxPaginationModule,
    PaginationComponent,
    SelectComponent
  ]
})
export class UiModule { }
