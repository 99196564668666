import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

// abstract classes
import { BaseHttpService } from 'src/app/core/services/base-http-service';

@Injectable()
export class RelatedCarRequestService extends BaseHttpService {

  constructor(
    http: HttpClient,
  ) { super(http); }

  getCars<T>(): Observable<HttpResponse<T>> {
    return this.get<T>('/related-car?limit=8');
  }

}