<div class="container">
  <div class="row">
    <div class="col-lg-12 col-xs-12">
      <div class="b-infoBar__select">
        <form [formGroup]="form" class="info-form d-flex justify-between align-center">
          <!--TODO add class 'b-pageHeader__search' to div for display search results count-->
          <div  class="">
            <h3 [hidden]="true"> {{"SEARCH.SEARCH_REULT_COUNT" | translate:{itemsCount: itemsCount} }}</h3>
          </div>
          <div class="b-infoBar__options d-flex">
            <div class="b-infoBar__select d-flex align-center mr-3">
              <span class="b-infoBar__select-one-title">{{"SEARCH.SELECT_VIEW" | translate}}</span>
              <div class="b-infoBar__select-one d-flex align-center">
                <!--TODO add class -> 'm-active' to m-list or m--table to set active -->
                <a (click)="changeToList()" class="m-list" [ngClass]="{'m-active': listed}"><span class="fa fa-list"></span></a>
                <a (click)="changeToTable()" class="m-table" [ngClass]="{'m-active': !listed}"><span class="fa fa-table"></span></a>
              </div>
            </div>
            <div class="b-infoBar__select d-flex align-center mr-3">
              <span class="b-infoBar__select-one-title">{{"FILTER.SHOW_ON_PAGE" | translate}}</span>
              <div class="b-infoBar__select-one">
                <select (change)="changePageItemsCount()" formControlName="limit" name="select1" class="m-select">
                  <option value="10" selected="">{{"FILTER.ITEM" | translate:{count: 10} }}</option>
                  <option value="25" selected="">{{"FILTER.ITEM" | translate:{count: 25} }}</option>
                  <option value="50" selected="">{{"FILTER.ITEM" | translate:{count: 50} }}</option>
                  <option value="75" selected="">{{"FILTER.ITEM" | translate:{count: 75} }}</option>
                  <option value="100" selected="">{{"FILTER.ITEM" | translate:{count: 100} }}</option>
                </select>
                <span class="fa fa-caret-down"></span>
              </div>
            </div>
            <div class="b-infoBar__select d-flex align-center">
              <span class="b-infoBar__select-one-title">{{"SEARCH.SORT_BY" | translate}}</span>
              <div class="b-infoBar__select-one">
                <select (change)="changePageItemsCount()" formControlName="sort_by" name="select2" class="m-select sort-select">
                  <option *ngFor="let sortFilter of sorting_filters" [value]="sortFilter.id" selected="">{{sortFilter.name | translate}}</option>
                </select>
                <span class="fa fa-caret-down"></span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
