<div class="b-items__cars" *ngIf="listed">
  <div class="b-items__cars-one" *ngFor="let car of items | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count }">
    <div [routerLink]="['/filter/detail', car.id, getUrlFragment(car)]" class="b-items__cars-one-img">
      <img [src]="car.images[0] ? car.images[0].thumbnail : 'assets/media/237x202/default.png'" alt=""/>
      <!--<span class="b-items__cars-one-img-type m-premium">PREMIUM</span>-->
      <!--<span class="b-items__cars-one-img-type m-leasing">LEASING AVAILABLE</span>-->
      <!--<span class="b-items__cars-one-img-type m-listing">NEW LISTING</span>-->
      <!--Or without span-->
    </div>
    <div class="b-items__cars-one-info">
      <div class="b-items__cars-one-info-header s-lineDownLeft">
        <a [routerLink]="['/filter/detail', car.id, getUrlFragment(car)]"><h2>{{car?.car_model?.brand.name}} {{car?.car_model?.name}} {{car?.generation && car.generation.name ? car.generation.name + ' , ' : ''}} {{car?.production_date}}</h2></a>

      </div>
      <div class="row s-noRightMargin">
        <div class="col-xs-12">
          <!--<p>In a pickup market gone fancy, the Silverado sticks to its basic-truck recipe. The steering is accurate, and the Silverado</p>-->
          <div class="m-width row m-smallPadding">
            <div class="b-items__cars-one-info-price d-flex align-center">
              <label>{{'ADD_CAR.PRICE' | translate}}:</label>
              <h4>{{getCurrency(car.advert.currency) | translate}} {{car.advert.price}}</h4>
            </div>
            <div class="col-lg-6">
              <ul class="no-bullet no-padding detail-list">
                <li *ngIf="car.car_type?.name">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.BODY_TYPE' | translate}}: </span>
                  <span class="b-items__cars-one-info-value">{{car?.car_type?.name | emptyFormat}}</span>
                </li>
                <li *ngIf="car.kilometer">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.RUNS' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{car.kilometer}} {{'CAR.KM' | translate}}</span>
                </li>
                <li *ngIf="car.advert?.region?.name || car.advert?.country?.name">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.LOCATION' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{(car.advert?.country?.name || '') + (car.advert?.country?.name && car.advert?.region?.name ? ',' : '')}} {{car.advert?.region?.name || ''}}</span>
                </li>
                <li>
                  <span class="b-items__cars-one-info-title">{{'CAR.CUSTOM_CLEARANCE.LABEL' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{(car.custom_clearance ? 'CAR.CUSTOM_CLEARANCE.YES' : 'CAR.CUSTOM_CLEARANCE.NO') | translate }}</span>
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul class="no-bullet no-padding detail-list">
                <li *ngIf="car.drive_type">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.DRIVE_TYPE' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{language.DRIVE_TYPE[(car.drive_type).toUpperCase()]}}</span>
                </li>
                <li *ngIf="car.color?.name">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.COLOR' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{car?.color?.name | emptyFormat}}</span>
                </li>
                <li *ngIf="car.transmission">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.TRANSMISSION' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{language.TRANSMISSION[(car.transmission).toUpperCase()]}}</span>
                </li>
                <li *ngIf="car.cubic_capacity">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.CUBIC_CAPACITY' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{car.cubic_capacity?.toFixed(1) | emptyFormat}} <span *ngIf="car.cubic_capacity">{{'ADD_CAR.LITER' | translate}}</span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-xs-12">
          <div class="b-items__cars-one-info-price">
            <!--<div class="pull-right">
              <h3>Price:</h3>
              <h4>$29,415</h4>
            </div>-->
            <a [routerLink]="['/filter/detail', car.id, getUrlFragment(car)]" class="btn m-btn">{{"CAR_DETAIL.DETAILS" | translate}}<span class="fa fa-angle-right"></span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row m-border" *ngIf="!listed">
  <div class="col-lg-4 col-md-6 col-xs-12 list-view" *ngFor="let car of items | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count }">
    <div class="b-items__cell">
      <div [routerLink]="['/filter/detail', car.id,  getUrlFragment(car)]" class="b-items__cars-one-img">
        <img class='img-responsive' [src]="car.images[0] ? car.images[0].thumbnail : 'assets/media/237x202/default.png'" alt='chevrolet'/>
        <!--<span class="b-items__cars-one-img-type m-premium">PREMIUM</span>-->
        <!--<span class="b-items__cars-one-img-type m-listing">NE LISTING</span>-->
      </div>
      <div class="b-items__cell-info">
        <div class="s-lineDownLeft b-items__cell-info-title">
          <h2 class=""><a [routerLink]="['/filter/detail', car.id, getUrlFragment(car)]">{{car.car_model?.brand.name}} {{car.car_model?.name}} {{car.generation && car.generation.name ? car.generation.name : '' }} , {{car.production_date}}</a></h2>
        </div>
        <!--<p>Lorem ipsum dolor sit amet consec let radipisicing elit, sed do eiusmod  ...</p>-->
        <div>
          <div class="row m-smallPadding">
            <div class="col-xs-12">
              <ul class="no-bullet no-padding detail-list">
                <li *ngIf="car.car_type?.name">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.BODY_TYPE' | translate}}: </span>
                  <span class="b-items__cars-one-info-value">{{car.car_type?.name}}</span>
                </li>
                <li *ngIf="car.kilometer">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.RUNS' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{car.kilometer}} {{'CAR.KM' | translate}}</span>
                </li>
                <li *ngIf="car.advert?.country?.name || car.advert?.region?.name">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.LOCATION' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{(car.advert?.country?.name || '') + (car.advert?.country?.name && car.advert?.region?.name ? ',' : '')}} {{car.advert?.region?.name || ''}}</span>
                </li>
                <li>
                  <span class="b-items__cars-one-info-title">{{'CAR.CUSTOM_CLEARANCE.LABEL' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{(car.custom_clearance ? 'CAR.CUSTOM_CLEARANCE.YES' : 'CAR.CUSTOM_CLEARANCE.NO') | translate }}</span>
                </li>
              </ul>
            </div>
            <div class="col-xs-12">
              <ul class="no-bullet no-padding detail-list">
                <li *ngIf="car.drive_type">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.DRIVE_TYPE' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{language.DRIVE_TYPE[(car.drive_type).toUpperCase()]}}</span>
                </li>
                <li *ngIf="car.color?.name">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.COLOR' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{car.color?.name}}</span>
                </li>
                <li *ngIf="car.transmission">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.TRANSMISSION' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{language.TRANSMISSION[(car.transmission).toUpperCase()]}}</span>
                </li>
                <li *ngIf="car.cubic_capacity">
                  <span class="b-items__cars-one-info-title">{{'ADD_CAR.CUBIC_CAPACITY' | translate}}:</span>
                  <span class="b-items__cars-one-info-value">{{car.cubic_capacity?.toFixed(1) | emptyFormat}} <span *ngIf="car.cubic_capacity">{{'ADD_CAR.LITER' | translate}}</span></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <h5 class="b-items__cell-info-price"><span>{{'ADD_CAR.PRICE' | translate}}:</span>{{getCurrency(car.advert?.currency) | translate}} {{car.advert?.price}}</h5>
        <a [routerLink]="['/filter/detail', car.id, getUrlFragment(car)]" class="btn m-btn">{{"CAR_DETAIL.DETAILS" | translate}}<span class="fa fa-angle-right"></span></a>
      </div>
    </div>
  </div>
</div>
<!--no items-->
<p class="no-data-msg" *ngIf="count === 0">{{'USER.NO_ADDED_CARS' | translate}}</p>
<!--no items-->

<div *ngIf="count !== 0" class="b-items__pagination" app-pagination (pageChange)="getCars($event)"></div>
