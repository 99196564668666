<div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
  <div class="col-9">
    <div *ngIf="title" [ngClass]="options.titleClass" [attr.aria-label]="title">
      {{ title | translate }}
    </div>
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
         [ngClass]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
         [ngClass]="options.messageClass" [attr.aria-label]="message">
      {{ message | translate }}
    </div>
  </div>
  <div class="col-3 text-right">
    <!--<a *ngIf="!options.closeButton" class="btn btn-pink btn-sm" (click)="action($event)">
      {{ undoString }}
    </a>-->
<!--    <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-pink btn-sm">
      {{'SHARED.CLOSE' | translate}}
    </a>-->
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>
