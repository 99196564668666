<div class="row">
  <div class="modal-dialog">
    <div class="col-lg-12 no-padding">
      <div class="b-password__form modal-content col-lg-12">
        <div class="b-contacts__form-header s-lineDownLeft">
          <button type="button" (click)="closeDialog()" class="close no-outline"><span aria-hidden="true">&times;</span></button>
          <h2 class="s-titleDet">{{"OTHER.WRITE_TO_SELLER" | translate}}</h2>
        </div>
        <form class="s-noPadding s-form">
          <div class="text-field">
            <textarea [placeholder]="'HOME.MESSAGE' | translate"></textarea>
          </div>
          <div class="s-submit d-flex align-center">
            <input formControlName="rememberMe" type="checkbox" name="check123" id="check123">
            <label class="s-submitCheckLabel" for="check123"><span class="fa fa-check"></span></label>
            <label class="s-submitCheck" for="check123">{{"OTHER.SEND_ME_A_COPY_OF_MY_EMAIL_IN_THE_MAIL" | translate}}</label>
          </div>
          <button class="btn m-btn" type="submit">{{"AUTH.SEND" | translate}}<span class="fa fa-angle-right"></span></button>
        </form>
      </div>
    </div>
  </div>
</div>
