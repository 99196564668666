import {Injectable} from '@angular/core';
import {Model} from '../../../shared/models/model.model';
import {CarGeneration} from '../../../shared/models/car-generation.model';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {

  public brands = new Set<number>();
  public models = new Map<number, Model>();
  public generation = new Map<number, CarGeneration>();

  public deleteGenerationsForUncheckedBrand(brandID: number): void {
    this.generation.forEach(gen => {
      if (gen.model && gen.model.brand && +gen.model.brand.id === +brandID) {
        this.generation.delete(+gen.id);
      }
    });
  }

  public deleteGenerationsForUncheckedModel(modelID: number): void {
    this.generation.forEach(gen => {
      if (gen.model && +gen.model.id === +modelID) {
        this.generation.delete(+gen.id);
      }
    });
  }

  public deleteModelsForUncheckedBrand(brandID: number): void {
    this.models.forEach(model => {
      if (model && model.brand && +model.brand.id === +brandID) {
        this.models.delete(+model.id);
      }
    });
  }

  public reset(): void {
    this.brands.clear();
    this.models.clear();
    this.generation.clear();
  }
}
