<div class="m-index" data-scrolling-animations="true" data-equal-height=".b-auto__main-item">

  <app-search-section></app-search-section>

  <section class="b-featured">
    <div class="container">
      <h2 class="s-title">{{"OTHER.TOP_ADS" | translate}}</h2>
      <app-top-vehicles [topParam]="true"></app-top-vehicles>
    </div>
  </section>

  <!--
    <section class="b-featured">
      <div class="container">
        <h2 class="s-title">Urgent Sale</h2>
        <app-urgent-sale></app-urgent-sale>
      </div>
    </section>
  -->

  <section class="b-asks">
    <div class="container">
      <div class="row">
        <div routerLink="/filter" [queryParams]="getQueryParams()"
             class="col-md-6 col-sm-10 col-sm-offset-1 col-md-offset-0 col-xs-12" style="outline: none;">
          <div class="b-asks__first mb-5">
            <div class="b-asks__first-circle">
              <span class="fa fa-search"></span>
            </div>
            <div class="b-asks__first-info">
              <h2>{{"HOME.ARE_YOU_LOOKING_FOR_A_CAR" | translate}}</h2>
            </div>
            <div class="b-asks__first-arrow">
              <a><span class="fa fa-angle-right"></span></a>
            </div>
          </div>
          <div class="mb-3" style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://www.youtube.com/embed/_Ed5bFOsrv8?color=white"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="fullscreen"  allow="autoplay"
                    allowfullscreen></iframe>
          </div>
        </div>
        <div routerLink="/submit-a-vehicle" class="col-md-6 col-sm-10 col-sm-offset-1 col-xs-12 col-md-offset-0"
             style="outline: none">
          <div class="b-asks__first m-second mb-5">
            <div class="b-asks__first-circle">
              <span class="fa fa-usd"></span>
            </div>
            <div class="b-asks__first-info">
              <h2>{{"HOME.SELL_A_CAR" | translate}}</h2>
            </div>
            <div class="b-asks__first-arrow">
              <a><span class="fa fa-angle-right"></span></a>
            </div>
          </div>
          <div class="mb-3" style="padding:56.25% 0 0 0;position:relative;">
            <iframe src="https://www.youtube.com/embed/ur0MYIFAJX0?color=white"
                    style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" autoplay="false" allow="fullscreen"
                    allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </section><!--b-asks-->

  <!-- Reg form section start -->
  <section *ngIf="!authService.isLoggedIn()" class="mt-5 mb-5">
    <div class="container home-reg">
      <div class="b-contacts__form-header s-lineDownLeft">
        <h2 class="s-titleDet color-primary">{{'HOME.SIGNUP_SECTION_HEADER' | translate}}</h2>
      </div>
      <form [formGroup]="form" (ngSubmit)="submitForm(form.value)" class="s-form s-submit">
        <div class="row">
          <div class="col-xs-12 col-sm-4 col-md-4">
            <div class="text-field">
              <input
                formControlName="email_or_phone"
                appLetterOnly [isEmailField]="true"
                type="text" [placeholder]="'AUTH.EMAIL_OR_PHONE' | translate"
                name="email_or_phone" id="user-email-or-phone">
              <ng-container *ngFor="let error of validationMessages.email_or_phone">
                <p class="error-message"
                   *ngIf="form.get('email_or_phone').hasError(error.type) && form.get('email_or_phone').touched && form.get('email_or_phone').dirty || form.get('email_or_phone').pending">{{error.message | translate}}</p>
							</ng-container>
							<ng-container *ngFor="let err of errorsMsgByBackend">
								<p class="error-message" *ngIf="err.field === 'email_or_phone'">{{err.error}}</p>
							</ng-container>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4">
            <div class="text-field">
              <input
                formControlName="password"
                type="password" [placeholder]="'AUTH.PASSWORD' | translate"
                name="password" id="user-password">
              <ng-container *ngFor="let error of validationMessages.password">
                <p class="error-message"
                   *ngIf="form.get('password').hasError(error.type) && form.get('password').touched && form.get('password').dirty || form.get('password').pending">{{error.message | translate}}</p>
							</ng-container>
							<ng-container *ngFor="let err of errorsMsgByBackend">
								<p class="error-message" *ngIf="err.field === 'password'">{{err.error}}</p>
							</ng-container>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-4">
            <div class="text-field">
              <input
                formControlName="repeat_password"
                type="password"
                [placeholder]="'AUTH.CONFIRM_PASSWORD' | translate" name="confirm-password"
                id="confirm-password">
              <ng-container *ngFor="let error of validationMessages.confirm_password">
                <p class="error-message"
                   *ngIf="form.get('repeat_password').hasError(error.type) && form.get('repeat_password').touched && form.get('repeat_password').dirty || form.get('repeat_password').pending">{{error.message | translate}}</p>
							</ng-container>
							<ng-container *ngFor="let err of errorsMsgByBackend">
								<p class="error-message" *ngIf="err.field === 'repeat_password'">{{err.error}}</p>
							</ng-container>
            </div>
          </div>
        </div>
        <div class="b-submit__main-element mt-4">
          <label [innerHTML]="'TERM.TERM_13' | translate"></label>
        </div>

        <p class="error-message" *ngIf="registrationFailed || isSignInFailed">{{errorMessage | translate}}</p>

				<ng-container *ngFor="let err of errorsMsgByBackend">
					<p class="error-message" *ngIf="err.field !== 'email_or_phone' && err.field !== 'password' && err.field !== 'repeat_password'">{{err.error}}</p>
				</ng-container>

        <div class="row form-links form-align">
          <div class="col-lg-12 text-right">
            <button type="submit" class="btn m-btn">{{'AUTH.SIGN_UP' | translate}}<span
              class="fa fa-angle-right"></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
  <!-- Reg and reg form section start -->

  <section class="b-featured">
    <div class="container">
      <h2 class="s-title">{{"OTHER.LAST_ADDED" | translate}}</h2>
      <app-top-vehicles></app-top-vehicles>
    </div>
  </section>

  <!-- Download app section start -->

  <section class="download-app">
    <div class="container">
      <div class="download-content">
        <h3>{{"HOME.MULTIPLATFORM_SYSTEM" | translate}}</h3>
        <p class="sect-info">{{"HOME.CHOOSE_YOUR_PLATFORM" | translate}}</p>
        <ul class="download">
          <li>
            <a href="https://itunes.apple.com/us/app/gear-am/id1460577458?ls=1&mt=8">
              <img src="../../../assets/images/backgrounds/apple.png" alt="Apple link">
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=am.gear.app">
              <img src="../../../assets/images/backgrounds/google.png" alt="Google link">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>
