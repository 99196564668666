import {Component, OnDestroy, OnInit} from '@angular/core';
import {CarService} from '../../../../core/services/car.service';
import {ResponseModel} from '../../../../shared/models/response-model.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-vehicle-type',
  templateUrl: './vehicle-type.component.html'
})
export class VehicleTypeComponent implements OnInit, OnDestroy {
  carSeries: Array<{ id: number, name: string, image: string }> = [];
  form: FormGroup;
  private $carSeriesSubscription: Subscription;

  constructor(private carService: CarService, private fb: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      car_type: [null]
    });
    this.$carSeriesSubscription = this.carService.
                                     getCarSeries<ResponseModel<{ id: number, name: string, image: string }>>().subscribe(res => {
      this.carSeries = res.body.results;
    });
  }

  expandVehicleTypes() {
    const vehicleTypes = document.getElementById('vehicle-types');
    vehicleTypes.classList.toggle('expand');
  }

  ngOnDestroy(): void {
    this.$carSeriesSubscription.unsubscribe();
    this.form.reset();
  }

}
