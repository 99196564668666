import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

// models
import {
  ADD_CAR_STATUSES,
  AddCarFromOtherWebsiteModel
} from '../../../profile/add-a-car/models/add-car-from-other-website-payload.model';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html'
})
export class InfoBoxComponent {

  @Output() public closeEvent = new EventEmitter<void>();
  @Input() public completedCount = 0;
  @Input() public pendingCount = 0;
  @Input() public dataForRender: AddCarFromOtherWebsiteModel[] = [];

  public readonly statuses = ADD_CAR_STATUSES;

  constructor(
    private readonly translate: TranslateService
  ) {
  }

	// instant is sync after lang change it not retranslate 
  public get infoBoxHeaderText(): string {
    if (this.pendingCount > 0) {
      return this.pendingCount === 1 ? 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.IN_PROGRESS_SINGLE'
        : this.pendingCount + ' ' + this.translate.instant('ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.IN_PROGRESS_MULTIPLE');
    } else if (this.completedCount > 0) {
      return this.completedCount === 1 ? 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.SUCCESS_SINGLE'
        : 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.SUCCESS_MULTIPLE';
    } else if (this.dataForRender.length > 0) {
      return 'ADD_CAR.FROM_OTHER_SITE.STATUS_INDICATOR.FAILURE';
    }
  }

  public closeInfoBox(minimize: boolean): void {
    if (minimize) {
      // console.log('Minimize info bar');
      return;
    }

    this.dataForRender = [];
    this.closeEvent.emit();
  }
}
