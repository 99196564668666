import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {navBarCollapse} from '../../../../shared/animations/animations';
import {checkboxes, extraFeatures} from '../../../detail-view/detail.config';
import {CarLocalItemsService} from '../../../../core/services/car-local-items.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Color} from '../../../../shared/models/color.model';
import {Country, Region} from '../../../../shared/models/region.model';
import {ResponseModel} from '../../../../shared/models/response-model.model';
import {CarService} from '../../../../core/services/car.service';
import {Model} from '../../../../shared/models/model.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  animations: navBarCollapse
})
export class AdvancedSearchComponent implements OnInit, OnDestroy {
  langObj = Object.create(null);
  @Input() collapsed: boolean;
  form: FormGroup;
  checkboxes;
  extraSelects;
  car_type: Array<{ id: number, name: string }> = [];
  colors: Array<Color> = [];
  countries: Array<Region> = [];
  regions: Array<Country> = [];
  isRegionsLoading = false;

  constructor(public carLocalItemsService: CarLocalItemsService,
              private fb: FormBuilder,
              private carService: CarService,
              public translateService: TranslateService
  ) {
    this.checkboxes = checkboxes;
    this.extraSelects = extraFeatures;
  }

  ngOnInit() {
    this.createForm();
    this.initColors();
    this.initCarTypes();
    this.initCountries();
  }

  createForm() {
    this.form = this.fb.group({
      created: [null],
      owner: [null],
      kilometer_range_min: [null],
      kilometer_range_max: [null],
      posted_time_desc: [null],
      price_asc: [null],
      price_desc: [null],
      production_date_asc: [null],
      production_date_desc: [null],
      kilometer_desc: [null],
      cubic_min: [null],
      cubic_max: [null],
      power_min: [null],
      power_max: [null],
      region: [null],
      country: [null],
      usage: [null],
      drive_wheel_type: [null],
      fuel_type: [null],
      color: [null],
      transmission: [null],
      is_crashed: [null],
      drive_type: [null],
      guarantee: [null],
      exchange: [null],
      custom_clearance: [null],
      owners_count: [null],
      interior_type: [null],
      interior_flavor: [null],
      climate_control: [null],
      window_regulator: [null],
      driver_seat_regulator: [null],
      passenger_seat_regulator: [null],
      seat_count: [null],
      helm_regulator: [null],
      airbag: [null],
      sunroof: [false],
      tined_windows: [false],
      xenon_headlights: [false],
      alloy_wheels: [false],
      board_computer: [false],
      gas_cylinder_equipment: [false],
      rain_sensor: [false],
      cruise_control: [false],
      navigation: [false],
      heated_mirrors: [false],
      headlight_washer: [false],
      central_lock: [false],
      security_alarm: [false],
      heated_helm: [false],
      heated_seal: [false],
      electric_mirrors: [false],
      abs: [false],
      anti_sleep: [false],
      esp: [false],
      parktronic: [false],
      cd: [false],
      tv: [false],
      posted_time: [null],
    }, {
      updateOn: 'submit'
    });
  }

  initColors() {
    return new Promise((resolve, reject) => {
      this.carService.getColors<ResponseModel<Color>>().subscribe(res => {
        this.colors = [...res.body.results];
        resolve(true);
      });
    });
  }

  initCarTypes() {
    return new Promise((resolve, reject) => {
      this.carService.getCarSeries<ResponseModel<{ id: number, name: string, image: string }>>().subscribe(res => {
        this.car_type = [...res.body.results];
        resolve(true);
      });
    });
  }

  initCountries() {
    this.carService.getCountries<ResponseModel<Region>>().subscribe(res => {
      this.countries = [...res.body.results];
    });
  }

  getRegions = async (value = null) => {
    const country = this.form.get('country');
    const region = this.form.get('region');
    if (region.value) {
      region.setValue(null);
      this.regions = [];
    }
    if (country.value) {
      this.isRegionsLoading = true;
      this.carService.getRegions<ResponseModel<Model>>(country.value).subscribe(res => {
        this.regions = [...res.body.results];
        if (value) {
          region.setValue(value);
        } else {
          this.autocomplete(this.regions, region);
        }
        this.isRegionsLoading = false;
      });
    } else {
      this.form.get('region').setValue(null);
      this.regions = [];
    }
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  autocomplete(array, formControl, prop = 'id') {
    if (array.length === 1) {
      formControl.setValue(array[0][prop]);
    }
  }
}
