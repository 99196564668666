import { NgxGalleryAnimation } from 'ngx-gallery';

export const checkboxes: Array<{ property: string, type: string, label: string }> = [
	{ property: 'sunroof', type: 'checkbox', label: 'ADD_CAR.SUNROOF' },
	{ property: 'tined_windows', type: 'checkbox', label: 'ADD_CAR.TINED_WINDOW' },
	{ property: 'xenon_headlights', type: 'checkbox', label: 'ADD_CAR.XENON_HEADLIGHTS' },
	{ property: 'alloy_wheels', type: 'checkbox', label: 'ADD_CAR.ALLOY_WHEELS' },
	{ property: 'board_computer', type: 'checkbox', label: 'ADD_CAR.BOARD_COMPUTER' },
	{ property: 'gas_cylinder_equipment', type: 'checkbox', label: 'ADD_CAR.GAS_CYLINDER_EQUIPMENT' },
	{ property: 'rain_sensor', type: 'checkbox', label: 'ADD_CAR.RAIN_SENSOR' },
	{ property: 'cruise_control', type: 'checkbox', label: 'ADD_CAR.CRUISE_CONTROL' },
	{ property: 'navigation', type: 'checkbox', label: 'ADD_CAR.NAVIGATION' },
	{ property: 'heated_mirrors', type: 'checkbox', label: 'ADD_CAR.HEATED_MIRRORS' },
	{ property: 'headlight_washer', type: 'checkbox', label: 'ADD_CAR.HEADLIGHT_WASHER' },
	{ property: 'central_lock', type: 'checkbox', label: 'ADD_CAR.CENTRAL_LOCK' },
	{ property: 'security_alarm', type: 'checkbox', label: 'ADD_CAR.SECURITY_ALARM' },
	{ property: 'heated_helm', type: 'checkbox', label: 'ADD_CAR.HEATED_HELM' },
	{ property: 'heated_seal', type: 'checkbox', label: 'ADD_CAR.HEATED_SEAL' },
	{ property: 'electric_mirrors', type: 'checkbox', label: 'ADD_CAR.ELECTRIC_MIRRORS' },
	{ property: 'abs', type: 'checkbox', label: 'ADD_CAR.ABS' },
	{ property: 'anti_sleep', type: 'checkbox', label: 'ADD_CAR.ANTI_SLEEP' },
	{ property: 'esp', type: 'checkbox', label: 'ADD_CAR.ESP' },
	{ property: 'parktronic', type: 'checkbox', label: 'ADD_CAR.PARKTRONIC' },
	{ property: 'cd', type: 'checkbox', label: 'ADD_CAR.CD' },
	{ property: 'tv', type: 'checkbox', label: 'ADD_CAR.TV' },
];

export const extraFeatures: Array<{ property: string, type: string, label: string }> = [
	{ property: 'interior_type', type: 'select', label: 'ADD_CAR.SALON' },
	{ property: 'interior_flavor', type: 'select', label: 'ADD_CAR.SALON_COLOR' },
	{ property: 'climate_control', type: 'select', label: 'ADD_CAR.CLIMATE_CONTROL' },
	{ property: 'window_regulator', type: 'select', label: 'ADD_CAR.GLASS_CONTROL' },
	{ property: 'driver_seat_regulator', type: 'select', label: 'ADD_CAR.DRIVER_SEAUPLOADT_CONFIG' },
	{ property: 'passenger_seat_regulator', type: 'select', label: 'ADD_CAR.PASSENGER_SEAT_CONFIG' },
	{ property: 'seat_count', type: 'select', label: 'ADD_CAR.SEAT_NUMBER' },
	{ property: 'helm_regulator', type: 'select', label: 'ADD_CAR.DRIVE_CONFIG' },
	{ property: 'airbag', type: 'select', label: 'ADD_CAR.SAFETY_PADS' }
];

export const descriptionInfo: Array<{
	property: string,
	type: string,
	label: string,
	value?: any,
	disableConversion?: boolean,
	measurementUnit?: string
}> = [
		{ property: 'brand', type: 'select', label: 'ADD_CAR.MAKE' },
		{ property: 'model', type: 'select', label: 'ADD_CAR.MODEL' },
		{ property: 'generation', type: 'arrObj', label: 'ADD_CAR.STYLE' },

		{ property: 'country', type: 'select', label: 'CAR_DETAIL.LOCATION' },
		{ property: 'region', type: 'select', label: 'CAR_DETAIL.STATE' },

		{ property: 'is_crashed', type: 'select', label: 'ADD_CAR.DAMAGE' },
		{ property: 'transmission', type: 'select', label: 'ADD_CAR.TRANSMISSION' },
		{ property: 'owners_count', type: 'select', label: 'ADD_CAR.NUMBER_OF_OWNERS' },
		{ property: 'purchased_year', type: 'number', label: 'ADD_CAR.PURCHASE_DATE' },
		{ property: 'cubic_capacity', type: 'number', label: 'ADD_CAR.CUBIC_CAPACITY', disableConversion: true, measurementUnit: 'ADD_CAR.LITER' },
		{ property: 'kilometer', type: 'number', label: 'CAR_DETAIL.MILE', measurementUnit: 'CAR.KM' },
		{ property: 'color', type: 'arrObj', label: 'ADD_CAR.COLOR' },
		{ property: 'production_date', type: 'number', label: 'ADD_CAR.YEAR' },
		{ property: 'fuel_type', type: 'select', label: 'CAR_DETAIL.FUEL_TYPE' },
		{ property: 'car_type', type: 'arrObj', label: 'ADD_CAR.BODY_TYPE' },
		{ property: 'power', type: 'number', label: 'ADD_CAR.POWER' },
		{ property: 'drive_type', type: 'select', label: 'ADD_CAR.DRIVE_TYPE' },
		{ property: 'drive_wheel_type', type: 'select', label: 'ADD_CAR.DRIVE' },
		{ property: 'usage', type: 'select', label: 'CAR.USED.YES' },
		{ property: 'custom_clearance', type: 'checkbox', label: 'CAR_DETAIL.CUSTOM_CLEARANCE' },
		{ property: 'guarantee', type: 'checkbox', label: 'CAR_DETAIL.GUARANTEE' },
		{ property: 'exchange', type: 'checkbox', label: 'ADD_CAR.EXCHANGE_LABEL' },
	];

export const extraFeaturesCategories: Array<{
	label: string, fields: Array<{ property: string, head?: string, type: string, label: string, value?: any }>
}> = [
		{
			label: 'CAR_DETAIL.SECURITY', fields: [
				{ property: 'abs', type: 'checkbox', label: 'ADD_CAR.ABS' },
				{ property: 'anti_sleep', type: 'checkbox', label: 'ADD_CAR.ANTI_SLEEP' },
				{ property: 'central_lock', type: 'checkbox', label: 'ADD_CAR.CENTRAL_LOCK' },
				{ property: 'airbag', type: 'select', label: 'ADD_CAR.SAFETY_PADS', head: '' },
				{ property: 'security_alarm', type: 'checkbox', label: 'ADD_CAR.SECURITY_ALARM' },
			]
		},
		{
			label: 'CAR_DETAIL.INTERIOR', fields: [
				{ property: 'interior_type', type: 'select', label: 'ADD_CAR.SALON', head: '' },
				{ property: 'interior_flavor', type: 'select', label: 'ADD_CAR.SALON_COLOR', head: '' },
				{ property: 'seat_count', type: 'select', label: 'ADD_CAR.SEAT_NUMBER', head: '' },
				{ property: 'helm_regulator', type: 'select', label: 'ADD_CAR.DRIVE_CONFIG', head: '' },
				{ property: 'sunroof', type: 'checkbox', label: 'ADD_CAR.SUNROOF', head: '' },
				{ property: 'cd', type: 'checkbox', label: 'ADD_CAR.CD' },
				{ property: 'tv', type: 'checkbox', label: 'ADD_CAR.TV' }
			]
		},
		{
			label: 'CAR_DETAIL.RELATIONS', fields: [
				{ property: 'board_computer', type: 'checkbox', label: 'ADD_CAR.BOARD_COMPUTER' },
				{ property: 'climate_control', type: 'select', label: 'ADD_CAR.CLIMATE_CONTROL', head: '' },
				{ property: 'cruise_control', type: 'checkbox', label: 'ADD_CAR.CRUISE_CONTROL' },
				{ property: 'parktronic', type: 'checkbox', label: 'ADD_CAR.PARKTRONIC' },
				{ property: 'esp', type: 'checkbox', label: 'ADD_CAR.ESP' },
				{ property: 'navigation', type: 'checkbox', label: 'ADD_CAR.NAVIGATION' },
				{ property: 'gas_cylinder_equipment', type: 'checkbox', label: 'ADD_CAR.GAS_CYLINDER_EQUIPMENT' },
				{ property: 'driver_seat_regulator', type: 'select', label: 'ADD_CAR.DRIVER_SEAUPLOADT_CONFIG', head: '' },
				{ property: 'passenger_seat_regulator', type: 'select', label: 'ADD_CAR.PASSENGER_SEAT_CONFIG', head: '' },
				{ property: 'heated_seal', type: 'checkbox', label: 'ADD_CAR.HEATED_SEAL' },
				{ property: 'heated_helm', type: 'checkbox', label: 'ADD_CAR.HEATED_HELM' }
			]
		},
		{
			label: 'CAR_DETAIL.FOREIGN_FEATURES', fields: [
				{ property: 'alloy_wheels', type: 'checkbox', label: 'ADD_CAR.ALLOY_WHEELS' },
				{ property: 'tined_windows', type: 'checkbox', label: 'ADD_CAR.TINED_WINDOW' },
			]
		},
		{
			label: 'CAR_DETAIL.OVERVIEW', fields: [
				{ property: 'window_regulator', type: 'select', label: 'ADD_CAR.GLASS_CONTROL', head: '' },
				{ property: 'xenon_headlights', type: 'checkbox', label: 'ADD_CAR.XENON_HEADLIGHTS' },
				{ property: 'heated_mirrors', type: 'checkbox', label: 'ADD_CAR.HEATED_MIRRORS' },
				{ property: 'electric_mirrors', type: 'checkbox', label: 'ADD_CAR.ELECTRIC_MIRRORS' },
				{ property: 'rain_sensor', type: 'checkbox', label: 'ADD_CAR.RAIN_SENSOR' },
				{ property: 'headlight_washer', type: 'checkbox', label: 'ADD_CAR.HEADLIGHT_WASHER' },
			]
		}
	];

export const galleryOptions = [
	{
		width: '600px',
		height: '500px',
		thumbnailsColumns: 4,
		previewCloseOnClick: true,
		previewCloseOnEsc: true,
		previewZoom: true,
		previewRotate: true,
		previewFullscreen: true,
		lazyLoading: true,
		previewKeyboardNavigation: true,
		imageAnimation: NgxGalleryAnimation.Slide,
		previewInfinityMove: true
	},
	// max-width 800
	{
		breakpoint: 800,
		width: '100%',
		height: '600px',
		imagePercent: 80,
		thumbnailsColumns: 4,
		previewCloseOnClick: true,
		previewCloseOnEsc: true,
		lazyLoading: true,
		previewZoom: true,
		previewRotate: true,
		previewFullscreen: true,
		previewKeyboardNavigation: true,
		thumbnailsPercent: 20,
		thumbnailsMargin: 20,
		thumbnailMargin: 20,
		previewInfinityMove: true
	},
	// max-width 400
	{
		width: '280px',
		height: '200px',
		breakpoint: 400,
		lazyLoading: true,
		imagePercent: 80,
		thumbnailsColumns: 4,
		previewCloseOnClick: true,
		previewZoom: true,
		previewRotate: true,
		previewFullscreen: true,
		previewKeyboardNavigation: true,
		previewInfinityMove: true
	}
];

