import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import {Component} from '@angular/core';
import {RouterLoaderService} from '../../services/router-loader.service';

@Component({
  selector: 'app-page-preloader, [app-page-preloader]',
  templateUrl: './page-preloader.component.html'
})
export class PagePreloaderComponent {
  public static isGoToTopEnabled = true;
  public loading = true;
  public loadingEvent = false;

  constructor(
    private readonly router: Router,
    private readonly routerLoaderService: RouterLoaderService
  ) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    // Shows and hides the loading spinner in custom cases using Subject in service
    this.routerLoaderService.$loader.subscribe(state => {
      if (typeof state === 'boolean') {
        this.loadingEvent = state;
        this.loading = state;
        if (!this.loadingEvent) {
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        }
      } else {
        this.loadingEvent = state.loader;
        this.loading = state.loader;
      }
    });
    this.routerLoaderService.$stopLoader.subscribe(() => {
      this.loadingEvent = false;
      this.loading = false;
    });
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd && !this.loadingEvent && PagePreloaderComponent.isGoToTopEnabled) {
      this.loading = false;
      // document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
}
