import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../services/authorization.service';
import { MatDialog } from '@angular/material';
import { AddPostLinkModalComponent } from '../../../pages/profile/add-a-car/add-post-link-modal/add-post-link-modal.component';
import { AppLanguageService } from '../../services/app-language.service';
import { ifInHomepage } from '../../route/route-matcher';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(router, authService, appLanguageService, dialog) {
        this.router = router;
        this.authService = authService;
        this.appLanguageService = appLanguageService;
        this.dialog = dialog;
        this.isProfileUlOpened = false;
        this.menuIsOpened = false;
        this.isLangChangePanelOpen = false;
        this.currentLang = AppLanguageService.CURRENT_LANG;
        this.languages = AppLanguageService.langList;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        // get current lang 
    };
    HeaderComponent.prototype.toggleState = function () {
        if (this.isAnimated) {
            this.isProfileUlOpened = !this.isProfileUlOpened;
        }
    };
    HeaderComponent.prototype.signOut = function () {
        this.closeMenu();
        this.authService.logOut();
    };
    HeaderComponent.prototype.openAddPostLinkModal = function () {
        this.dialog.open(AddPostLinkModalComponent);
    };
    HeaderComponent.prototype.toggleMenu = function () {
        if (this.menuIsOpened) {
            this.closeMenu();
        }
        else {
            this.openMenu();
        }
    };
    Object.defineProperty(HeaderComponent.prototype, "isAnimated", {
        get: function () {
            return window.innerWidth < 992;
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.openMenu = function () {
        this.menuIsOpened = true;
    };
    HeaderComponent.prototype.closeMenu = function () {
        this.menuIsOpened = false;
    };
    HeaderComponent.prototype.isUserLoggedIn = function () {
        return this.authService.isLoggedIn();
    };
    HeaderComponent.prototype.onLangChangePanelClick = function () {
        this.isLangChangePanelOpen = !this.isLangChangePanelOpen;
    };
    HeaderComponent.prototype.onLangChange = function (lang) {
        this.isLangChangePanelOpen = false;
        this.appLanguageService.defineLanguage(lang);
        this.currentLang = lang;
        if (ifInHomepage()) {
            window.location.href = "/" + AppLanguageService.API_LANGUAGE;
        }
        else {
            window.location.reload();
        }
    };
    return HeaderComponent;
}());
export { HeaderComponent };
