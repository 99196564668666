import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// abstract classes
import { Carousel } from 'src/app/shared/ui/carousel';

// services
import { RelatedCarRequestService } from './services/related-car-request.service';

// models
import { ResponseModel } from 'src/app/shared/models/response-model.model';
import { Car } from 'src/app/shared/models/car.model';

@Component({
  selector: 'app-similar-cars-carousel',
  templateUrl: './similar-cars-carousel.component.html',
  providers: [RelatedCarRequestService]
})
export class SimilarCarsCarouselComponent extends Carousel implements OnInit, OnDestroy {

  public enabled = false;
  private subscription: Subscription;

  constructor(
    private readonly relatedCarRequestService: RelatedCarRequestService,
  ) { super(); }

  ngOnInit() {
    this.subscription = this.relatedCarRequestService.getCars<ResponseModel<Car>>().subscribe(res => {
      this.carouselTileItems = res.body.results;
      this.enabled = true;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  
}