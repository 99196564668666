import { ToastrService } from 'ngx-toastr';
import { ToastrComponent } from './toastr.component';
import * as i0 from "@angular/core";
import * as i1 from "ngx-toastr";
var MyToastrService = /** @class */ (function () {
    function MyToastrService(toastr) {
        this.toastr = toastr;
    }
    MyToastrService.prototype.success = function (message, title, timing) {
        if (timing === void 0) { timing = 10000; }
        this.toastr.success(message, title, { timeOut: timing, toastComponent: ToastrComponent });
    };
    MyToastrService.prototype.error = function (message, title, timing) {
        if (message === void 0) { message = 'SHARED.ERROR_HEADER'; }
        if (title === void 0) { title = 'SHARED.ERROR_MESSAGE'; }
        if (timing === void 0) { timing = 10000; }
        this.toastr.error(message, title, {
            // disableTimeOut: true,
            timeOut: timing,
            closeButton: true,
            toastComponent: ToastrComponent,
        });
    };
    MyToastrService.ngInjectableDef = i0.defineInjectable({ factory: function MyToastrService_Factory() { return new MyToastrService(i0.inject(i1.ToastrService)); }, token: MyToastrService, providedIn: "root" });
    return MyToastrService;
}());
export { MyToastrService };
