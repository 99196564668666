/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "ngx-pagination";
import * as i3 from "./pagination.component";
var styles_PaginationComponent = [];
var RenderType_PaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-left"], ["style", "cursor: pointer"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v.parent, 1).previous() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-angle-left"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 1).isFirstPage(); _ck(_v, 0, 0, currVal_0); }); }
function View_PaginationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-angle-left"]], null, null, null, null, null))], null, null); }
function View_PaginationComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v.parent.parent, 1).setCurrent(_v.parent.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_PaginationComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_PaginationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "current", null], [2, "m-active", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "span", [], [[2, "current", null], [2, "m-active", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = (i0.ɵnov(_v.parent, 1).getCurrent() !== _v.context.$implicit.value); _ck(_v, 3, 0, currVal_2); var currVal_5 = (i0.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); var currVal_1 = (i0.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = (i0.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); var currVal_4 = (i0.ɵnov(_v.parent, 1).getCurrent() === _v.context.$implicit.value); _ck(_v, 4, 0, currVal_3, currVal_4); }); }
function View_PaginationComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-right"], ["style", "cursor: pointer"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v.parent, 1).next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-angle-right"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 1).isLastPage(); _ck(_v, 0, 0, currVal_0); }); }
function View_PaginationComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "m-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "fa fa-angle-right"]], null, null, null, null, null))], null, null); }
export function View_PaginationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "pagination-template", [["class", "b-items__pagination-main"]], null, [[null, "pageChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageChange" === en)) {
        var pd_0 = (_co.pageChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, [["p", 4]], 0, i2.PaginationControlsDirective, [i2.PaginationService, i0.ChangeDetectorRef], null, { pageChange: "pageChange" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_3)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_6)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_7)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = !i0.ɵnov(_v, 1).isFirstPage(); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 1).isFirstPage(); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵnov(_v, 1).pages; _ck(_v, 7, 0, currVal_2); var currVal_3 = !i0.ɵnov(_v, 1).isLastPage(); _ck(_v, 9, 0, currVal_3); var currVal_4 = i0.ɵnov(_v, 1).isLastPage(); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_PaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i0.ɵdid(1, 114688, null, 0, i3.PaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i0.ɵccf("app-pagination, [app-pagination]", i3.PaginationComponent, View_PaginationComponent_Host_0, { id: "id", maxSize: "maxSize" }, { pageChange: "pageChange" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };
