import {ValidatorFn, AbstractControl} from '@angular/forms';
import {emailPattern} from '../../pages/authorization/validation-helper-model';
import {PhoneNumberValidator} from './phone-number-validator';

export function EmailOrPhoneNumberValidator(regionCode?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      return null;
    }

    if (isEmailValid(control.value)) {
      return null;
      } else if (!PhoneNumberValidator(regionCode)(control)) {
      return null;
    } else {
      return {'wrongEmailOrPhoneNumber': {value: control.value}};
    }
  };
}

export function isEmailValid(value: string): boolean {
  return emailPattern.test(value);
}

