var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { checkboxes, extraFeatures } from '../../../detail-view/detail.config';
import { CarLocalItemsService } from '../../../../core/services/car-local-items.service';
import { FormBuilder } from '@angular/forms';
import { CarService } from '../../../../core/services/car.service';
import { TranslateService } from '@ngx-translate/core';
var AdvancedSearchComponent = /** @class */ (function () {
    function AdvancedSearchComponent(carLocalItemsService, fb, carService, translateService) {
        var _this = this;
        this.carLocalItemsService = carLocalItemsService;
        this.fb = fb;
        this.carService = carService;
        this.translateService = translateService;
        this.langObj = Object.create(null);
        this.car_type = [];
        this.colors = [];
        this.countries = [];
        this.regions = [];
        this.isRegionsLoading = false;
        this.getRegions = function (value) {
            if (value === void 0) { value = null; }
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                var country, region;
                return __generator(this, function (_a) {
                    country = this.form.get('country');
                    region = this.form.get('region');
                    if (region.value) {
                        region.setValue(null);
                        this.regions = [];
                    }
                    if (country.value) {
                        this.isRegionsLoading = true;
                        this.carService.getRegions(country.value).subscribe(function (res) {
                            _this.regions = res.body.results.slice();
                            if (value) {
                                region.setValue(value);
                            }
                            else {
                                _this.autocomplete(_this.regions, region);
                            }
                            _this.isRegionsLoading = false;
                        });
                    }
                    else {
                        this.form.get('region').setValue(null);
                        this.regions = [];
                    }
                    return [2 /*return*/];
                });
            });
        };
        this.checkboxes = checkboxes;
        this.extraSelects = extraFeatures;
    }
    AdvancedSearchComponent.prototype.ngOnInit = function () {
        this.createForm();
        this.initColors();
        this.initCarTypes();
        this.initCountries();
    };
    AdvancedSearchComponent.prototype.createForm = function () {
        this.form = this.fb.group({
            created: [null],
            owner: [null],
            kilometer_range_min: [null],
            kilometer_range_max: [null],
            posted_time_desc: [null],
            price_asc: [null],
            price_desc: [null],
            production_date_asc: [null],
            production_date_desc: [null],
            kilometer_desc: [null],
            cubic_min: [null],
            cubic_max: [null],
            power_min: [null],
            power_max: [null],
            region: [null],
            country: [null],
            usage: [null],
            drive_wheel_type: [null],
            fuel_type: [null],
            color: [null],
            transmission: [null],
            is_crashed: [null],
            drive_type: [null],
            guarantee: [null],
            exchange: [null],
            custom_clearance: [null],
            owners_count: [null],
            interior_type: [null],
            interior_flavor: [null],
            climate_control: [null],
            window_regulator: [null],
            driver_seat_regulator: [null],
            passenger_seat_regulator: [null],
            seat_count: [null],
            helm_regulator: [null],
            airbag: [null],
            sunroof: [false],
            tined_windows: [false],
            xenon_headlights: [false],
            alloy_wheels: [false],
            board_computer: [false],
            gas_cylinder_equipment: [false],
            rain_sensor: [false],
            cruise_control: [false],
            navigation: [false],
            heated_mirrors: [false],
            headlight_washer: [false],
            central_lock: [false],
            security_alarm: [false],
            heated_helm: [false],
            heated_seal: [false],
            electric_mirrors: [false],
            abs: [false],
            anti_sleep: [false],
            esp: [false],
            parktronic: [false],
            cd: [false],
            tv: [false],
            posted_time: [null],
        }, {
            updateOn: 'submit'
        });
    };
    AdvancedSearchComponent.prototype.initColors = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.carService.getColors().subscribe(function (res) {
                _this.colors = res.body.results.slice();
                resolve(true);
            });
        });
    };
    AdvancedSearchComponent.prototype.initCarTypes = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.carService.getCarSeries().subscribe(function (res) {
                _this.car_type = res.body.results.slice();
                resolve(true);
            });
        });
    };
    AdvancedSearchComponent.prototype.initCountries = function () {
        var _this = this;
        this.carService.getCountries().subscribe(function (res) {
            _this.countries = res.body.results.slice();
        });
    };
    AdvancedSearchComponent.prototype.ngOnDestroy = function () {
        this.form.reset();
    };
    AdvancedSearchComponent.prototype.autocomplete = function (array, formControl, prop) {
        if (prop === void 0) { prop = 'id'; }
        if (array.length === 1) {
            formControl.setValue(array[0][prop]);
        }
    };
    return AdvancedSearchComponent;
}());
export { AdvancedSearchComponent };
