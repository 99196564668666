import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { FilterComponent } from './pages/filter-pages/filter.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { DetailViewComponent } from './pages/detail-view/detail-view.component';
import { ResolveDetailService } from './pages/detail-view/resolve-detail.service';
import { ResolveFilterService } from './pages/filter-pages/resolve-filter.service';
import { languageMatcher } from './core/route/route-matcher';
var ɵ0 = { h1: 'CAR_DETAIL.VEHICLE', h3: '', className: 'm-detail', routerLink: '', showPageHeader: true }, ɵ1 = {
    h1: 'CAR_DETAIL.VEHICLE',
    h3: '',
    className: 'm-detail',
    routerLink: '',
    showPageHeader: true,
    multiple: [
        { h1: 'SEARCH.RESULTS', routerLink: '/filter' },
        { h1: 'CAR_DETAIL.VEHICLE', routerLink: '' }
    ],
};
var appRoutes = [
    { path: '', component: HomeComponent },
    {
        component: HomeComponent,
        matcher: languageMatcher
    },
    {
        path: 'detail/:id/:car', component: DetailViewComponent,
        data: ɵ0,
        resolve: { car: ResolveDetailService },
    },
    {
        path: 'filter', component: FilterComponent,
        resolve: { car: ResolveFilterService },
    },
    {
        path: 'filter/detail/:id/:car', component: DetailViewComponent,
        data: ɵ1,
        resolve: { car: ResolveDetailService },
    },
    {
        path: 'about', loadChildren: './pages/about/about.module#AboutModule'
    },
    {
        path: '', loadChildren: './pages/policy-pages/policy.module#PolicyModule'
    },
    {
        path: 'contacts', loadChildren: './pages/contacts/contacts.module#ContactsModule'
    },
    { path: '', loadChildren: './pages/authorization/auth.module#AuthModule' },
    { path: 'blog', loadChildren: './pages/blog-pages/blog.module#BlogModule' },
    { path: '', loadChildren: './pages/profile/profile.module#ProfileModule' },
    { path: '**', component: PageNotFoundComponent }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
