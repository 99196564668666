export interface AddCarFromOtherWebsitePayload {
  url: string;
  price?: number;
}

export interface AddCarFromOtherWebsiteModel {
  task_id: string;
  status: ADD_CAR_STATUSES;
  slug: string;

  title?: string;
  requestStartDate: Date;
  routerLink?: Array<string>;
}

// Statuses that matter for JS(front) are:
// pending, failure, success
// but as Gear API requests 3rd party APIs, it responds with many statuses,
// which mean different things for Back End, but are the same for Front End
export enum ADD_CAR_STATUSES {
  PENDING = 'PENDING',
  RECEIVED = 'PENDING',
  STARTED = 'PENDING',
  RETRY = 'PENDING',
  REVOKED = 'PENDING',
  FAILURE = 'FAILURE',
  IGNORED = 'FAILURE',
  SUCCESS = 'SUCCESS'
}
