import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Carousel } from '../../../shared/ui/carousel';
import { CarService } from '../../../core/services/car.service';
import { Car } from '../../../shared/models/car.model';
import { ResponseModel } from '../../../shared/models/response-model.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-vehicles',
  templateUrl: './top-vehicles.component.html'
})
export class TopVehiclesComponent extends Carousel implements OnInit, OnDestroy {
  enabled = false;
  private $carSubscription: Subscription;
  @Input() private topParam = false;

  constructor(private carService: CarService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.$carSubscription = this.carService.getCars<ResponseModel<Car>>(`offset=0&limit=25${this.topParam ? '&top=True' : ''}`)
      .subscribe(res => {
        this.carouselTileItems = res.body.results;
        this.enabled = true;
      });
  }

  ngOnDestroy(): void {
    this.$carSubscription.unsubscribe();
  }

}
