import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MyTranslateChildModule} from './modules/my-translate-child.module';
import {ToastrComponent} from './toastr/toastr.component';
import {ToastNoAnimationModule, ToastrModule} from 'ngx-toastr';
import {ValidationService} from '../core/services/validation.service';
import {PipesModule} from './pipes/pipes-module';
import {DetailViewComponent} from '../pages/detail-view/detail-view.component';
import {PageHeaderModule} from '../core/layout/page-header/page-header.module';
import {NgxGalleryModule} from 'ngx-gallery';
import { SimilarCarsCarouselComponent } from '../pages/detail-view/similar-cars-carousel/similar-cars-carousel.component';
import { SecondaryCarouselItemComponent } from '../pages/detail-view/secondary-carousel-item/secondary-carousel-item.component';
import {OwlModule} from 'ngx-owl-carousel';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MyTranslateChildModule,
        ToastrModule.forRoot({
            toastComponent: ToastrComponent, // added custom toast!
            maxOpened: 1,
            autoDismiss: true
        }),
        ToastNoAnimationModule.forRoot({
            maxOpened: 1,
            autoDismiss: true
        }),
        PageHeaderModule,
        NgxGalleryModule,
        OwlModule
    ],
  declarations: [
    ToastrComponent,
    DetailViewComponent,
    SimilarCarsCarouselComponent,
    SecondaryCarouselItemComponent,
  ],
  entryComponents: [ToastrComponent],
  exports: [
    PipesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrComponent,
    PageHeaderModule,
    NgxGalleryModule
  ],
  providers: [ValidationService],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
}
