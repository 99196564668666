import {Injectable} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {map, mergeMap, take, takeWhile, tap} from 'rxjs/operators';
// services
import {BaseHttpService} from '../../../../core/services/base-http-service';
import {AuthorizationService} from '../../../../core/services/authorization.service';
import {InfoBoxService} from '../../../info-box/services/info-box.service';
import {AppLanguageService} from '../../../../core/services/app-language.service';
import {CarService} from '../../../../core/services/car.service';
// models
import {
  ADD_CAR_STATUSES,
  AddCarFromOtherWebsiteModel,
  AddCarFromOtherWebsitePayload
} from '../models/add-car-from-other-website-payload.model';
import {ResponseModel} from '../../../../shared/models/response-model.model';
import {Car} from '../../../../shared/models/car.model';
// utils
import {getDateDifferenceInMinutes} from '../../utils';
import {urlFragment} from '../../../../shared/utils/utils';


@Injectable({
  providedIn: 'root'
})
export class AddCarFromOtherWebsiteService extends BaseHttpService {
  public static completedTasks = new Set<string>(); // completed tasks
  public static data: Map<string, AddCarFromOtherWebsiteModel> = new Map(); // pending tasks
  private static TIMEOUT = 10; // in minutes

  private readonly POST_URL = 'car-scraper/';
  private readonly GET_STATUS_URL = 'car-scraper-info/';
  private alreadyStartedChecking = false;

  constructor(
    protected readonly httpClient: HttpClient,
    private readonly authService: AuthorizationService,
    private readonly infoBoxService: InfoBoxService,
    private readonly carsService: CarService
  ) {
    super(httpClient);
  }

  public createRequest(payload: AddCarFromOtherWebsitePayload): Observable<AddCarFromOtherWebsiteModel> {
    return this.post(`/${this.POST_URL}`, payload,
      {
        headers: new HttpHeaders({
          'Accept-Language': AppLanguageService.API_LANGUAGE,
          Authorization: 'Token ' + this.authService.getToken(),
        })
      }).pipe(
      tap((res: AddCarFromOtherWebsiteModel) => {
        res.title = AddCarFromOtherWebsiteService.getHostnameFromUrl(payload.url);
        AddCarFromOtherWebsiteService.data.set(res.task_id, res);

        this.infoBoxService.updateData(
          AddCarFromOtherWebsiteService.data.size,
          AddCarFromOtherWebsiteService.completedTasks.size,
          res
        );
        this.statusChecker();
      })
    );
  }

  public getStatuses(): Observable<ResponseModel<AddCarFromOtherWebsiteModel>> {
    return this.get(`/${this.GET_STATUS_URL}?limit=-1`, {
      headers: new HttpHeaders({
        'Accept-Language': AppLanguageService.API_LANGUAGE,
        Authorization: 'Token ' + this.authService.getToken(),
      })
    })
      .pipe(map(res => res as any));
  }

  public statusChecker(): void {
    if (AddCarFromOtherWebsiteService.data.size <= 0) {
      this.stopStatusChecking();
      return;
    }
    if (this.alreadyStartedChecking) {
      return;
    }

    this.alreadyStartedChecking = true;

    interval(7000)
      .pipe(
        mergeMap(() => {
          return this.getStatuses();
        }),
        map((result: ResponseModel<AddCarFromOtherWebsiteModel>) => {
          if (result.count === 0 || result.results.length === 0) {
            // no status updates yet
            return;
          }

          AddCarFromOtherWebsiteService.data.forEach(car => {
            if (!car.requestStartDate) {
              car.requestStartDate = new Date();
            }

            const index = result.results.findIndex(item => item.task_id === car.task_id);
            if (index >= 0 && AddCarFromOtherWebsiteService.data.has(result.results[index].task_id)) {
              const item = result.results[index];
              item.slug = car.slug;
              item.title = car.title;

              switch (item.status) {
                case ADD_CAR_STATUSES.SUCCESS:
                  this.changeItemStatus(item, ADD_CAR_STATUSES.SUCCESS);
                  break;
                case ADD_CAR_STATUSES.FAILURE:
                  this.changeItemStatus(item, ADD_CAR_STATUSES.FAILURE);
                  break;
                default:
                  this.changeItemStatus(item, ADD_CAR_STATUSES.PENDING);
                  break;
              }
            } else {
              const now = new Date();
              const minutes = getDateDifferenceInMinutes(car.requestStartDate, now);
              if (minutes >= AddCarFromOtherWebsiteService.TIMEOUT) {
                this.changeItemStatus(car, ADD_CAR_STATUSES.FAILURE);
              }
            }
          });

          if (AddCarFromOtherWebsiteService.data.size <= 0) {
            this.stopStatusChecking();
          }
        })
      )
      .pipe(
        takeWhile(() => AddCarFromOtherWebsiteService.data.size > 0)
      )
      .subscribe();
  }

  // stops polling and updates pending/completed
  private stopStatusChecking(): void {
    this.infoBoxService.updateData(
      0,
      AddCarFromOtherWebsiteService.completedTasks.size,
      null
    );
    this.alreadyStartedChecking = false;
  }

  private changeItemStatus(item: AddCarFromOtherWebsiteModel, status: ADD_CAR_STATUSES): void {
    item.status = status;

    if (status === ADD_CAR_STATUSES.SUCCESS || status === ADD_CAR_STATUSES.FAILURE) {
      if (AddCarFromOtherWebsiteService.data.has(item.task_id)) {
        AddCarFromOtherWebsiteService.data.delete(item.task_id);
      }

      if (status === ADD_CAR_STATUSES.SUCCESS) {
        AddCarFromOtherWebsiteService.completedTasks.add(item.task_id);

        this.carsService.getCars(`slug=${item.slug}`)
          .pipe(take(1))
          .subscribe((response: HttpResponse<ResponseModel<Car>>) => {
            const car = response.body.results[0];
            if (car) {
              item.routerLink = [
                '/detail',
                `${car.id}`,
                urlFragment(car)
              ];
              item.title = car.car_model.brand.name + ' ' + car.car_model.name;
            }

            this.infoBoxService.updateData(
              AddCarFromOtherWebsiteService.data.size,
              AddCarFromOtherWebsiteService.completedTasks.size,
              item
            );
          });
      } else {
        // console.log('update FAILURE:', item);
        this.infoBoxService.updateData(
          AddCarFromOtherWebsiteService.data.size,
          AddCarFromOtherWebsiteService.completedTasks.size,
          item
        );
      }
    }
  }

  public static getHostnameFromUrl(url: string): string {
    let hostname;

    // remove protocol
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    if (url.indexOf('www.') > -0) {
      hostname = url.split('www.')[1];
    }

    // hostname = hostname.split('.')[0];

    // remove port
    hostname = hostname.split(':')[0];

    // remove query
    hostname = hostname.split('?')[0];

    hostname = hostname.split('/')[0];

    return hostname;
  }
}
