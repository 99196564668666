var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Carousel } from '../../../shared/ui/carousel';
import { CarService } from '../../../core/services/car.service';
var TopVehiclesComponent = /** @class */ (function (_super) {
    __extends(TopVehiclesComponent, _super);
    function TopVehiclesComponent(carService) {
        var _this = _super.call(this) || this;
        _this.carService = carService;
        _this.enabled = false;
        _this.topParam = false;
        return _this;
    }
    TopVehiclesComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.$carSubscription = this.carService.getCars("offset=0&limit=25" + (this.topParam ? '&top=True' : ''))
            .subscribe(function (res) {
            _this.carouselTileItems = res.body.results;
            _this.enabled = true;
        });
    };
    TopVehiclesComponent.prototype.ngOnDestroy = function () {
        this.$carSubscription.unsubscribe();
    };
    return TopVehiclesComponent;
}(Carousel));
export { TopVehiclesComponent };
