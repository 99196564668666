<footer [hidden]="isFooterHidden" class="b-footer">
  <div class="container">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-xs-12 col-sm-4 mb-4">
          <div class="b-footer__company">
            <div class="b-nav__logo">
              <a routerLink="/"><img src="../../../../assets/images/logo/logo-black.png" alt="Logo"></a>
            </div>
            <p>© 2019 gear.am. {{"FOOTER.ALL_RIGHTS_RESERVED" | translate}}.</p>
          </div>
        </div>

        <div class="col-xs-12 col-sm-4 mb-2">
          <div class="fb-page" data-href="https://www.facebook.com/1gear.am/"
               data-tabs="" data-width="" data-height=""
               data-small-header="false"
               data-adapt-container-width="true"
               data-hide-cover="false"
               data-show-facepile="true">
            <blockquote cite="https://www.facebook.com/1gear.am/" class="fb-xfbml-parse-ignore">
              <a href="https://www.facebook.com/1gear.am/">Gear.am</a></blockquote></div>
        </div>

        <div class="col-xs-12 col-sm-4 mb-2">
          <div class="b-footer__content">
            <div class="b-footer__content-social">
              <a href="https://www.facebook.com/1gear.am/"><span class="fa fa-facebook-square"></span></a>
              <a href="https://www.instagram.com/1gear.am/"><span class="fa fa-instagram"></span></a>
              <a href="https://www.youtube.com/channel/UCh6LyhzpwyrzABT7m6-C8gg"><span class="fa fa-youtube"></span></a>
            </div>
            <nav class="b-footer__content-nav">
              <ul>
                <li><a routerLink="/">{{'HEADER.HOME' | translate}}</a></li>
                <li><a routerLink="/about">{{'HEADER.ABOUT' | translate}}</a></li>
                <li><a routerLink="/terms/arm" target="_blank" rel="noopener">{{'TERM.TERM_1' | translate}}</a></li>
                <li><a routerLink="/privacy-policy/arm">{{'PRIVACY.PRIVACY_1' | translate}}</a></li>
                <!--<li><a routerLink="/blog/posts">{{'HEADER.BLOG' | translate}}</a></li>-->
                <li><a routerLink="/contacts">{{'HEADER.CONTACTS' | translate}}</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer><!--b-footer-->
