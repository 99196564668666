import {Injectable} from '@angular/core';
import {BaseHttpService} from './base-http-service';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {IUserPayload} from '../../pages/authorization/sign-in/IUserPayload.model';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService extends BaseHttpService {
  private static authToken: string;
  public static id: any;
  private LOGIN_URL = '/auth-users/login/';
  private REGISTER_URL = '/auth-users/register/';
  private RESEND_EMAIL_URL = '/auth-users/resend-email/';
  private RESET_PASSWORD_URL = '/auth-users/reset-password/';
  private FORGOT_PASSWORD_URL = '/auth-users/forgot-password/';
  private CONFIRM_ACCOUNT_URL = '/auth-users/confirm-account/';
  private TOKEN_VERIFICATION = '/auth-users/token_verification/';

  constructor(private httpClient: HttpClient, private cookieService: CookieService) {
    super(httpClient);
  }

  static get token() {return AuthorizationService.authToken; }
  static set token(token) {AuthorizationService.authToken = token; }

  login(credentials: IUserPayload) {
    return this.post(this.LOGIN_URL, credentials);
  }

  register(credentials: object) {
    return this.post(this.REGISTER_URL, credentials);
  }

  confirmAccount(credentials: object) {
    return this.post(this.CONFIRM_ACCOUNT_URL, credentials);
  }

  resendEmail(credentials: object) {
    return this.post(this.RESEND_EMAIL_URL, credentials);
  }

  verifyToken(tokenObj: {token: string}) {
    return this.post(this.TOKEN_VERIFICATION, tokenObj);
  }

  resetPassword(credentials: object, resetKey: string) {
    return this.post(`${this.RESET_PASSWORD_URL}${resetKey}/`, credentials);
  }

  forgotPassword(credentials: object) {
    return this.post(this.FORGOT_PASSWORD_URL, credentials);
  }

  saveTokenAndUserId(rememberMe: boolean, res) {
    if (rememberMe) {
      this.cookieService.set('token', res.body.token);
      this.cookieService.set('id', res.body.id);
      localStorage.setItem('token', res.body.token);
      localStorage.setItem('id', res.body.id);
    } else {
      AuthorizationService.token = res.body.token;
      AuthorizationService.id = res.body.id;
    }
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('token') || this.cookieService.check('token') || !!AuthorizationService.token;
  }

  getToken(): string {
    return localStorage.getItem('token') || this.cookieService.get('token') || AuthorizationService.token;
  }

  getUserId(): string {
    return localStorage.getItem('id') || AuthorizationService.id;
  }

  logOut() {
    this.cookieService.delete('token');
    this.cookieService.delete('id');
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    AuthorizationService.token = null;
    AuthorizationService.id = null;
  }

  getEmailDomain(email: string) {
    return 'https://' + email.slice(email.lastIndexOf('@') + 1);
  }

}
