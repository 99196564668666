import {Injectable} from '@angular/core';
import {AbstractControl, ValidatorFn} from '@angular/forms';
import {Observable, of} from 'rxjs';


@Injectable()
export class ValidationService {
  constructor() {
  }

  onlyNumbers(control: AbstractControl): { [key: string]: boolean } {
    const pattern = /^\d+$/;
    if (control.value && !pattern.test(control.value)) {
      return {'notOnlyNumbers': true};
    }
    return null;
  }

  onlyAlphabeticChars(control: AbstractControl): { [key: string]: boolean } {
    const pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-я' ]*$/;
    if (control.value && !pattern.test(control.value)) {
      return {'notOnlyChars': true};
    }
    return null;
  }

  public static onlyNumbersWithLength(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } => {
      const pattern = /^\d+$/;
      if (!control.value) {
        return null;
      }
      if (!pattern.test(`${control.value}`)) {
        return {'notOnlyNumbers': true};
      }
      if (`${control.value}`.length !== length) {
        return {'pattern': true};
      }

      return null;
    };
  }

  public static onlyNumbersAsync(control: AbstractControl): Observable<{ [key: string]: boolean }> {
    const pattern = /^\d+$/;
    if (control.errors && Object.keys(control.errors).length > 0) {
      return of(null);
    }
    if (control.value && !pattern.test(control.value)) {
      return of({'notOnlyNumbers': true});
    }
    return of(null);
  }

  public static notOnltSpaces(control: AbstractControl): { [key: string]: boolean } {
    if (!control.value) {
      return null;
    }

    return control.value.trim() ? null : {'notOnlySpaces': true};
  }

  public static onlyAlphabeticAndNumbers(control: AbstractControl): { [key: string]: boolean } {
    const pattern = /^[a-zA-Zա-ֆԱ-ՖА-Яа-я0-9']*$/;
    if (control.value && !pattern.test(control.value)) {
      return {'notOnlyAlphabeticalAndNumbers': true};
    }

    return null;
  }
}
