import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {ToastrComponent} from './toastr.component';

@Injectable({
  providedIn: 'root'
})
export class MyToastrService {

  constructor(private toastr: ToastrService) { }

  success(message: string, title: string, timing = 10000): void {
    this.toastr.success(message, title, {timeOut: timing, toastComponent: ToastrComponent});
  }

  error(message = 'SHARED.ERROR_HEADER', title = 'SHARED.ERROR_MESSAGE', timing = 10000): void {
    this.toastr.error(message, title,  {
      // disableTimeOut: true,
      timeOut: timing,
      closeButton: true,
      toastComponent: ToastrComponent,
    });
  }
}
